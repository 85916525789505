import log from '@/log'
import store from '@/store'
import types from '@/types'
import AudienceApi from '@unified/js-common/lib/api/AudiencesApi'
import ContextingApi from '@unified/js-common/lib/api/ContextingApi'
import PMNApi from '@unified/js-common/lib/api/PMNApi'
import PlatformApi from '@unified/js-common/lib/api/PlatformApi'
import PublisherApi from '@unified/js-common/lib/api/PublisherApi'
import ReportApi from '@unified/js-common/lib/api/ReportsApi'
import _ from 'lodash'

import stripMargin from '@/helpers/stripMargin'
// @ts-ignore
import PlatformDashboardsApi from '@unified/js-common/lib/api/PlatformDashboardsApi'
import PlatformUIApi from '@unified/js-common/lib/api/PlatformUiApi'
import SocialApi from '@unified/js-common/lib/api/SocialApi'

interface RequestConfig {
  uri: string
  fetchOptions: RequestInit
}

const api = (apiCall: string): API => {
  const apiName = apiCall.split('.', 1)[0]
  const callName = apiCall.split('.').slice(1).join('.')
  const apiAuthentication = types.config.apiAuthentication()
  return new API(apiName, callName, apiAuthentication)
}

export class RespError extends Error {
  constructor(public resp: Response) {
    super(`Non-ok response (${resp.status} ${resp.statusText}) from ${resp.url}: ${resp.statusText}`)
  }
}

api.isRespError = function (err: any): err is RespError {
  return !!err.resp
}

export default api

export const checkLogin = async (next = ''): Promise<boolean> => {
  const authToken =
    types.config.apiAuthentication() === 'bypass' ? types.config.byPassIdentityId() : types.user.authToken()
  if (!authToken) {
    login(next)
    return false
  }

  try {
    const ok = await api('platform.identity.auth.validate')
      .method('POST')
      .params({
        authToken,
      })
      .ok()
    if (!ok) {
      login(next)
      return false
    }
    return true
  } catch (err) {
    log.error(err, 'An error occurred validating token')
    login(next)
  }
  return false
}

export const login = (next = '', tempToken = '') => {
  if (next && !next.startsWith('http')) {
    // If we have no protocol, add the protocol, domain, and `/` to the next
    // so that platform-ui will redirect back to us
    next = `${document.location.protocol}//${document.location.host}/#${next}`
  }

  let loginURL = `${types.config.LogoutDomain()}/login?`

  loginURL += `next=${encodeURIComponent(next || document.location.href)}`

  if (tempToken) {
    // If this is a login request for a temporary user
    loginURL += `&tempToken=${encodeURIComponent(tempToken)}`
  }

  log.debug('Redirecting user to login URL: ', loginURL)

  window.location.href = loginURL
}

export const getLogoutURL = () => {
  return `${types.config.LogoutDomain()}/logout?return_url=${encodeURIComponent(window.location.href)}`
}

export const getLoginURL = () => {
  return `${types.config.LogoutDomain()}/login`
}

export const logout = (msg = '') => {
  let logoutURL = getLogoutURL()
  if (msg) logoutURL += `&msg=${encodeURIComponent(msg)}`
  window.location.href = logoutURL
}

export const unimpersonate = async () => {
  const url = `${types.config.UPDomain()}/impersonate`
  const req = new Request(url, {
    method: 'POST',
    body: JSON.stringify({ user: {} }),
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      'X-Unified-Auth-Token': types.user.authToken(),
    },
  })
  try {
    await call(req, 'unimpersonate')
    window.location.reload()
  } catch (err) {
    store.commit('error', 'An error occurred unimpersonating user')
  }
}

const apis: any = {
  audience: new AudienceApi(types.config.APIConfig()),
  contexting: new ContextingApi(types.config.APIConfig()),
  platform: new PlatformApi(types.config.APIConfig()),
  pmn: new PMNApi(types.config.APIConfig()),
  publisher: new PublisherApi(types.config.APIConfig()),
  report: new ReportApi(types.config.APIConfig()),
  social: new SocialApi(types.config.APIConfig()),
  ui: new PlatformUIApi(types.config.UIConfig()),
  dashboards: new PlatformDashboardsApi(types.config.APIConfig()),
}

/**
 * Wraps the js-common API class to add extra functionality
 */
export class API {
  /**
   * The name of the api that will be called through this wrapper
   */
  private readonly apiAuthentication: string

  /**
   * The name of the api that will be called through this wrapper
   */
  private readonly apiName: string

  /**
   * The api that will be called through this wrapper
   */
  private readonly api: any

  /**
   * The API call name
   */
  private readonly callName: string

  /**
   * The method of the API call
   */
  private callMethod: string

  /**
   * The headers that will be used to call the API
   */
  private callHeaders: any

  /**
   * The parameters that will be used to call the API
   */
  private callParams: any

  /**
   * Any extra request initializations to be set on the Request
   */
  private callInit: RequestInit

  /**
   * How long to cache this calls results. Set to -1 to cache forever
   */
  private callCache: number

  /**
   * True if we should not throw and error on non-ok response
   */
  private callNoError: boolean

  /**
   * If this is specified, take the given action on receiving a status code
   */
  private callOnStatus: { [status: number]: any }

  constructor(apiName: string, callName: string, apiAuthentication: string) {
    this.apiAuthentication = apiAuthentication
    this.apiName = apiName
    this.api = apis[this.apiName]
    if (!this.api) {
      throw new Error(`API Not found: ${apiName}`)
    }
    this.callName = callName
    this.callMethod = 'GET'
    this.callParams = {}
    this.callHeaders = {}
    this.callCache = 0
    this.callNoError = false
    this.callOnStatus = {}
    this.callInit = apiName === 'ui' ? { credentials: 'include' } : {}
  }

  /**
   * Clone the API object
   */
  public clone(): API {
    return (
      new API(this.apiName, this.callName, this.apiAuthentication)
        .cache(this.callCache)
        .method(this.callMethod)
        // Clone call params (this may break if it gets a Date or something)
        .params(_.cloneDeep(this.callParams))
        .headers(_.cloneDeep(this.callHeaders))
        .init(_.cloneDeep(this.callInit))
    )
  }

  /**
   * Set the method for the call
   * @param method The method to set for the call
   */
  public method(method: string): API {
    this.callMethod = method
    return this
  }

  /**
   * Set this to a positive number to cache the result
   * @param cache How long to cache the result in milliseconds
   */
  public cache(cache: number): API {
    this.callCache = cache
    return this
  }

  /**
   * Set the params for the call
   * @param params The params to set for the call
   */
  public params(params: any): API {
    this.callParams = Object.assign({}, this.callParams, params)
    return this
  }

  /**
   * Set the headers for the call
   * @param headers The headers to set for the call
   */
  public headers(headers: any): API {
    this.callHeaders = Object.assign({}, this.callHeaders, headers)
    return this
  }

  /**
   * Set the extra reqeust initialization for the call
   * @param init The request initialization to set for the call
   */
  public init(init: RequestInit): API {
    this.callInit = Object.assign({}, this.callInit, init)
    return this
  }

  /**
   * Toggle throwing an error on non-ok response
   * @param toError Whether or not to set an error on non-ok response
   */
  public noError(toError: boolean = true): API {
    this.callNoError = toError
    return this
  }

  /**
   * Take the following action on the given status code
   * @param status: the status code to take the action on
   * @param do: Either a callable that takes a response and returns a value, or a value
   */
  public on(status: number, val: any): API {
    this.callOnStatus[status] = val
    return this
  }

  /**
   * Call the API, return the response if succeeded, else throw error.
   * Also handles caching responsiblities
   */
  public async do(): Promise<Response | never> {
    let reqConfig: RequestConfig
    try {
      // if apiAuthentication=bypass, we will use identityId in X-Unified-Auth-Token instead
      if (this.apiAuthentication === 'bypass') {
        _.set(this.callParams, 'authToken', types.config.byPassIdentityId())
      }

      reqConfig = this.api.query(this.callName, this.callParams, this.callMethod, false)
      reqConfig.fetchOptions.headers = Object.assign({}, reqConfig.fetchOptions.headers, this.callHeaders)

      reqConfig.fetchOptions = Object.assign({}, reqConfig.fetchOptions, this.callInit)
    } catch (err) {
      // @ts-ignore
      if (err.message === 'meta is undefined') {
        const msg = stripMargin(
          `
        |Could not find endpoint ${this.apiName}.${this.callName}.
        | Make sure you spelt the name correctly!`,
          true
        )
        log.error(msg)
        throw new Error(msg)
      } else {
        // @ts-ignore
        log.exception(err, `An error occurred calling api ${this.apiName}.${this.callName}`)
      }
      throw err
    }

    const req = new Request(reqConfig.uri, reqConfig.fetchOptions)
    const resp = await call(req, `${this.apiName}.${this.callName}`, this.callCache, this.callNoError)

    if (resp.status === 401) {
      checkLogin()
    }

    return resp
  }

  /**
   * Call the API, return true if succeeded, else false
   */
  public async ok(): Promise<boolean> {
    try {
      await this.do()
      return true
    } catch (err) {
      if (api.isRespError(err)) return false
      throw err
    }
  }

  private handleRespErr(err: Error) {
    if (api.isRespError(err) && this.callOnStatus[err.resp.status] !== undefined) {
      const handler = this.callOnStatus[err.resp.status]
      if (typeof handler === 'function') {
        return handler(err.resp)
      } else {
        return handler
      }
    }
    throw err
  }

  /**
   * Call the API, return JSON if succeeded, else throw error
   */
  public async blob(): Promise<Blob> {
    try {
      const resp = await this.do()
      return await resp.blob()
    } catch (err) {
      // @ts-ignore
      return this.handleRespErr(err)
    }
  }

  /**
   * Call the API, return JSON if succeeded, else throw error
   */
  public async text(): Promise<string> {
    try {
      const resp = await this.do()
      return await resp.text()
    } catch (err) {
      // @ts-ignore
      return this.handleRespErr(err)
    }
  }

  /**
   * Call the API, return JSON if succeeded, else throw error
   */
  public async json<T>(reviver?: (key: string, value: any) => any): Promise<T> {
    try {
      const resp = await this.do()
      return JSON.parse(await resp.text(), reviver)
    } catch (err) {
      // @ts-ignore
      return this.handleRespErr(err)
    }
  }

  /**
   * Call the API and JSON conversion up to three times
   * if succeeded return the response , else throw error
   */
  public async jsonWithRetry<T>(
    attempts: number = 3,
    delay: number = 1000,
    reviver?: (key: string, value: any) => any
  ): Promise<T> {
    const execute = async (attempt: number): Promise<T> => {
      try {
        const resp = await this.do()
        return JSON.parse(await resp.text(), reviver)
      } catch (err) {
        if (err instanceof RespError && (err.resp.status === 404 || err.resp.status === 500)) {
          throw err
        }

        if (attempt < attempts) {
          await new Promise((resolve) => setTimeout(resolve, delay))
          return execute(attempt + 1)
        } else {
          // @ts-ignore
          return this.handleRespErr(err)
        }
      }
    }

    return execute(1)
  }

  /**
   * Call the API, return JSON if succeeded. Else, display broken message
   * to the user about error and rethrow
   * @param msg The message to display to the user on error
   */
  public async jsonMust<T>(
    reviver?: (key: string, value: any) => any,
    msg: string = 'An unexpected error occurred'
  ): Promise<T> {
    try {
      return await this.json<T>(reviver)
    } catch (err) {
      store.commit('broken', true)
      throw err
    }
  }
}

async function _getCachedResp(
  cacheKey: string,
  req: Request,
  cacheFor: number
): Promise<{
  resp: Response | undefined
  expiresAt: number
}> {
  let expiresAt: number = 0
  let resp: Response | undefined
  if (!types.config.cacheDisabled() && caches && cacheFor !== 0) {
    try {
      const cache = await caches.open(cacheKey)
      resp = await cache.match(req)
      if (resp && cacheFor > 0) {
        const cachedAtStr = resp.headers.get('date')
        if (cachedAtStr) {
          const cachedAt = new Date(cachedAtStr).getTime()
          expiresAt = cachedAt + cacheFor
          if (expiresAt < Date.now()) {
            await cache.delete(req)
            resp = undefined
          }
        }
      }
    } catch (err) {
      log.error('An error occurred getting cache', err)
    }
  }
  return { resp, expiresAt }
}

async function cacheResp(cacheKey: string, label: string, cacheFor: number, resp: Response, req: Request) {
  try {
    if (cacheFor > 0 && !resp.headers.has('date')) {
      log.error(`Cannot cache ${label} as response has no 'Date' header.`)
      return
    }

    const cache = await caches.open(cacheKey)
    await cache.put(req, resp.clone())
  } catch (err) {
    log.error('An error occurred storing cache', err)
  }
}

/**
 * Make an API call, utilizing the caching, logging, and error hanlding framework
 * for API requests
 * @param req The request to call
 * @param label The label to use when logging the call
 * @param cacheFor How long in ms to cache the calls response for. 0 to disable. -1 to cache forever
 * @param noError The default is for non-ok responses to throw an exception. Set this to true to override that.
 */
export async function call(
  req: Request,
  label: string,
  cacheFor: number = 0,
  noError: boolean = false
): Promise<Response> {
  const cacheKey = `api-${types.user.currentUserID()}`
  // expiresAt could be const, however It is better to destructure with let to do the API call once
  // eslint-disable-next-line
  let { resp, expiresAt } = await _getCachedResp(cacheKey, req, cacheFor)

  const notCached = !resp
  try {
    if (!resp) {
      resp = await fetch(req)
    }
  } catch (err) {
    // @ts-ignore
    await logError(label, req, err)
    throw err
  }

  if (!noError && resp && !resp.ok) {
    const err = new RespError(resp)
    await logError(label, req, err)
    throw err
  }

  if (resp.ok && notCached && caches && cacheFor != 0) {
    await cacheResp(cacheKey, label, cacheFor, resp, req)
  }

  await logSuccess(label, req, resp, !notCached, expiresAt)

  return resp
}

api.call = call

/**
 * Log a successful response
 * @param resp The resposne
 */
export async function logSuccess(label: string, req: Request, resp: Response, cached: boolean, cacheExpires: number) {
  const desc = `API CALL ${cached ? 'CACHE HIT' : 'SUCCESS'}`
  let msg = `API CALL ${desc}: ${label} - [${req.method}] ${req.url}`
  if (req.headers.get('x-unified-requestid')) {
    msg += ` (${req.headers.get('x-unified-requestid')})`
  }

  // Need to await for the body here, or concurrent
  // request logs could be nested under this log grouping
  let body
  if (types.config.isDev()) {
    const clone = resp.clone()
    body = await clone.text()
  }

  log.groupCollapsed(msg)
  if (cached) {
    log.debug(`Cache expires at ${new Date(cacheExpires).toLocaleString()}`)
  }
  logHeaders(req.headers, 'Request')
  logHeaders(resp.headers, 'Response')
  if (types.config.isDev()) {
    logBody(body)
  }
  log.groupEnd()
}

/**
 * Log an error from an API call
 * @param err An error from an api call
 */
export async function logError(label: string, req: Request, err: Error) {
  let desc = 'API CALL ERROR'
  if (api.isRespError(err)) {
    desc = 'API CALL NOT OK'
  }
  let msg = `${desc}: ${label} - [${req.method}] ${req.url}`
  if (req.headers.get('x-unified-requestid')) {
    msg += ` (${req.headers.get('x-unified-requestid')})`
  }

  // Need to await for the body here, or concurrent
  // request logs could be nested under this log grouping
  let body
  if (api.isRespError(err)) {
    body = await err.resp.text()
  }

  log.group(msg)
  log.error(err)
  logHeaders(req.headers, 'Request')
  if (api.isRespError(err)) {
    logHeaders(err.resp.headers, 'Response')
    logBody(body)
  }
  log.groupEnd()
}

/**
 * Log the response headers
 */
function logHeaders(headers: Headers, type: string) {
  const out: { [key: string]: string } = {}
  for (const k of headers) {
    out[k[0]] = k[1]
  }
  log.debug(`${type} Headers`, out)
}

/**
 * Log a response body
 * @param data The response body to log
 */
function logBody(data: any) {
  log.debug('Response Body:', data || '<NONE>')
}
