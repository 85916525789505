import api from '@/api'
import types from '@/types'
import Report, { UnsupportedMediaTypeReport } from '@/api/communique/report'

export interface ImpressionsByDevice {
  // the number of impressions for a specific device
  impressions: number

  // the type of the device
  device_type: string
}

export interface FetchImpressionsByDeviceParams {
  startDate?: string
  endDate?: string
  initiativeId?: string
  mediaTypes: string[]
  excludeLineItemIds?: string[]
  includeLineItemIds?: string
}

export class CampaignImpressionsByDeviceModel {
  public async fetchAll({
    initiativeId,
    mediaTypes,
    endDate,
    excludeLineItemIds,
    includeLineItemIds,
    startDate,
  }: FetchImpressionsByDeviceParams): Promise<Report<ImpressionsByDevice> | UnsupportedMediaTypeReport | null> {
    return api('report.dashboards.ihmCampaign.v2.device')
      .params({
        initiativeId,
        media_types: mediaTypes,
        end_date: endDate,
        start_date: startDate,
        exclude_line_item_ids: excludeLineItemIds,
        include_line_item_ids: includeLineItemIds,
      })
      .cache(1000 * types.config.dashboardCacheSeconds())
      .jsonMust<Report<ImpressionsByDevice>>()
  }
}
