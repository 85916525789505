export default interface Styles {
  [key: string]: any
}

function rangeScale(min: number, max: number, scale: number, maxScale: number, minScale: number = 0) {
  const range = max - min
  if (maxScale <= minScale) return min
  const percent = Math.max(scale - minScale, 0) / (maxScale - minScale)
  const result = min + range * percent
  return Math.max(Math.min(result, max), min)
}

export class StylesModel {
  public maxWidth = 1920 // We currently support a maximum width of 1080p
  public minWidth = 320 // We currently support lowest resolution of iphone 5

  scale(
    min: number,
    max: number,
    { width = screen.width, maxWidth = this.maxWidth, minWidth = 0, unit = 'px' } = {}
  ): string {
    return `${rangeScale(min, max, width, maxWidth, minWidth)}${unit}`
  }
}
