import api from '@/api'
import Report from '@/api/communique/report'
import types from '@/types'

//Subject to be modified as more stats are added
export interface CreativeStats {
  //the backend doens't return id
  //so we generate id based on the index for each row, that why it's optional
  id?: number
  media_type: string
  publisher: string
  name: string
  format: string
  tactic: string
  impressions: number
  clicks: number
  ctr: number
  vcr: number
  video_url?: string
  preview?: string
  creative_status: string
  vast?: boolean
}

export interface QueryParams {
  activeToggle: boolean
  offset?: number
  itemsPerPage?: number
  order_fields: string[]
  startDate?: string
  endDate?: string
  mediaTypes?: string[]
  initiativeId: string
  excludeLineItemIds?: string[]
  includeLineItemIds?: string
}

export class CampaignCreativeModel {
  public async fetch({
    activeToggle,
    offset,
    itemsPerPage,
    order_fields,
    initiativeId,
    mediaTypes,
    startDate,
    endDate,
    excludeLineItemIds,
    includeLineItemIds,
  }: QueryParams): Promise<Report<CreativeStats>> {
    return api('report.dashboards.ihmCampaign.v2.campaignCreativeStats')
      .params({
        activeCreativeToggle: activeToggle,
        offset: offset,
        itemsPerPage: itemsPerPage,
        orderFields: order_fields,
        initiativeId: initiativeId,
        media_types: mediaTypes,
        start_date: startDate,
        end_date: endDate,
        exclude_line_item_ids: excludeLineItemIds,
        include_line_item_ids: includeLineItemIds,
      })
      .cache(1000 * types.config.dashboardCacheSeconds())
      .jsonMust<Report<CreativeStats>>()
  }
}
