const EMAIL_TEMPLATE = `<!DOCTYPE html>
    <html>
    <head>
        <meta charset='utf-8'>
        <meta name='viewport' content='width=device-width, initial-scale=1'>
        <meta http-equiv='X-UA-Compatible' content='IE=edge' />
        <style type='text/css'>
            body, table, td, a{-webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%;}
            table, td{mso-table-lspace: 0pt; mso-table-rspace: 0pt;}
            img{-ms-interpolation-mode: bicubic;}
            img{border: 0; height: auto; line-height: 100%; outline: none; text-decoration: none;}
            table{border-collapse: collapse !important;}
            body{height: 100% !important; margin: 0 !important; padding: 0 !important; width: 100% !important;}
            a[x-apple-data-detectors] {
                color: inherit !important;
                text-decoration: none !important;
                font-size: inherit !important;
                font-family: inherit !important;
                font-weight: inherit !important;
                line-height: inherit !important;
            }
            @media screen and (max-width: 525px) {
                .wrapper {
                  width: 100% !important;
                  max-width: 100% !important;
                }
                .logo img {
                  margin: 0 auto !important;
                }
                .mobile-hide {
                  display: none !important;
                }
                .img-max {
                  max-width: 100% !important;
                  width: 100% !important;
                  height: auto !important;
                }
                .responsive-table {
                  width: 100% !important;
                }
                .padding {
                  padding: 10px 5% 15px 5% !important;
                }
                .padding-meta {
                  padding: 30px 5% 0px 5% !important;
                  text-align: center;
                }
                .padding-copy {
                     padding: 10px 5% 10px 5% !important;
                  text-align: center;
                }
                .no-padding {
                  padding: 0 !important;
                }
                .section-padding {
                  padding: 50px 15px 50px 15px !important;
                }
                .mobile-button-container  {
                    margin: 0 auto;
                    width: 100% !important;
                }
                .mobile-button {
                    padding: 15px !important;
                    border: 0 !important;
                    font-size: 16px !important;
                    display: block !important;
                }
            }
            div[style*="margin: 16px 0;"] { margin: 0 !important; }
        </style>
    </head>
    <body style='margin: 0 !important; padding: 0 !important;'>
        <div style='display: none; font-size: 1px; color: #fefefe; line-height: 1px; font-family: Helvetica, Arial, sans-serif; max-height: 0px; max-width: 0px; opacity: 0; overflow: hidden;'>
            Report is ready for download. Your requested report is now available for download. Please follow the link below to retrieve your report.
        </div>
        <table border='0' cellpadding='0' cellspacing='0' width='100%'>
            <tr>
                <td bgcolor='#ffffff' align='center'>
                    <!--[if (gte mso 9)|(IE)]>
                    <table align="center" border="0" cellspacing="0" cellpadding="0" width="500">
                    <tr>
                    <td align="center" valign="top" width="500">
                    <![endif]-->
                    <table border='0' cellpadding='0' cellspacing='0' width='100%' style='max-width: 500px;' class='wrapper'>
                        <tr>
                            <td align='center' valign='top' style='padding: 15px 0;' class='logo'>
                                <a href='https://get.unified.com/' target='_blank'>
                                    <img alt='Unified' src='https://s3.amazonaws.com/unified.com/images/unified-logo-horizontal-2.png' width='200' height='51' style='display: block; font-family: Helvetica, Arial, sans-serif; color: #ffffff; font-size: 16px;' border='0'>
                                </a>
                            </td>
                        </tr>
                    </table>
                    <!--[if (gte mso 9)|(IE)]>
                    </td>
                    </tr>
                    </table>
                    <![endif]-->
                </td>
            </tr>
            <tr>
                <td bgcolor='#E1FBF9' align='center' style='padding: 30px 15px 70px 15px;' class='section-padding'>
                    <!--[if (gte mso 9)|(IE)]>
                    <table align="center" border="0" cellspacing="0" cellpadding="0" width="500">
                    <tr>
                    <td align="center" valign="top" width="500">
                    <![endif]-->
                    <table border='0' cellpadding='0' cellspacing='0' width='100%' style='max-width: 500px;' class='responsive-table'>
                        <tr>
                            <td>
                                <table width='100%' border='0' cellspacing='0' cellpadding='0'>
                                    <tr>
                                        <td>
                                            <table width='100%' border='0' cellspacing='0' cellpadding='0'>
                                                <tr>
                                                    <td align='center' style='font-size: 25px; font-family: Helvetica, Arial, sans-serif; color: #333333; padding-top: 30px;' class='padding'>$email_title</td>
                                                </tr>
                                                <tr>
                                                    <td align='center' style='padding: 20px 0 0 0; font-size: 16px; line-height: 25px; font-family: Helvetica, Arial, sans-serif; color: #666666;' class='padding'>$email_body</td>
                                                </tr>
                                            </table>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td align='center'>
                                            <table width='100%' border='0' cellspacing='0' cellpadding='0'>
                                                <tr>
                                                    <td align='center' style='padding-top: 25px;' class='padding'>
                                                        <table border='0' cellspacing='0' cellpadding='0' class='mobile-button-container'>
                                                            <tr>
                                                                <td align='center' style='border-radius: 3px;' bgcolor='#007288'>
                                                                    <a href='$file_location' target='_blank' style='font-size: 16px; font-family: Helvetica, Arial, sans-serif; color: #ffffff; text-decoration: none; color: #ffffff; text-decoration: none; border-radius: 3px; padding: 15px 25px; border: 1px solid #007288; display: inline-block;' class='mobile-button'>
                                                                        Download File &rarr;
                                                                    </a>
                                                                </td>
                                                            </tr>
                                                        </table>
                                                    </td>
                                                </tr>
                                            </table>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td align='center'>
                                            <table width='100%' border='0' cellspacing='0' cellpadding='0'>
                                                <tr>
                                                    <td align='center' style='padding-top: 25px;' class='padding'>
                                                        <table border='0' cellspacing='0' cellpadding='0' class='mobile-button-container'>
                                                            <tr>
                                                                <td align='center' style='font-size: 12px; line-height: 18px; font-family: Helvetica, Arial, sans-serif; color:#888888;'>
                                                                Having trouble? If the link above is not working, <br />please try copying and pasting the URL below. </td>
                                                            </tr>
                                                            <tr>
                                                                <td align='center' style='border-radius: 3px;' >
                                                                    <p style='font-size: 14px; font-family: Helvetica, Arial, sans-serif; color: #888888; text-decoration: none; color: #888888; background-color: #F4F3B6; text-decoration: none; border-radius: 3px; padding: 15px 25px; border: 1px solid #888888; display: inline-block;' class='mobile-button'>
                                                                        $file_location
                                                                    </p>
                                                                </td>
                                                            </tr>
                                                            
                                                        </table>
                                                    </td>
                                                </tr>
                                            </table>
                                        </td>
                                    </tr>
                                    
                                </table>
                            </td>
                        </tr>
                    </table>
                    <!--[if (gte mso 9)|(IE)]>
                    </td>
                    </tr>
                    </table>
                    <![endif]-->
                </td>
            </tr>
            <tr>
                <td bgcolor='#ffffff' align='center' style='padding: 20px 0px;'>
                    <!--[if (gte mso 9)|(IE)]>
                    <table align="center" border="0" cellspacing="0" cellpadding="0" width="500">
                    <tr>
                    <td align="center" valign="top" width="500">
                    <![endif]-->
                    <!-- UNSUBSCRIBE COPY -->
                    <table width='100%' border='0' cellspacing='0' cellpadding='0' align='center' style='max-width: 500px;' class='responsive-table'>
                        <tr>
                            <td align='center' style='font-size: 12px; line-height: 18px; font-family: Helvetica, Arial, sans-serif; color:#666666;'>
                            &copy; 2021 Unified Enterprises Corp. | <a href='https://unified.com/privacy-policy/' target='_blank'>Privacy Policy</a>
                                <br>
                                180 Madison Avenue, New York NY 10016
                                <br /><br />
                                <a href='http://unified.com'>Learn more about Unified</a> &bull; <a href='https://support.unified.com/hc/en-us'>Visit Knowledge Base</a>
                                <br /><br />
                                Having trouble? <a href='mailto:help@unified.com'>help@unified.com</a>
                            </td>
                        </tr>
                    </table>    
                </td>
            </tr>
        </table>
    </body>
    </html>`
const EMAIL_TEMPLATE_FAILED = `
<!DOCTYPE html>
<html>
<head>
<meta charset="utf-8">
<meta name="viewport" content="width=device-width, initial-scale=1">
<meta http-equiv="X-UA-Compatible" content="IE=edge" />
<style type="text/css">
    /* CLIENT-SPECIFIC STYLES */
    body, table, td, a{-webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%;} /* Prevent WebKit and Windows mobile changing default text sizes */
    table, td{mso-table-lspace: 0pt; mso-table-rspace: 0pt;} /* Remove spacing between tables in Outlook 2007 and up */
    img{-ms-interpolation-mode: bicubic;} /* Allow smoother rendering of resized image in Internet Explorer */

    /* RESET STYLES */
    img{border: 0; height: auto; line-height: 100%; outline: none; text-decoration: none;}
    table{border-collapse: collapse !important;}
    body{height: 100% !important; margin: 0 !important; padding: 0 !important; width: 100% !important;}

    /* iOS BLUE LINKS */
    a[x-apple-data-detectors] {
        color: inherit !important;
        text-decoration: none !important;
        font-size: inherit !important;
        font-family: inherit !important;
        font-weight: inherit !important;
        line-height: inherit !important;
    }

    /* MOBILE STYLES */
    @media screen and (max-width: 525px) {

        /* ALLOWS FOR FLUID TABLES */
        .wrapper {
          width: 100% !important;
            max-width: 100% !important;
        }

        /* ADJUSTS LAYOUT OF LOGO IMAGE */
        .logo img {
          margin: 0 auto !important;
        }

        /* USE THESE CLASSES TO HIDE CONTENT ON MOBILE */
        .mobile-hide {
          display: none !important;
        }

        .img-max {
          max-width: 100% !important;
          width: 100% !important;
          height: auto !important;
        }

        /* FULL-WIDTH TABLES */
        .responsive-table {
          width: 100% !important;
        }

        /* UTILITY CLASSES FOR ADJUSTING PADDING ON MOBILE */
        .padding {
          padding: 10px 5% 15px 5% !important;
        }

        .padding-meta {
          padding: 30px 5% 0px 5% !important;
          text-align: center;
        }

        .padding-copy {
             padding: 10px 5% 10px 5% !important;
          text-align: center;
        }

        .no-padding {
          padding: 0 !important;
        }

        .section-padding {
          padding: 50px 15px 50px 15px !important;
        }

        /* ADJUST BUTTONS ON MOBILE */
        .mobile-button-container  {
            margin: 0 auto;
            width: 100% !important;
        }

        .mobile-button {
            padding: 15px !important;
            border: 0 !important;
            font-size: 16px !important;
            display: block !important;
        }

    }

    /* ANDROID CENTER FIX */
    div[style*="margin: 16px 0;"] { margin: 0 !important; }
</style>
</head>
<body style="margin: 0 !important; padding: 0 !important;">


<!-- HEADER -->
<table border="0" cellpadding="0" cellspacing="0" width="100%">
    <tr>
        <td bgcolor="#ffffff" align="center">
            <!--[if (gte mso 9)|(IE)]>
            <table align="center" border="0" cellspacing="0" cellpadding="0" width="500">
            <tr>
            <td align="center" valign="top" width="500">
            <![endif]-->
            <table border="0" cellpadding="0" cellspacing="0" width="100%" style="max-width: 500px;" class="wrapper">
                <tr>
                    <td align="center" valign="top" style="padding: 15px 0;" class="logo">
                        <a href="https://get.unified.com/" target="_blank">
                            <img alt="Unified" src="https://s3.amazonaws.com/unified.com/images/unified-logo-horizontal-2.png" width="200" height="51" style="display: block; font-family: Helvetica, Arial, sans-serif; color: #ffffff; font-size: 16px;" border="0">
                        </a>
                    </td>
                </tr>
            </table>
            <!--[if (gte mso 9)|(IE)]>
            </td>
            </tr>
            </table>
            <![endif]-->
        </td>
    </tr>
    <tr>
        <td bgcolor="#F8E4E4" align="center" style="padding: 30px 15px 70px 15px;" class="section-padding">
            <!--[if (gte mso 9)|(IE)]>
            <table align="center" border="0" cellspacing="0" cellpadding="0" width="500">
            <tr>
            <td align="center" valign="top" width="500">
            <![endif]-->
            <table border="0" cellpadding="0" cellspacing="0" width="100%" style="max-width: 500px;" class="responsive-table">
                <tr>
                    <td>
                        <!-- HERO IMAGE -->
                        <table width="100%" border="0" cellspacing="0" cellpadding="0">
                            <tr>
                                <td>
                                    <table width="100%" border="0" cellspacing="0" cellpadding="0">
                                        <tr>
                                            <td align="center" style="font-size: 25px; font-family: Helvetica, Arial, sans-serif; color: #cc0000; padding-top: 30px;" class="padding">Export Error</td>
                                        </tr>
                                        <tr>
                                            <td align="center" style="padding: 20px 0 0 0; font-size: 16px; line-height: 25px; font-family: Helvetica, Arial, sans-serif; color: #cc0000;" class="padding">
                                                $error_message
                                            </td>
                                        </tr>
                                    </table>
                                </td>
                            </tr>
                            <!-- START: Optional section for body copy contained within scheduled reports. -->
                            <tr >
                                <td>
                                    <!-- COPY -->
                                    <table width="100%" border="0" cellspacing="0" cellpadding="0">
                                       
                                        <tr>
                                            <td align="center" style="padding: 20px 0 0 0; font-size: 13px; line-height: 25px; font-family: Helvetica, Arial, sans-serif; color: #444444;" class="padding">
                                            \tIf you need immediate assistance, please use this link to <a href="$support_info">email our support team</a>. Please include as many details as possible about your experience.
                                            </td>
                                        </tr>
                                    </table>
                                </td>
                            </tr>
                            <!-- END: Optional section for body copy contained within scheduled reports. -->
                        </table>
                    </td>
                </tr>
            </table>
            <!--[if (gte mso 9)|(IE)]>
            </td>
            </tr>
            </table>
            <![endif]-->
        </td>
    </tr>
    <tr>
        <td bgcolor="#ffffff" align="center" style="padding: 20px 0px;">
            <!--[if (gte mso 9)|(IE)]>
            <table align="center" border="0" cellspacing="0" cellpadding="0" width="500">
            <tr>
            <td align="center" valign="top" width="500">
            <![endif]-->
            <!-- UNSUBSCRIBE COPY -->
            <table width="100%" border="0" cellspacing="0" cellpadding="0" align="center" style="max-width: 500px;" class="responsive-table">
                <tr>
                    <td align="center" style="font-size: 12px; line-height: 18px; font-family: Helvetica, Arial, sans-serif; color:#666666;">

                    &copy; 2021 Unified Enterprises Corp. | <a href="https://unified.com/privacy-policy/" target="_blank">Privacy Policy</a>
                        <br>
                        180 Madison Avenue, New York NY 10016
                        <br /><br />
                        <a href="http://unified.com">Learn more about Unified</a> &bull; <a href="https://support.unified.com/hc/en-us">Visit Knowledge Base</a>
                        <br /><br />
                        Having trouble? <a href="mailto:help@unified.com">help@unified.com</a>
                        
                    </td>
                </tr>
            </table>    
        </td>
    </tr>
</table>
</body>
</html>
`

const get_email_template = (title: string, body: string, fileLocation: string): string => {
  let content = EMAIL_TEMPLATE.replace(/\$email_title/gi, title)
  content = content.replace(/\$email_body/gi, body)
  content = content.replace(/\$file_location/gi, fileLocation)
  return content
}

const get_export_failed_email_template = (errorMessage: string): string => {
  return EMAIL_TEMPLATE_FAILED.replace(/\$error_message/gi, errorMessage)
}

export { get_email_template, get_export_failed_email_template }
