import Highcharts from 'highcharts'
import HighchartsVue from 'highcharts-vue'
import exporting from 'highcharts/modules/exporting'
import exportData from 'highcharts/modules/export-data'
import offlineExporting from 'highcharts/modules/offline-exporting'
import highchartsFunnel from 'highcharts/modules/funnel'
import highchartsSankey from 'highcharts/modules/sankey'
import mapInit from 'highcharts/modules/map'
import Vue from 'vue'

highchartsFunnel(Highcharts)
highchartsSankey(Highcharts)
exporting(Highcharts)
exportData(Highcharts)
offlineExporting(Highcharts)
mapInit(Highcharts)

Vue.use(HighchartsVue)
