import api from '@/api'
import Report, { UnsupportedMediaTypeReport } from '@/api/communique/report'
import types from '@/types'

export interface LandingPagePerformanceStat {
  directions: number
  more_info: number
  coupon: number
  adgroup_name: string
  adgroup_id: number
  click_to_call: number
  website: number
  html?: string
  creative_id?: string
}

export interface queryParams {
  companyId: string
  mediaTypes: string[]
  startDate?: string
  endDate?: string
  excludedInitiativeIDs?: string[]
  includedInitiativeIDs?: string[]
  creativeId?: string
}

export class LandingPagePerformanceStatsModel {
  public async fetchAll({
    companyId,
    mediaTypes,
    startDate,
    endDate,
    excludedInitiativeIDs,
    includedInitiativeIDs,
  }: queryParams): Promise<Report<LandingPagePerformanceStat> | UnsupportedMediaTypeReport> {
    return api('report.dashboards.ihmCompany.landingPagePerformanceStats')
      .params({
        companyId: companyId,
        media_types: mediaTypes,
        start_date: startDate,
        end_date: endDate,
        exclude_initiative_ids: excludedInitiativeIDs,
        include_initiative_ids: includedInitiativeIDs,
      })
      .cache(1000 * types.config.dashboardCacheSeconds())
      .jsonMust<Report<LandingPagePerformanceStat>>()
  }
}
