import api from '@/api'
import types from '@/types'
import Report, { UnsupportedMediaTypeReport } from '@/api/communique/report'

export interface TacticPerformance {
  // the number of impressions for a specific grouping
  impressions: number

  // the number of clicks for a specific grouping
  clicks: number

  // the grouping to group the result by
  grouping: string
}

export interface FetchTacticPerformanceParams {
  companyId: string
  startDate?: string
  endDate?: string
  mediaTypes?: string[]
  excludedInitiativeIDs?: string[]
  includedInitiativeIDs?: string[]
  identityID?: string
}

export class TacticPerformanceModel {
  public async fetchAll({
    companyId,
    mediaTypes,
    endDate,
    excludedInitiativeIDs,
    includedInitiativeIDs,
    startDate,
  }: FetchTacticPerformanceParams): Promise<Report<TacticPerformance> | UnsupportedMediaTypeReport | null> {
    return api('report.dashboards.ihmCompany.tacticPerformanceStats')
      .params({
        companyId,
        media_types: mediaTypes,
        end_date: endDate,
        exclude_initiative_ids: excludedInitiativeIDs,
        include_initiative_ids: includedInitiativeIDs,
        start_date: startDate,
      })
      .cache(1000 * types.config.dashboardCacheSeconds())
      .jsonMust<Report<TacticPerformance>>()
  }
}
