import api from '@/api'
import types from '@/types'
import Report, { UnsupportedMediaTypeReport } from '@/api/communique/report'

export interface ImpressionsByPublisher {
  // the number of impressions a publisher has
  impressions: number

  // the name of the publisher
  publisher: string

  // the media type of the publisher
  media_type: string
}

export interface FetchImpressionsByPublisherParams {
  companyId: string
  startDate?: string
  endDate?: string
  mediaTypes: string[]
  excludedInitiativeIDs?: string[]
  includedInitiativeIDs?: string[]
  identityID?: string
}

export class ImpressionsByPublisherModel {
  public async fetchAll({
    companyId,
    mediaTypes,
    endDate,
    excludedInitiativeIDs,
    includedInitiativeIDs,
    startDate,
  }: FetchImpressionsByPublisherParams): Promise<Report<ImpressionsByPublisher> | UnsupportedMediaTypeReport | null> {
    return api('report.dashboards.ihmCompany.publisherStats')
      .params({
        companyId,
        media_types: mediaTypes,
        end_date: endDate,
        exclude_initiative_ids: excludedInitiativeIDs,
        include_initiative_ids: includedInitiativeIDs,
        start_date: startDate,
      })
      .cache(1000 * types.config.dashboardCacheSeconds())
      .jsonMust<Report<ImpressionsByPublisher>>()
  }
}
