import Vue from 'vue'
import Vuex, { StoreOptions } from 'vuex'

import log from '@/log'
import types from '@/types'
import Dashboard from '@/types/dashboard'
import UserMessage from '@/types/userMessage'
import selectionModule from '@/store/modules/selection'

const DarkKey = (email: string) => `${email}-dark`
const DrawerKey = (email: string) => `${email}-drawer`

interface LDFlagValues {
  [key: string]: boolean
}

export interface RootState {
  /**
   * Whether or not the global state has been broken. Generally
   * happens when a necessary API call fails, but could happen
   * from some assertion failure
   */
  broken: boolean
  /*
   * Whether or not the user has the dark theme turned on
   */
  dark: boolean | null
  /**
   * The list of dashboards the user has access to
   */
  dashboards: Dashboard[] | null
  /**
   * Whether or not the global drawer is open
   */
  drawer: boolean
  /**
   * The current error message to display to the user
   */
  error: string
  /**
   * LD flag values for the user
   */
  ldFlags: LDFlagValues
  /**
   * The current info message to display to the user
   */
  messages: UserMessage[]
  /**
   * is the IHM Company dashboard downloading or not
   */
  downloading: boolean
}

/**
 * This gets the dark theme toggle stored in localStorage for the logged in user.
 * @returns boolean
 */
function storedDark(): boolean | null {
  const item = localStorage.getItem(DarkKey(types.user.currentUserID()))
  if (item === null) return null
  return item === 'true'
}

/**
 * This gets the drawer toggle stored in localStorage for the logged in user.
 * @returns string
 */
function storedDrawer(): boolean {
  if (!types || !types.user) {
    return false
  }
  return localStorage.getItem(DrawerKey(types.user.currentUserID())) === 'true'
}

const store: StoreOptions<RootState> = {
  actions: {
    async checkLDFlags({ commit }, flags: string[]) {
      try {
        const results = await Promise.all(flags.map(async (f) => await types.user.isEnabled(f)))

        const flagValues = flags.reduce<LDFlagValues>((r, f, idx) => {
          r[f] = results[idx]
          return r
        }, {})

        commit('addLDFlags', flagValues)
      } catch (err) {
        log.error(`Failed to fetch LD flags of [${flags.join(', ')}]. Set values to false: ${err}`)

        const flagValues = flags.reduce<LDFlagValues>((r, f) => {
          r[f] = false

          return r
        }, {})

        commit('addLDFlags', flagValues)
      }
    },
  },
  modules: {
    selection: selectionModule,
  },
  getters: {
    messages(state): UserMessage[] {
      return state.messages
    },
    checkHasAccessTo(state) {
      return (flag: string): boolean => {
        return Boolean(state.ldFlags[flag])
      }
    },
  },
  mutations: {
    broken(state, broken) {
      state.broken = broken
    },
    dark(state, dark) {
      state.dark = dark
      localStorage.setItem(DarkKey(types.user.currentUserID()), `${state.dark}`)
    },
    dashboards(state, dashboards) {
      state.dashboards = dashboards
    },
    error(state, message) {
      state.messages.push({ type: 'error', message })
    },
    message(state, message) {
      // If we have a bunch of messages on here don't spam the user
      if (state.messages.length > 5) state.messages.splice(0, 5)
      state.messages.push({ type: 'info', message })
    },
    removeMessage(state) {
      state.messages.shift()
    },
    toggleDrawer(state, val) {
      if (val === undefined) val = !state.drawer
      state.drawer = val
      localStorage.setItem(DrawerKey(types.user.currentUserID()), `${state.drawer}`)
    },
    addLDFlags(state, flagValues: LDFlagValues) {
      Object.keys(flagValues).forEach((flag) => {
        state.ldFlags[flag] = flagValues[flag]
      })
    },
    downloading(state, downloading) {
      state.downloading = downloading
    },
  },
  state: {
    broken: false,
    dark: null,
    dashboards: null,
    drawer: storedDrawer(),
    error: '',
    ldFlags: {},
    messages: [],
    downloading: false,
  },
}

Vue.use(Vuex)

export default new Vuex.Store(store)
