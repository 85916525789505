import api from '@/api'
import types from '@/types'

export interface SisenseDashboard {
  _id?: string
  title?: string
  oid: string
  desc?: string
  source?: null | string
  type?: string
  shares?: any[]
  tags?: any[]
  style?: any
  owner?: string
  userId?: string
  created?: string
  lastUpdated?: string
  layout?: any
  instanceType?: string
  datasource?: any
  filters?: any[]
  editing?: boolean
  lastOpened?: string
  filterToDatasourceMapping?: any
  subscription?: any
  isPublic?: null
  parentFolder?: null | string
  previewLayout?: any
  dataExploration?: boolean
  opened?: boolean
  hiddenForNonOwner?: boolean
  defaultFilters?: any[] | null
  allowChangeSubscription?: boolean
  script?: string
  systemName?: string
  lastPublish?: string
  settings?: any
  hierarchies?: any[]
}

export class SisenseModel {
  public async fetchAll(): Promise<SisenseDashboard[]> {
    const call = api('platform.dashboards').cache(1000 * types.config.dashboardCacheSeconds())

    return types.config.isDev() ? await call.json<SisenseDashboard[]>() : await call.jsonMust<SisenseDashboard[]>()
  }
}
