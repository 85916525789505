import types from '@/types'
import Company from '@/types/company'
import UserStorage from '@unified/js-common/lib/user/User'
import UserFlags from '@unified/js-common/lib/user/UserFlags'
export default interface User {
  /**
   * The user's company
   */
  company: Company
  /**
   * The date the user was created
   */
  createDate: string
  /**
   * The user's default currency
   */
  defaultCurrency: string
  /**
   * The user's email
   */
  email: string
  /**
   * The user's first name
   */
  firstName: string
  /**
   * The user's identity ID
   */
  identityID: string
  /**
   * If this is a temp user, the brand for the audiences to share
   */
  tempUserBrand: string | null
  /**
   * If this is a temp user, the person who invited them
   */
  tempUserInvitor: string | null
  /**
   * Is this user an admin
   */
  isAdmin: boolean
  /**
   * Is this user a provider admin
   */
  isProviderAdmin: boolean
  /**
   * Is this user a temporary user
   */
  isTemp: boolean
  /**
   * The user's last name
   */
  lastName: string
  /**
   * The user's timezone
   */
  timezone: string
  /**
   * The user's title
   */
  title: string
  /**
   * The user's title type
   */
  titleType: string
}

export interface ImpersonatedUser {
  /**
   * The impersonated user's company ID
   */
  companyID: string
  /**
   * The impersonated user's company name
   */
  companyName: string
  /**
   * The impersonated user's date created
   */
  dateCreated: string
  /**
   * The impersonated user's date modified
   */
  dateModified: string
  /**
   * The impersonated user's default currencty
   */
  defaultCurrency: string
  /**
   * The impersonated user's disabled status
   */
  disabled: boolean
  /**
   * The impersonated user's email
   */
  email: string
  /**
   * The impersonated user's first name
   */
  firstName: string
  /**
   * The impersonated user's ID
   */
  id: string
  /**
   * The impersonated user's identity ID
   */
  identityID: string
  /**
   * The impersonated user's admin status
   */
  isAdmin: boolean
  /**
   * The impersonated user's languages
   */
  language: string
  /**
   * The impersonated user's last name
   */
  lastName: string
  /**
   * The impersonated user's notifications
   */
  notifications: string
  /**
   * The impersonated user's profile status
   */
  profileStatus: string
  /**
   * The impersonated user's timezone
   */
  timezone: string
  /**
   * The impersonated user's company
   */
  parentCompany: Company
}

export interface ParentCompany {
  id: string
  name: string
  allowedAccess: string[]
}

/**
 * Contains methods for working with the User type
 */
export class UserModel {
  /**
   * Get the impersonated or logged in user's identityID
   */

  public async isEnabled(flag: string, enabledDefault = false): Promise<boolean> {
    const f = await UserFlags.isEnabled(flag, enabledDefault)

    return f
  }

  public currentUserID(): string {
    if (types.config.apiAuthentication() === 'bypass') {
      return types.config.byPassIdentityId()
    }
    return UserStorage.currentUserId()
  }

  /**
   * The current auth token
   */
  public authToken(): string {
    return UserStorage.authToken()
  }

  /**
   * True if we're impersonating a user
   */
  public impersonating(): boolean {
    return this.currentUserID() !== this.loginUser().identityID
  }

  /**
   * Get the current logged in user's company. NOT IMPERSONATED
   */
  public current(): User {
    if (this.impersonating()) return this.impersonatedUser()
    return this.loginUser()
  }

  /**
   * The impersonated users information
   */
  public impersonatedUser(): User {
    const i: ImpersonatedUser = UserStorage.impersonating()
    return {
      company: i.parentCompany,
      createDate: i.dateCreated,
      defaultCurrency: i.defaultCurrency,
      email: i.email,
      firstName: i.firstName,
      identityID: i.identityID,
      isAdmin: i.isAdmin,
      isProviderAdmin: false, // Can never impersonate provider
      isTemp: false, // Can never impersonate temp user
      tempUserBrand: null,
      tempUserInvitor: null,
      lastName: i.lastName,
      timezone: i.timezone,
      title: '', // Title isn't included in impersonation data
      titleType: '', // TitleType isn't included in impersonation data
    }
  }

  public loginUser(): User {
    return {
      company: UserStorage.parentCompany(),
      createDate: UserStorage.createDate(),
      defaultCurrency: UserStorage.defaultCurrency(),
      email: UserStorage.email(),
      firstName: UserStorage.firstName(),
      identityID: UserStorage.identityId(),
      isAdmin: UserStorage.isAdmin(),
      isProviderAdmin: UserStorage.isProviderAdmin(),
      isTemp: UserStorage.isTemp(),
      tempUserBrand: UserStorage.tempUserBrand(),
      tempUserInvitor: UserStorage.tempUserInvitor(),
      lastName: UserStorage.lastName(),
      timezone: UserStorage.timezone(),
      title: UserStorage.title(),
      titleType: UserStorage.titleType(),
    }
  }
}
