import api from '@/api'
import fileDownload from 'js-file-download'
import Cookie from 'js-cookie'
import log from '@/log'
import types from '@/types'
import { get_email_template, get_export_failed_email_template } from '@/helpers/email_template'
import slugify from '@/helpers/slugify'

const RENDER_COOKIES = [
  'authToken',
  'config',
  'dateCreated',
  'defaultTimezone',
  'displayName',
  'email',
  'identityId',
  'isAdmin',
  'isProviderAdmin',
  'sid',
  'userCompany',
  'usingSisenseSso',
]

export enum RenderType {
  Screenshot = 'screenshot',
  PDF = 'pdf',
  PPT = 'ppt',
}

interface EmailBody {
  from: string
  htmlBody?: string
  textBody?: string
  subject?: string
  to: string[]
  cc?: string[]
  bcc?: string[]
}

interface RenderBodyCookie {
  name: string
  value: unknown
  secure: boolean
  path: string
  domain: string
}

interface RenderBody {
  filename: string
  urls: {
    url: string
    cookies: RenderBodyCookie[]
  }[]
  src_key: string
  options: {
    delay: number
    timeout: number
  }
  screenshotOptions?: {
    selector?: string
  }
  pdfOptions?: {
    selector?: string
    landscape?: boolean
    margin?: any
    printBackground?: boolean
  }
  customs?: Object
}

interface JobBody {
  method: string
  url: string
  body: RenderBody
  email?: EmailBody
  errorEmail?: EmailBody
  customs?: Object
}

export interface RenderResponse {
  id: string
}

export enum RenderJobResponseStatus {
  Failed = 'FAILED',
  Processing = 'PROCESSING',
  Finished = 'FINISHED',
}

export interface RenderJobResponse {
  status: RenderJobResponseStatus
  data?: {
    status: number
    preview_key: string
    file_key: string
    file_url: string
  }
}

export class RenderModel {
  public async renderDownload(
    url: string,
    type: RenderType,
    name: string,
    selector: string = '',
    customs: Object = {}
  ): Promise<RenderJobResponse> {
    const finished = await this.renderPoll(url, type, name, selector, [], false, [], '', customs)

    await this.downloadPreview(assertv(finished.data).preview_key, finished.data?.file_key ?? 'unknown')

    return finished
  }

  public async renderPoll(
    url: string,
    type: RenderType,
    name: string,
    selector: string = '',
    emails: string[] = [],
    ccMe: boolean = false,
    ccs: string[] = [],
    from: string = '',
    customs: Object = {}
  ): Promise<RenderJobResponse> {
    const meta = await this.render(url, type, name, selector, emails, ccMe, ccs, from, customs)

    return await this.pollJob(assertv(meta).id)
  }

  public async render(
    url: string,
    type: RenderType,
    name: string,
    selector: string = '',
    emails: string[] = [],
    ccMe: boolean = false,
    ccs: string[] = [],
    from: string = '',
    customs: Object = {}
  ): Promise<RenderResponse> {
    log.debug(`Starting ${type} render`)
    const body = this.buildJobBody(url, type, name, selector, emails, ccMe, ccs, from, customs)

    return await api('platform.rendering.jobs')
      .method('POST')
      .params({ body })
      .headers({
        accept: 'application/json',
        'X-Unified-Service-Versions': JSON.stringify({
          rendering: '1.5',
        }),
      })
      .json<RenderResponse>()
  }

  public async pollJob(id: string): Promise<RenderJobResponse> {
    return new Promise((resolve, reject) => {
      const int = setInterval(async () => {
        try {
          const body = await api('platform.rendering.jobs.job')
            .params({ src_key: this.buildSrcKey(), id })
            .headers({
              accept: 'application/json',
              'X-Unified-Service-Versions': JSON.stringify({
                rendering: '1.5',
              }),
            })
            .json<RenderJobResponse>()

          if (body.status === RenderJobResponseStatus.Processing) return
          if (body.status === RenderJobResponseStatus.Failed) {
            clearInterval(int)
            reject({ message: 'Server Render Job Failed' })
            return
          }

          clearInterval(int)
          resolve(body)
        } catch (e) {
          clearInterval(int)
          reject(e)
        }
      }, 2500)
    })
  }

  public async downloadPreview(key: string, fileKey: string) {
    const data = await api('platform.rendering.preview').params({ src_key: this.buildSrcKey(), key }).blob()

    fileDownload(data, fileKey)
  }

  buildJobBody(
    url: string,
    type: RenderType,
    name: string,
    selector: string,
    emails: string[],
    ccMe: boolean,
    ccs: string[] = [],
    from: string = '',
    customs: Object = {}
  ): JobBody {
    if (!from) {
      from = types.user.current().email
    }
    const body: JobBody = {
      method: 'POST',
      url: `/1.5/render/${type}`,
      body: this.buildBody(url, name, type, selector, customs),
    }

    // include email for receiving the rendered file via Email
    if (emails && emails.length) {
      const htmlBody = get_email_template(
        'File Download',
        'Your file is now available for download.<br>Please use the link below to download your file.',
        '${file_url}?srcKey=native-dashboards'
      )
      const failedHtmlBody = get_export_failed_email_template(
        `Failed to generate presentation file for initiative <a href="${window.location.href}">${name}</a>.`
      )
      body.email = {
        from,
        htmlBody,
        subject: 'Generated Report (${preview_key}) from Unified',
        to: emails,
        cc: ccs,
      }
      body.errorEmail = {
        from,
        htmlBody: failedHtmlBody,
        subject: `Dashboard failed To generate presentation file`,
        to: emails,
        cc: ccs,
      }
      if (ccMe && !ccs.includes(from)) {
        body.email.cc?.push(from)
      }
    }

    return body
  }

  buildSrcKey() {
    return `native-dashboards`
  }

  buildBody(url: string, name: string, type: string, selector: string, customs: Object = {}): RenderBody {
    const body: RenderBody = {
      filename: slugify(name),
      urls: [
        {
          url,
          cookies: [],
        },
      ],
      src_key: this.buildSrcKey(),
      options: {
        delay: 5000,
        timeout: 120000,
      },
      customs,
    }

    if (type === 'screenshot' && selector) {
      body.screenshotOptions = { selector }
    }

    if (type === 'pdf') {
      body.pdfOptions = { landscape: true }
      // if (selector) body.pdfOptions.selector = selector
    }

    if (type === 'ppt') {
      // Presentation file perform more works than screenshot (Download creatives, widgets, archiving)
      // so increase timeout for this case.
      body.options.timeout = 300000
    }

    // Detect environment through the browser URL. contains ".cute.on" is stage
    const isStageEnvironment = window.location.hostname?.includes('.cuteon.me')
    // Add some cookies from Web Page for passing Authentication
    for (const [key, value] of Object.entries(Cookie.get())) {
      if (RENDER_COOKIES.includes(key)) {
        const newCookie: RenderBodyCookie = {
          name: key,
          value,
          secure: true,
          path: '/',
          domain: isStageEnvironment ? '.cuteon.me' : '.unified.com',
        }

        body.urls[0].cookies.push(newCookie)
      }
    }

    return body
  }
}
