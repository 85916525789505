import api from '@/api'
import Report, { UnsupportedMediaTypeReport } from '@/api/communique/report'
import types from '@/types'

export interface CompanyDailyMediaTypeClickStat {
  stat_date: string
  clicks: number
  media_type: string
}

interface queryParams {
  companyId: string
  mediaTypes: string[]
  startDate?: string
  endDate?: string
  excludedInitiativeIDs?: string[]
  includedInitiativeIDs?: string[]
}

export class CompanyDailyMediaTypeClickStatsModel {
  public async fetchAll({
    companyId,
    mediaTypes,
    startDate,
    endDate,
    excludedInitiativeIDs,
    includedInitiativeIDs,
  }: queryParams): Promise<Report<CompanyDailyMediaTypeClickStat> | UnsupportedMediaTypeReport> {
    return api('report.dashboards.ihmCompany.companyDailyMediaTypeClickStats')
      .params({
        companyId: companyId,
        media_types: mediaTypes,
        start_date: startDate,
        end_date: endDate,
        exclude_initiative_ids: excludedInitiativeIDs,
        include_initiative_ids: includedInitiativeIDs,
      })
      .cache(1000 * types.config.dashboardCacheSeconds())
      .jsonMust<Report<CompanyDailyMediaTypeClickStat>>()
  }
}
