import colors from '@/stylus/colors.json'

export interface PublisherLogosType {
  label: string
  icon: string
  color: string
}

const PublisherLogosMap = {
  Facebook: {
    label: 'facebook',
    icon: 'fa-facebook-square',
    color: colors.facebook.primary,
  },
  Instagram: {
    label: 'instagram',
    icon: 'fa-instagram',
    color: colors.instagram.primary,
  },
  Linkedin: {
    label: 'linkedin',
    icon: 'fa-linkedin-square',
    color: colors.linkedin.primary,
  },
  Pinterest: {
    label: 'pinterest',
    icon: 'fa-pinterest-square',
    color: colors.pinterest.primary,
  },
  Reddit: {
    label: 'reddit',
    icon: 'fa-reddit-square',
    color: colors.reddit.primary,
  },
  Snapchat: {
    label: 'snapchat',
    icon: 'fa-snapchat-square',
    color: colors.snapchat.primary,
  },
  Twitter: {
    label: 'twitter',
    icon: 'fa-twitter-square',
    color: colors.twitter.primary,
  },
  Adwords: {
    label: 'youtube',
    icon: 'fa-youtube-play',
    color: colors.youtube.primary,
  },
  Youtube: {
    label: 'youtube',
    icon: 'fa-youtube-play',
    color: colors.youtube.primary,
  },
}

export class PublisherLogosModel {
  public logos(): { [key: string]: PublisherLogosType } {
    return PublisherLogosMap
  }
}
