import api from '@/api'

export default interface ImpressionsOrderedRange {
  minImpressionsOrdered: number
  maxImpressionsOrdered: number
}

export class ImpressionsOrderedRangeModel {
  public async fetch(): Promise<ImpressionsOrderedRange> {
    const temp = await api('pmn.impressionsOrderedRange').jsonMust<any>()

    return temp
  }
}
