import api from '@/api'
import Report from '@/api/communique/report'
import types from '@/types'

export default interface CampaignSummary {
  start_date: string
  ihm_geotargeting: string
  total_target_impressions: number
  end_date: string
  timezone: string
  initiative_name: string
  company_logo?: string
}

export interface CampaignFetchParams {
  startDate?: string
  endDate?: string
  initiativeId?: string
  mediaTypes: string[]
  excludeLineItemIds?: string[]
  includeLineItemIds?: string
}

export class CampaignSummaryModel {
  public async fetch({
    mediaTypes,
    startDate,
    endDate,
    initiativeId,
    excludeLineItemIds,
    includeLineItemIds,
  }: CampaignFetchParams) {
    return api('report.dashboards.ihmCampaign.v2.initiativeSummary')
      .params({
        media_types: mediaTypes,
        initiativeId,
        start_date: startDate,
        end_date: endDate,
        exclude_line_item_ids: excludeLineItemIds,
        include_line_item_ids: includeLineItemIds,
      })
      .cache(1000 * types.config.dashboardCacheSeconds())
      .jsonMust<Report<CampaignSummary>>()
  }
}
