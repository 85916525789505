import csvify from '@/helpers/csvify'

export interface DataTableHeader {
  text: string
  value: string
  align?: 'left' | 'center' | 'right'
  sortable?: boolean
  class?: string[] | string
  width?: string
}

export interface PivotTableHeader {
  text: string
  value: string
  align?: 'left' | 'center' | 'right'
  dimension?: boolean
  sticky?: boolean
  width?: number
  colClasses?: string[] | string
}

export interface DataTablePagination {
  descending?: boolean
  page?: number
  rowsPerPage?: number
  sortBy?: string
  totalItems?: number
}

export class DataTableModel {
  public csvFromHeader<T>(headers: (DataTableHeader | PivotTableHeader)[], data: T[]): string {
    const csvHeaders = headers.map((h) => h.text)
    const csv: Array<Array<number | string>> = [csvHeaders]
    for (const row of data) {
      csv.push(headers.map((h) => (row as any)[h.value]))
    }
    return csvify(csv)
  }
}
