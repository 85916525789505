import api from '@/api'

export default interface IHMCampaignDetails {
  brand_id: string
  brand_name: string
  campaign_id: string
  company_foreign_id: string
  company_id: string
  company_logo: string
  company_name: string
  company_type: string
  dashboard_friendly_name: string
  earliest_start_date: string
  initiative_id: string
  initiative_name: string
  initiative_start_date: string
  initiative_end_date: string
  latest_end_date: string
  lineitem_end_date: string
  lineitem_foreign_id: string
  lineitem_id: string
  lineitem_media_type: string
  lineitem_name: string
  lineitem_publisher: string
  lineitem_start_date: string
  lineitem_timezone: string
}

export class IHMCampaignDetailsModel {
  public async fetch(initiativeId: string): Promise<IHMCampaignDetails[]> {
    return api('pmn.ihmCampaignSummary.summary')
      .params({
        initiativeId,
      })
      .jsonMust<IHMCampaignDetails[]>()
  }
}
