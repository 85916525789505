import _ from 'lodash'
import query from '@/types/query'
import moment from 'moment'

export interface ImpressionsStat {
  impressions: number
  excluded_impressions?: number
  excluded_clicks?: number
}

export interface BaseStat extends ImpressionsStat {
  clicks: number
}

export interface DailyStat extends BaseStat {
  stat_date: string
}

export interface GTBaseStat extends BaseStat {
  second_actions: number
  visits: number
}

export interface VideoViewStat {
  completions: number
  hours_watched: number
  video_impressions: number
  impressions: number
  pcts_25: number
  pcts_50: number
  pcts_75: number
}

export interface VideoBaseStat extends BaseStat, VideoViewStat {}

export interface VideoDailyStat extends DailyStat, VideoBaseStat {}

export interface GTDailyStat extends DailyStat, GTBaseStat {}

export interface StatTotal extends BaseStat {
  ctr: number
}

export interface VideoStatTotal extends StatTotal, VideoBaseStat {
  vcr: number
}

export interface GTStatTotal extends StatTotal, GTBaseStat {}

export class StatsModel {
  /**
   * Get the total stats for the given stats
   * @param stats The stats to get totals for
   * @param excluded boolean, to exclude OTT impressions
   */
  public totals(stats: BaseStat[], excluded: Boolean = false): StatTotal {
    const totals = query<BaseStat>(stats).agg<StatTotal>({
      impressions: _.sum,
      clicks: _.sum,
      excluded_impressions: _.sum,
      excluded_clicks: _.sum,
      ctr: 0,
    })

    if (!excluded && totals.impressions > 0) {
      totals.ctr = totals.clicks / totals.impressions
    } else if (excluded && totals.excluded_impressions && totals.excluded_impressions > 0) {
      const excluded_clicks = totals.excluded_clicks ? totals.excluded_clicks : 0
      totals.ctr = excluded_clicks / totals.excluded_impressions
    }
    return totals
  }

  /**
   * Get the total stats for the given video stats
   * @param stats The video stats to get totals for
   */
  public videoTotals(stats: VideoBaseStat[]): VideoStatTotal {
    const totals = query<VideoBaseStat>(stats).agg<VideoStatTotal>({
      impressions: _.sum,
      video_impressions: _.sum,
      clicks: _.sum,
      completions: _.sum,
      pcts_25: _.sum,
      pcts_50: _.sum,
      pcts_75: _.sum,
      ctr: 0,
      vcr: 0,
    })

    if (totals.impressions > 0) {
      totals.ctr = totals.clicks / totals.impressions
      totals.vcr = totals.completions / totals.video_impressions
    }
    return totals
  }

  /**
   * Get the total stats for the given groundtruth stats
   * @param stats The groundtruth stats to get totals for
   */
  public gtTotals(stats: GTBaseStat[]): GTStatTotal {
    const totals = query<GTBaseStat>(stats).agg<GTStatTotal>({
      impressions: _.sum,
      clicks: _.sum,
      second_actions: _.sum,
      visits: _.sum,
      ctr: 0,
    })

    if (totals.impressions > 0) {
      totals.ctr = totals.clicks / totals.impressions
    }
    return totals
  }

  /**
   * Get the total stats for the given stats
   * @param stats The stats to get totals for
   */
  public max(stats: BaseStat[]): BaseStat {
    return query<BaseStat>(stats).agg<BaseStat>({
      impressions: _.max,
      clicks: _.max,
    })
  }

  private fillDaily<T extends DailyStat>(stats: T[], emptyVal: T): T[] {
    if (!stats.length) {
      return stats
    }

    const start = moment(stats[0].stat_date)
    const end = moment(stats[stats.length - 1].stat_date)
    const filledStats: T[] = []

    let sIdx = 0

    for (let d = start; d.isSameOrBefore(end); d.add(1, 'day')) {
      const statDate = d.format('YYYY-MM-DD')
      if (sIdx < stats.length && d.isSame(stats[sIdx].stat_date)) {
        filledStats.push(stats[sIdx])
        sIdx++
      } else {
        filledStats.push({ ...emptyVal, stat_date: statDate })
      }
    }

    return filledStats
  }

  /**
   * Fills in gaps between DailyStat activity with 0 values
   * @param stats The stats to `fill`
   */
  public fill(stats: DailyStat[]): DailyStat[] {
    return this.fillDaily(stats, { stat_date: '', impressions: 0, clicks: 0 })
  }

  /**
   * Fills in gaps between GTDailyStat activity with 0 values
   * @param stats The stats to `fill`
   */
  public gtFill(stats: GTDailyStat[]): GTDailyStat[] {
    return this.fillDaily(stats, { stat_date: '', impressions: 0, clicks: 0, second_actions: 0, visits: 0 })
  }

  /**
   * Fills in gaps between VideoDailyStat activity with 0 values
   * @param stats The stats to `fill`
   */
  public videoFill(stats: VideoDailyStat[]): VideoDailyStat[] {
    return this.fillDaily(stats, {
      stat_date: '',
      impressions: 0,
      clicks: 0,
      video_impressions: 0,
      completions: 0,
      pcts_25: 0,
      pcts_50: 0,
      pcts_75: 0,
      hours_watched: 0,
    })
  }
}
