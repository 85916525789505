import { API } from '@/api'
import PMNList from '@/api/pmn/list'

/**
 * Load all of the data across all of the pages in PMN asynchronously
 * @param name The name of the sub group to grab from the payload envelope
 * @param limit The size of each page
 */
export async function loadPages<T>(api: API, name: string, limit: number): Promise<T[]> {
  // Get count to determine number of calls
  const countData = await api.params({ limit }).json<PMNList<T>>()
  let calls = Math.floor(countData.meta.count / limit)
  if (countData.meta.count % limit > 0) calls++

  const promises: Array<Promise<PMNList<T>>> = []
  for (let i = 0; i < calls; i++) {
    promises.push(
      api
        .clone()
        .params({ offset: i * limit })
        .json<PMNList<T>>()
    )
  }

  const data = await Promise.all(promises)
  let output: T[] = []
  for (const d of data) {
    output = output.concat(d.data[name])
  }
  return output
}
