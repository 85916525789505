import api from '@/api'
import PMNList from '@/api/pmn/list'

export default interface IHMCampaign {
  companyName: string
  companyId: string
  dateCreated: string
  dateModified: string
  disabled: boolean
  name: string
  id: string
  impressionsOrdered: number
  startDate: string
  endDate: string
  mediaTypes: string[]
}

export interface FetchAllIHMCampaignsParams {
  customerOrderId?: string
  activeOrder: boolean
  agencyCompany: boolean
  companyIds?: string[]
  impressionsOrderedMin?: number | null
  impressionsOrderedMax?: number | null
  startDate?: string | null
  endDate?: string | null
  mediaTypes?: string[]
  searchBy?: string | null
  order_by?: string | null
  limit?: number | null
  offset?: number | null
}

export class IhmCampaignModel {
  public async fetchAll({
    activeOrder,
    agencyCompany,
    companyIds,
    impressionsOrderedMin,
    impressionsOrderedMax,
    startDate,
    endDate,
    mediaTypes,
    searchBy,
    order_by,
    limit,
    offset,
  }: FetchAllIHMCampaignsParams): Promise<PMNList<IHMCampaign>> {
    return await api('pmn.ihmCampaigns')
      .params({
        activeOrder,
        agencyCompany,
        companyIds,
        impressionsOrderedMin,
        impressionsOrderedMax,
        startDate,
        endDate,
        mediaTypes,
        searchBy,
        order_by,
        limit,
        offset,
      })
      .jsonMust<PMNList<IHMCampaign>>()
  }
}
