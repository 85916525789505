import log from '@/log'
import colors from '@/stylus/colors.json'

export enum Publishers {
  facebook = 'facebook',
  instagram = 'instagram',
  twitter = 'twitter',
  snapchat = 'snapchat',
  pinterest = 'pinterest',
  adwords = 'adwords',
  youtube = 'youtube',
  linkedin = 'linkedin',
  xandr = 'xandr',
  groundtruth = 'groundtruth',
  reddit = 'reddit',
  tiktok = 'tiktok',
}

export enum Networks {
  facebook = 'facebook',
  twitter = 'twitter',
  snapchat = 'snapchat',
  pinterest = 'pinterest',
  adwords = 'adwords',
  linkedin = 'linkedin',
  xandr = 'xandr',
  groundtruth = 'groundtruth',
  reddit = 'reddit',
  tiktok = 'tiktok',
}

/**
 * Contains methods for working with networks
 */
export class NetworkModel {
  /**
   * Returns the font-awesome icon string for the given network
   */
  public icon(publisher: Publishers): string {
    switch (publisher) {
      case 'adwords':
      case 'youtube':
        return 'fab fa-youtube-square'
      case 'facebook':
        return 'fab fa-facebook-square'
      case 'instagram':
        return 'fab fa-instagram'
      case 'linkedin':
        return 'fab fa-linkedin'
      case 'pinterest':
        return 'fab fa-pinterest-square'
      case 'snapchat':
        return 'fab fa-snapchat-square'
      case 'twitter':
        return 'fab fa-twitter-square'
      case 'groundtruth':
        return 'fab fa-street-view'
      case 'xandr':
        return 'fab hexagon' // We'll have to do something better for xandr and groundtruth
      default:
        log.error('Unmatched publisher for icon: %s', publisher)
        return 'fas fa-question'
    }
  }

  /**
   * Returns the website for the network
   */
  public website(network: string): string {
    switch (network) {
      case 'adwords':
      case 'youtube':
        return 'google.com'
      case 'facebook':
        return 'facebook.com'
      case 'instagram':
        return 'facebook.com'
      case 'linkedin':
        return 'linkedin.com'
      case 'pinterest':
        return 'pinterest.com'
      case 'snapchat':
        return 'snapchat.com'
      case 'twitter':
        return 'twitter.com'
      default:
        log.error('Unmatched network for icon: %s', network)
        throw new Error(`Unmatched network for website: ${network}`)
    }
  }

  /**
   * Returns the network's primary color
   */
  public networkColor(network: string): string {
    const c = (colors as any)[network]
    return !c ? 'grey' : c.primary
  }
}
