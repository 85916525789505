import { checkLogin } from '@/api'
import log from '@/log'
import types from '@/types'
import { ALLOWED_ACCESS } from '@/types/company'
import Vue from 'vue'
import Router, { RawLocation, Route } from 'vue-router'

Vue.use(Router)

// This is how we define prototype types in Typescript
declare module 'vue-router/types/router' {
  interface VueRouter {
    /**
     * Prototype method to do a custom redirect
     */
    $doRedirect: (redirect: string | RawLocation, onComplete?: () => void, onAbort?: (err: Error) => void) => void
    /**
     * Handle a redirect query parameter, optionally adding a message/error
     */
    $handleRedirectQuery: () => void
    /**
     * Get the given query param
     */
    $queryParam: (name: string, join?: string) => string
    /**
     * Set the given query param
     */
    $setQueryParam: (name: string, value: string) => void
  }
}

/**
 * Do a redirect to the given redirect.  If the redirect is a string
 * and contains a '/', it assumes its a path, else it assumes its a route name
 */
Router.prototype.$doRedirect = (
  redirect: string | RawLocation,
  onComplete?: () => void,
  onAbort?: (err: Error) => void
) => {
  if (!redirect) {
    log.error('No redirect given to $doRedirect! Redirecting to home')
    redirect = 'home'
  }

  if (typeof redirect === 'string') {
    if (redirect?.includes('/')) {
      redirect = { path: redirect }
    } else {
      redirect = { name: redirect }
    }
  }

  router.push(redirect, onComplete, onAbort)
}

/**
 * Look for a query param called "redirect" and redirect to it
 */
Router.prototype.$handleRedirectQuery = () => {
  const redirect = Router.prototype.$queryParam('redirect')
  if (!redirect) return

  Router.prototype.$doRedirect(redirect)
}

/**
 * Get a query parameter as a string. If it's a list and there is a join param,
 * join it and return it. Else just take the first item in the list. If it doesn't
 * exist, it returns empty string
 */
Router.prototype.$queryParam = (name: string, join: string = ''): string => {
  const query = router.currentRoute.query
  const val = query[name]
  if (!val) return ''
  if (typeof val === 'string') {
    return decodeURIComponent(val)
  }

  if (!val.length) return ''
  if (val.length === 1) return decodeURIComponent(val[0] ?? '')
  return decodeURIComponent(val.join(join))
}

/**
 * Set a query parameter on the current URL, and do not refresh the page
 */
Router.prototype.$setQueryParam = (name: string, value: string) => {
  const query = Object.assign({}, router.currentRoute.query)
  query[name] = encodeURIComponent(value)
  window.history.pushState(
    {},
    '',
    router.resolve({
      name: router.currentRoute.name || '',
      query,
    }).href
  )
  // Can't get the router pushstate to work for some reason, so I have to
  // resolve this myself and use window.history.pushState directly
  // router.push(
  //   {
  //     name: router.currentRoute.name,
  //     query
  //   },
  //   (s: any) => {
  //     log.debug('Pushed new state: ', s)
  //     return true
  //   }
  // )
}

const router = new Router({
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
  routes: [
    {
      name: 'home',
      path: '/',
      redirect: { name: 'ihmCampaignSelection' },
    },
    {
      beforeEnter: (to: Route, from: Route, next: any): void => {
        if (types.company.isIHMBranded() || types.company.isUnifiedUser()) {
          ensureAuthed(to, from, next)
        } else {
          router.$doRedirect({ name: 'notFound' })
        }
      },
      component: lazyLoadView('dashboards/Campaign'),
      name: 'ihmCampaignReports',
      path: '/dashboards/campaign-reports',
      alias: '/dashboards/ihm-campaign-reports',
    },

    {
      beforeEnter: (to: Route, from: Route, next: any): void => {
        if (types.company.isIHMBranded() || types.company.isUnifiedUser()) {
          ensureAuthed(to, from, next)
        } else {
          router.$doRedirect({ name: 'notFound' })
        }
      },
      component: lazyLoadView('dashboards/CampaignRedirect'),
      name: 'ihmCampaignReportsRedirect',
      path: '/dashboards/redirect/ihm-campaign-reports',
    },
    {
      beforeEnter: (to: Route, from: Route, next: any): void => {
        if (
          (types.company.hasDarklyFlag() && (types.company.isIHMBranded() || types.company.isUnifiedUser())) ||
          ((types.company.isIHMBranded() || types.company.isUnifiedUser()) && types.company.isCompanyDashboardUser())
        ) {
          ensureAuthed(to, from, next)
        } else {
          router.$doRedirect({ name: 'notFound' })
        }
      },
      component: lazyLoadView('dashboards/Company'),
      name: 'ihmCompanyReports',
      path: '/dashboards/company-reports',
      alias: '/dashboards/ihm-company-reports',
    },

    /**
     * Integration Dashboards
     */
    {
      beforeEnter: (to: Route, from: Route, next: any): void => {
        if (types.company.isIHMBranded() || types.company.isUnifiedUser()) {
          ensureAuthed(to, from, next)
        } else {
          router.$doRedirect({ name: 'notFound' })
        }
      },
      component: lazyLoadView('dashboards/IntegrationCampaign'),
      name: 'integrationIHMCampaignReports',
      path: '/dashboards/integration/ihm-campaign-reports',
    },
    /**
     * Integration Dashboards
     */
    {
      beforeEnter: (to: Route, from: Route, next: any): void => {
        if (types.company.isIHMBranded() || types.company.isUnifiedUser()) {
          ensureAuthed(to, from, next)
        } else {
          router.$doRedirect({ name: 'notFound' })
        }
      },
      component: lazyLoadView('dashboards/CustomerOrder'),
      name: 'integrationCustomerOrderReports',
      path: '/dashboards/integration/customer-order-reports',
    },
    {
      beforeEnter: (to: Route, from: Route, next: any): void => {
        if (
          (types.company.hasDarklyFlag() && (types.company.isIHMBranded() || types.company.isUnifiedUser())) ||
          ((types.company.isIHMBranded() || types.company.isUnifiedUser()) && types.company.isCompanyDashboardUser())
        ) {
          ensureAuthed(to, from, next)
        } else {
          router.$doRedirect({ name: 'notFound' })
        }
      },
      component: lazyLoadView('dashboards/IntegrationCompany'),
      name: 'integrationIHMCompanyReports',
      path: '/dashboards/integration/ihm-company-reports',
    },
    {
      beforeEnter: (to: Route, from: Route, next: any): void => {
        if (types.company.isIHMBranded() || types.company.isUnifiedUser()) {
          ensureAuthed(to, from, next)
        } else {
          router.$doRedirect({ name: 'notFound' })
        }
      },
      component: lazyLoadView('dashboards/IntegrationIHMCampaignSelection'),
      name: 'integrationIHMCampaignSelection',
      path: '/dashboards/integration/ihm-campaign-selection',
    },

    /**
     * Bundle Dashboard
     */

    {
      beforeEnter: (to: Route, from: Route, next: any): void => {
        if (types.company.isIHMBranded() || types.company.isUnifiedUser()) {
          ensureAuthed(to, from, next)
        } else {
          router.$doRedirect({ name: 'notFound' })
        }
      },
      component: lazyLoadView('dashboards/Bundle'),
      name: 'bundleDashboard',
      path: '/dashboards/bundle/:id',
      alias: '/dashboards/ihm-bundle/:id',
    },

    /**
     * ArtsAi creative
     */
    {
      beforeEnter: (to: Route, from: Route, next: any): void => {
        if (types.company.isIHMBranded() || types.company.isUnifiedUser()) {
          ensureAuthed(to, from, next)
        } else {
          router.$doRedirect({ name: 'notFound' })
        }
      },
      component: lazyLoadView('artsaiCreative'),
      name: 'artsaiCreative',
      path: '/dashboards/artsai/preview',
    },

    {
      beforeEnter: ensureAuthed,
      component: lazyLoadView('dashboards/SisenseDashboard'),
      name: 'sisenseDashboard',
      path: '/dashboards/custom/:id',
    },
    {
      beforeEnter: (to: Route, from: Route, next: any): void => {
        if (types.company.hasAccess(ALLOWED_ACCESS.TAB_EXPLORER)) {
          ensureAuthed(to, from, next)
        } else {
          router.$doRedirect({ name: 'notFound' })
        }
      },
      component: lazyLoadView('dashboards/ExplorerDashboard'),
      name: 'explorerDashboard',
      path: '/dashboards/explorer',
      alias: '/dashboards/explorer/explorer/*',
    },
    {
      beforeEnter: (to: Route, from: Route, next: any): void => {
        if (types.company.hasAccess(ALLOWED_ACCESS.TAB_PACING)) {
          ensureAuthed(to, from, next)
        } else {
          router.$doRedirect({ name: 'notFound' })
        }
      },
      component: lazyLoadView('dashboards/PacingDashboard'),
      name: 'pacingDashboard',
      path: '/dashboards/pacing',
      alias: '/dashboards/pacing/pacing/*',
    },
    {
      beforeEnter: (to: Route, from: Route, next: any): void => {
        if (types.company.hasAccess(ALLOWED_ACCESS.TAB_IHM_REPORTS)) {
          ensureAuthed(to, from, next)
        } else {
          router.$doRedirect({ name: 'notFound' })
        }
      },
      component: lazyLoadView('dashboards/IHMReport'),
      name: 'ihmReport',
      path: '/dashboards/ihm-report',
      alias: '/dashboards/ihm-report/ihm-template/*',
    },
    {
      beforeEnter: (to: Route, from: Route, next: any): void => {
        ensureAuthed(to, from, next)
      },
      component: lazyLoadView('dashboards/ViolationDashboard'),
      name: 'violationDashboard',
      path: '/dashboards/violation',
      alias: '/dashboards/violation/violation/*',
    },
    {
      beforeEnter: (to: Route, from: Route, next: any): void => {
        if (types.company.isIHMBranded() || types.company.isUnifiedUser()) {
          ensureAuthed(to, from, next)
        } else {
          router.$doRedirect({ name: 'notFound' })
        }
      },
      component: lazyLoadView('dashboards/IHMCampaignSelection'),
      name: 'ihmCampaignSelection',
      path: '/dashboards/campaign-selection',
      alias: '/dashboards/ihm-campaign-selection',
    },
    {
      beforeEnter: (to: Route, from: Route, next: any): void => {
        if (types.company.isIHMBranded() || types.company.isUnifiedUser()) {
          ensureAuthed(to, from, next)
        } else {
          router.$doRedirect({ name: 'notFound' })
        }
      },
      component: lazyLoadView('dashboards/SelectionNew'),
      name: 'selectionNew',
      path: '/dashboards-migrating/campaign-selection',
      alias: '/dashboards-migrating/ihm-campaign-selection',
    },
    {
      beforeEnter: (to: Route, from: Route, next: any): void => {
        if (types.company.hasAccess(ALLOWED_ACCESS.TAB_PLANNER)) {
          ensureAuthed(to, from, next)
        } else {
          router.$doRedirect({ name: 'notFound' })
        }
      },
      component: lazyLoadView('dashboards/SignaturePlanner'),
      name: 'signaturePlanner',
      path: '/app/planner',
    },
    {
      component: lazyLoadView('dashboards/SalesDashboard'),
      name: 'salesDashboard',
      path: '/dashboards/sales',
    },
    {
      component: lazyLoadView('Error'),
      name: 'error',
      path: '/error',
    },
    {
      component: lazyLoadView('Placeholder'),
      name: 'placeholder',
      path: '/placeholder',
    },
    {
      component: lazyLoadView('Adgroup'),
      name: 'adgroup',
      path: '/creative/:id',
    },
    {
      component: lazyLoadView('Creative'),
      name: 'creative',
      path: '/new/preview',
    },
    {
      component: lazyLoadView('NotFoundArchived'),
      name: 'notFoundArchived',
      path: '/not-found-archived',
    },
    {
      component: lazyLoadView('NotFound'),
      name: 'notFound',
      path: '/*',
    },
  ],
  mode: 'history',
})

// Debugging code
// router.beforeEach((to: Route, from: Route, next: any) => {
//   log.debug(`ROUTE CHANGE: to ${to.fullPath} from ${from.fullPath}`)
//   next()
// })

/**
 * A function to ensure the user is logged in before going to the route.
 * If they are not, redirect to login page
 * @returns any - void
 */
function ensureAuthed(to: Route, from: Route, next: any): void {
  checkLogin(to.fullPath)
  next() // Won't get here if login is invalid
}
/**
 * A function used to lazy load an component.
 */
function lazyLoadView(view: any) {
  return () => import(`@/views/${view}.vue`)
}

export default router
