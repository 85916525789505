import api from '@/api'
import Report, { UnsupportedMediaTypeReport } from '@/api/communique/report'
import types from '@/types'

export default interface GeoStoreVisitStats {
  address: string
  state: string
  store_visits: number
  ott_visits: number
  lat: number
  long: number
  city: string
  country: string
}

export interface queryParams {
  companyId: string
  mediaTypes?: string[]
  startDate?: string
  endDate?: string
  excludedInitiativeIDs?: string[]
  includedInitiativeIDs?: string[]
}

export class GeoStoreVisitStatsModel {
  public async fetchAll({
    companyId,
    mediaTypes,
    startDate,
    endDate,
    excludedInitiativeIDs,
    includedInitiativeIDs,
  }: queryParams): Promise<Report<GeoStoreVisitStats> | UnsupportedMediaTypeReport> {
    return api('report.dashboards.ihmCompany.geoStoreVisitStats')
      .params({
        companyId: companyId,
        media_types: mediaTypes,
        start_date: startDate,
        end_date: endDate,
        exclude_initiative_ids: excludedInitiativeIDs,
        include_initiative_ids: includedInitiativeIDs,
      })
      .cache(1000 * types.config.dashboardCacheSeconds())
      .jsonMust<Report<GeoStoreVisitStats>>()
  }
}
