import api from '@/api'
import log from '@/log'

interface MimeExtensionMapping {
  [name: string]: string[]
}

// The MIME type mappings for all supported types
// In the future we could use webpack to build this with the npm package mime-types
export const imageTypes: MimeExtensionMapping = {
  'image/apng': ['.apng'],
  'image/bmp': ['.bmp'],
  'image/gif': ['.gif'],
  'image/x-icon': ['.ico', '.cur'],
  'image/jpeg': ['.jpg', '.jpeg', '.jfif', '.pjpeg', '.pjp', 'format=jpg&name=orig'],
  'image/png': ['.png', 'format=png&name=orig'],
  'image/svg+xml': ['.svg'],
  'image/tiff': ['.tif', '.tiff'],
  'image/webp': ['.webp'],
}
imageTypes['image/vnd.microsoft.icon'] = imageTypes['image/x-icon']

export const videoTypes: MimeExtensionMapping = {
  'video/mp4': ['.mp4', '.m4a', '.m4p', '.m4b', '.m4r', '.m4v'],
  'video/x-flv': ['.flv', '.f4v', '.f4p', '.f4a', '.f4b'],
  'video/3gpp': ['.3gp', '.3gpp'],
  'video/ogg': ['.ogg', '.ogv', '.oga', '.ogx', '.ogm', '.spx', '.opus'],
  'video/webm': ['.webm'],
}

export const audioTypes: MimeExtensionMapping = {
  'audio/wav': ['.wav', '.wave'],
  'audio/mpeg': ['.mp3'],
  'audio/mp4': ['.mp4', '.m4a', '.m4p', '.m4b', '.m4r', '.m4v'],
  'audio/aac': ['.aac', '.3gp', '.3gpp'],
  'audio/ogg': ['.ogg', '.ogv', '.oga', '.ogx', '.ogm', '.spx', '.opus'],
  'audio/webm': ['.webm'],
  'audio/flac': ['.flac'],
}
audioTypes['audio/vnd.wave'] = audioTypes['audio/wav']
audioTypes['audio/wave'] = audioTypes['audio/wav']
audioTypes['audio/x-wav'] = audioTypes['audio/wav']
audioTypes['audio/MPA'] = audioTypes['audio/mpeg']
audioTypes['audio/mpa-robust'] = audioTypes['audio/mpeg']
audioTypes['audio/mp4a-latm'] = audioTypes['audio/mp4']
audioTypes['audio/mpeg4-generic'] = audioTypes['audio/mp4']
audioTypes['audio/aacp'] = audioTypes['audio/aac']
audioTypes['audio/3gpp'] = audioTypes['audio/aac']
audioTypes['audio/3gpp2'] = audioTypes['audio/aac']

export class MimeTypeModel {
  /**
   * Given a link, get the mime type of the content at the link.
   * First, by checking to match known extensions.
   * Second, by querying the content type of the resource using fetch
   * Third, by matching a set of known domains
   * @param link The link to get the mime type for
   */
  public async mimeTypeForLink(link: string, skipFetch: boolean): Promise<string | undefined> {
    // Since getting content type by extension is simple
    // and most likely going to be right every time,
    // default to that
    let contentType = this.getTypeFromExtension(link)

    if (!contentType && !skipFetch) {
      // Else, try to get the Content-Type by fetching the HEAD of the file
      contentType = await this.fetchContentType(link)
    }

    if (!contentType) {
      // Else, see if it's a known type of link. Special cases.
      contentType = this.getTypeFromLink(link)
    }

    if (!contentType) {
      log.error(`No content type found for ${link}`)
    }

    return contentType
  }

  public getTypeFromExtension(link: string) {
    if (link.endsWith('.html')) {
      return 'text/html'
    }
    let found = this.matchLinkToType(link, imageTypes)
    if (found) return found
    found = this.matchLinkToType(link, videoTypes)
    if (found) return found
    found = this.matchLinkToType(link, audioTypes)
    if (found) return found
  }

  public matchLinkToType(link: string, types: MimeExtensionMapping): string | undefined {
    for (const contentType of Object.keys(types)) {
      for (const type of types[contentType]) {
        if (link.endsWith(type)) return contentType
      }
    }
  }

  public getTypeFromLink(url: string): string | undefined {
    if (url.toLowerCase().includes('creative-preview-an.com')) {
      return 'text/html'
    }

    // Case for Facebook Creative Preview
    if (url.toLowerCase().includes('preview_iframe')) {
      return 'text/html'
    }

    // Case for LinkedIn Creative Preview
    if (url.toLowerCase().includes('urn:li')) {
      return 'text/html'
    }

    if (url.toLowerCase().includes('video_mp4')) {
      return 'video/mp4'
    }
  }

  public async fetchContentType(url: string): Promise<string | undefined> {
    const req = new Request(url, {
      method: 'HEAD',
      cache: 'no-cache',
      redirect: 'follow',
      referrer: 'no-referrer',
    })

    let resp
    try {
      resp = await api.call(req, 'mime_types.fetchContentType', 1000 * 60 * 60 * 24, true)
    } catch (e) {
      return // ignore any errors as not matching
    }

    if (!resp.ok) {
      return // ignore any errors as not matching
    }

    const conType = resp.headers.get('Content-Type')
    if (!conType) return undefined

    return conType.split(';')[0].trim()
  }
}
