import api from '@/api'
import Report from '@/api/communique/report'
import types from '@/types'

export default interface GeoStoreVisitFlags {
  lineitem_id: string
  track_store_visits: boolean
}

export class CampaignGeoStoreVisitFlagsModel {
  public async fetchAll(initiativeId: string): Promise<Report<GeoStoreVisitFlags>> {
    return api('report.dashboards.ihmCampaign.v2.campaignGeoStoreVisitFlags')
      .params({
        initiativeId: initiativeId,
      })
      .cache(1000 * types.config.dashboardCacheSeconds())
      .jsonMust<Report<GeoStoreVisitFlags>>()
  }
}
