import { DataOptions } from 'vuetify'
import { RootState } from '../index'
import { Module } from 'vuex'

interface SelectionState {
  options: DataOptions
}

const store: Module<SelectionState, RootState> = {
  namespaced: true,
  state() {
    return {
      options: {
        page: 1,
        itemsPerPage: 10,
        sortBy: [],
        sortDesc: [],
        groupBy: [],
        multiSort: true,
        mustSort: false,
        groupDesc: [],
      },
    }
  },
  mutations: {
    updateOptions(state, newOptions: DataOptions) {
      state.options = {
        ...state.options,
        ...newOptions,
      }
    },
  },
}

export default store
