import api from '@/api'
import Report from '@/api/communique/report'
import types from '@/types'

export interface CampaignOTTMarketPlaceStats {
  impressions: number
  marketplace: string
  publisher: string | null
}

export interface queryParams {
  startDate?: string
  endDate?: string
  initiativeId?: string
  mediaTypes: string[]
  excludeLineItemIds?: string[]
  includeLineItemIds?: string
}

export class CampaignOTTMarketPlaceStatsModel {
  public async fetchAll({
    initiativeId,
    mediaTypes,
    endDate,
    excludeLineItemIds,
    includeLineItemIds,
    startDate,
  }: queryParams): Promise<Report<CampaignOTTMarketPlaceStats>> {
    return api('report.dashboards.ihmCampaign.v2.ott.marketplace')
      .params({
        initiativeId,
        media_types: mediaTypes,
        start_date: startDate,
        end_date: endDate,
        exclude_line_item_ids: excludeLineItemIds,
        include_line_item_ids: includeLineItemIds,
      })
      .cache(1000 * types.config.dashboardCacheSeconds())
      .jsonMust<Report<CampaignOTTMarketPlaceStats>>()
  }
}
