/**
 *
 * @param str
 * @param join
 *
 * Realted to remove highchart margins in tooltips
 */
export const stripMargin = function (str: string, join?: boolean) {
  return str.replace(/(\n|\r|\r\n)\s*\|/g, join ? '' : '$1')
}

export default stripMargin
