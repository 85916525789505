import api from '@/api'
import types from '@/types'

export default interface singleCompanyUser {
  has_more_than_one_company: boolean
}

export class singleCompanyUserModel {
  public async fetch(): Promise<singleCompanyUser> {
    const temp = await api('pmn.singleCompanyUser')
      .cache(1000 * types.config.dashboardCacheSeconds())
      .jsonMust<singleCompanyUser>()

    return temp
  }
}
