import api from '@/api'
import types from '@/types'
import Report, { UnsupportedMediaTypeReport } from '@/api/communique/report'

export interface BroadcastDailyDelivery {
  // the number of impressions for a specific device
  cumulative_impressions: number

  cumulative_order: number

  stat_date: string
}

export interface FetchBroadcastDeliveryParams {
  startDate?: string
  endDate?: string
  initiativeId?: string
  mediaTypes: string[]
  excludeLineItemIds?: string[]
  includeLineItemIds?: string
  station: String[] | null
}

export class CampaignDailyBroadcastDelivery {
  public async fetchAll({
    initiativeId,
    mediaTypes,
    endDate,
    excludeLineItemIds,
    includeLineItemIds,
    startDate,
    station,
  }: FetchBroadcastDeliveryParams): Promise<Report<BroadcastDailyDelivery> | UnsupportedMediaTypeReport> {
    return api('report.dashboards.ihmCampaign.v2.campaignDailyBroadcastDelivery')
      .params({
        initiativeId,
        media_types: mediaTypes,
        end_date: endDate,
        start_date: startDate,
        exclude_line_item_ids: excludeLineItemIds,
        include_line_item_ids: includeLineItemIds,
        station: station,
      })
      .cache(1000 * types.config.dashboardCacheSeconds())
      .jsonMust<Report<BroadcastDailyDelivery>>()
  }
}
