import stripMargin from '@/helpers/stripMargin'
import _ from 'lodash'
import hash from 'object-hash'

type procedure<T> = (data: T) => T
type filter<T> = (data: T, idx?: number, array?: T[]) => any
type transformer<T, R> = (data: T) => R
type aggregatorFunc<T = any> = (all: T[]) => T
type columnAggregator = { [key: string]: aggregatorFunc | any }
type aggregator = aggregatorFunc | columnAggregator

function isAggregatorFunc(obj: any): obj is aggregatorFunc {
  return typeof obj === 'function'
}

export class Query<T> {
  constructor(private data: T[], private procedures: procedure<T[]>[] = []) {}

  /**
   * Run all of the procedures in the built query and return
   * the resulting dataset
   */
  go(): T[] {
    let output = this.data
    for (const proc of this.procedures) {
      output = proc(output)
    }

    return output
  }

  /**
   * Run all of the procedures in the current query and cache the result
   * in a new query object
   */
  cache(): Query<T> {
    return new Query<T>(this.go())
  }

  /**
   * Run all of the procedures in the current query, and run the result
   * through a transformer function to generate a new dataset
   * @param t The transformer which transforms the data to our output format
   */
  transform<R>(t: transformer<T, R>): R[] {
    return this.go().map((row) => t(row))
  }

  /**
   * Chunk up the list of data into sub-lists based on a key returned
   * from the predicate function.  The Predicate itself can be a string
   * which will just return the property at that string.
   * NOTE: The data should be sorted by the chunk key,
   * or it will have multiple chunks!
   */
  chunk(p: ((value: T, index: number, array: T[]) => any) | string | string[]): T[][] {
    let pFunc: (value: T, index: number, array: T[]) => any
    if (typeof p === 'string') {
      pFunc = (v: any) => v[p]
    } else if (Array.isArray(p)) {
      pFunc = (v: any) => _.pick(v, p as string[])
    } else {
      pFunc = p
    }

    const chunks: T[][] = []
    let prevKey: any = null
    let chunkValues: T[] = []
    this.go().forEach((value: T, index: number, list: T[]) => {
      const key = pFunc(value, index, list)
      if (index === 0 || _.isEqual(key, prevKey)) {
        prevKey = key
        chunkValues.push(value)
        return
      }

      if (chunkValues.length) {
        chunks.push(chunkValues)
      }
      prevKey = key
      chunkValues = [value]
    })

    if (chunkValues.length) {
      chunks.push(chunkValues)
    }
    return chunks
  }

  /**
   * Run the given aggregations on the given columns, and return the output
   * @param agg An object containing column aggregations to run
   */
  agg<R>(agg: columnAggregator): R {
    const data = this.go()
    const output: any = {}

    for (const col of Object.keys(agg)) {
      const colValues = data.map((row) => (row as any)[col])
      const a = agg[col]
      output[col] = isAggregatorFunc(a) ? a(colValues) : a
    }

    return output
  }

  /**
   * Joins two datasets.  If a join matches, the right side row of the join
   * is added as a key to the output row.  If no match is found, the key will
   * be null (so it functions as a left join). Throws an error if joinOutputKey
   * already exists on the left side of the join.
   * @param joinData The data to join against
   * @param on Either a column name or a function returning true if they joined
   * @param onRight A column name to join on right, if empty uses same name from on
   * @param joinOutputKey The key that the joined row should be stored under
   */
  join<R, O = any>(
    joinData: R[],
    on: string | ((a: T, b: R) => boolean),
    onRight?: string,
    joinOutputKey: string = 'join'
  ): O[] {
    const joinFunc =
      typeof on !== 'string'
        ? on
        : (a: T, b: R) => {
            return (a as any)[on] === (b as any)[onRight || on]
          }

    const output: O[] = []
    const addRow = (row: T, joinRow: R | null) => {
      const outputRow: any = _.cloneDeep(row)
      if (outputRow[joinOutputKey]) {
        throw new Error(
          stripMargin(
            `| Output join key ${joinOutputKey}
           | already exists on
           | ${JSON.stringify(outputRow, null, 2)}`,
            true
          )
        )
      }
      outputRow[joinOutputKey] = joinRow
      output.push(outputRow)
    }

    for (const row of this.data) {
      let found = false
      for (const joinRow of joinData) {
        if (joinFunc(row, joinRow)) {
          found = true
          addRow(row, joinRow)
        }
      }
      if (!found) {
        addRow(row, null)
      }
    }

    return output
  }

  /**
   * Group the data by the given columns, using the given aggregation
   * function for each column.  If no aggregation is specified for a specific
   * column, default to taking the first columns value
   * @param columns The columns to group by.
   * @param agg The aggregation function to use on the rest of the columns
   */
  group(columns: string | string[], agg: aggregator = _.head, defaultAgg: aggregatorFunc = _.head): Query<T> {
    if (!Array.isArray(columns)) columns = [columns]
    this.procedures.push((data: T[]): T[] => {
      const output: { [key: string]: any } = {}
      const list: { [key: string]: T[] } = {}
      const sort: string[] = []

      for (const row of data) {
        const outRow = _.pick(row, columns)
        const sha = hash(outRow)
        if (!output[sha]) {
          output[sha] = outRow
          sort.push(sha)
          list[sha] = []
        }
        list[sha].push(row)
      }

      return sort.map((sha) => {
        const outRow = output[sha]
        const rows = list[sha]
        // @ts-ignore, Typescript is complaining about rows[0] not being a object type
        const valCols = Object.keys(rows[0]).filter((col) => !columns.includes(col))

        for (const col of valCols) {
          const a = isAggregatorFunc(agg) ? agg : agg[col] || defaultAgg

          const values = rows.map((row) => (row as any)[col])
          outRow[col] = isAggregatorFunc(a) ? a(values) : a
        }

        return outRow
      })
    })
    return this
  }

  /**
   * Sort the dataset by the given columns
   * @param columns The columns to sort by. Prefix column name with '-' to make descending
   */
  sort(...columns: string[]): Query<T> {
    this.procedures.push((data: T[]): T[] => {
      return [...data].sort((a, b) => {
        for (const col of columns) {
          const desc = col.startsWith('-')
          const column = desc ? col.slice(1) : col

          const aVal = (a as any)[column]
          const bVal = (b as any)[column]

          if (aVal > bVal) {
            return desc ? -1 : 1
          } else if (aVal < bVal) {
            return desc ? 1 : -1
          }
        }
        return 0
      })
    })
    return this
  }

  /**
   * Filter the dataset based on the predicate
   * @param f The filter predicate for the function
   */
  filter(f: filter<T>): Query<T> {
    this.procedures.push((data: T[]): T[] => {
      return data.filter(f)
    })
    return this
  }
}

export default function query<T>(d: T[]): Query<T> {
  return new Query<T>(d)
}
