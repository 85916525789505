import { BrandModel } from '@/types/brand'
import { CompanyModel } from '@/types/company'
import { ConfigModel } from '@/types/config'

import { DashboardModel } from '@/types/dashboard'
import { DataTableModel } from '@/types/dataTable'

import { MapModel } from '@/types/map'
import { MimeTypeModel } from '@/types/mime_types'
import { NetworkModel } from '@/types/network'
import { NumberModel } from '@/types/number'
import { RenderModel } from '@/types/render'
import { SisenseModel } from '@/types/sisense'
import { StatsModel } from '@/types/stats'
import { StylesModel } from '@/types/styles'
import { ThemeModel } from '@/types/theme'
import { UserModel } from '@/types/user'
import { WidgetModel } from '@/types/widget'
import { SectionLogosModel } from '@/types/sectionLogos'
import { PublisherLogosModel } from '@/types/publisherLogos'
import { IhmCampaignModel } from '@/types/dashboards/ihm_campaign_selection/ihmCampaigns'
import { ImpressionsOrderedRangeModel } from './dashboards/ihm_campaign_selection/impressionsOrderRange'
import { singleCompanyUserModel } from './dashboards/ihm_campaign_selection/singleCompanyUser'
import { CompanyDetailsModel } from './dashboards/ihm_companies/companyDetails'
import { CampaignDetailsModel } from './dashboards/new_ihm_campaigns/campaignDetails'
import { CompanyDailyStatsModel } from './dashboards/ihm_companies/companyDailyStats'
import { ImpressionsByPublisherModel } from './dashboards/ihm_companies/impressionsByPublisher'
import { CompanySummaryModel } from './dashboards/ihm_companies/companySummary'
import { CompanyDailyMediaTypeImpStatsModel } from './dashboards/ihm_companies/companyDailyMediaTypeImpStats'
import { CompanyDailyMediaTypeClickStatsModel } from './dashboards/ihm_companies/companyDailyMediaTypeClickStats'
import { ImpressionsByDeviceModel } from './dashboards/ihm_companies/impressionsByDevice'
import { ImpressionsByDeviceModelModel } from './dashboards/ihm_companies/impressionsByDeviceModel'
import { CompanyTotalStatsModel } from './dashboards/ihm_companies/companyTotalStats'
import { CompanyMediaTypeStatsModel } from './dashboards/ihm_companies/companyMediaTypeStats'
import { TacticPerformanceModel } from './dashboards/ihm_companies/tacticPerformance'
import { GeoDmaStatsModel } from './dashboards/ihm_companies/geoDmaStats'
import { GeoCityStatsModel } from './dashboards/ihm_companies/geoCityStats'
import { GeoStateStatsModel } from './dashboards/ihm_companies/geoStateStats'
import { CompanyVideoViewModel } from './dashboards/ihm_companies/companyVideoViewStats'
import { CompanyCreativeModel } from './dashboards/ihm_companies/companyCreativeStats'
import { LandingPagePerformanceStatsModel } from './dashboards/ihm_companies/landingPagePerformanceStats'
import { GeoStoreVisitStatsModel } from './dashboards/ihm_companies/geoStoreVisitStats'
import { GeoStoreVisitFlagsModel } from './dashboards/ihm_companies/geoStoreVisitFlags'
import { OTTImpressionsByDayStatsModel } from './dashboards/ihm_companies/OTTImpressionsByDayStats'
import { OTTImpressionsByDayPartStatsModel } from './dashboards/ihm_companies/OTTImpressionsByDayPartStats'
import { CompanyOTTMarketPlaceStatsModel } from './dashboards/ihm_companies/companyOTTMarketPlaceStats'
import { OTTHoursWatchedStatsModel } from './dashboards/ihm_companies/OTTHourWatchedStats'
import { CampaignSummaryModel } from './dashboards/new_ihm_campaigns/campaignSummary'
import { TotalHeroMetricsModel } from './dashboards/new_ihm_campaigns/totalHeroMetrics'
import { CampaignReachAndFrequencyModel } from './dashboards/new_ihm_campaigns/campaignReachAndFrequency'
import { CampaignImpressionsByPublisherModel } from './dashboards/new_ihm_campaigns/campaignImpressionByPublishers'
import { IHMCampaignDetailsModel } from './dashboards/new_ihm_campaigns/ihmCampaignDetails'
import { CampaignImpressionsByDeviceModel } from './dashboards/new_ihm_campaigns/campaignImpressionsByDevice'
import { CampaignImpressionsByDeviceModelModel } from './dashboards/new_ihm_campaigns/campaignImpressionsByDeviceModel'
import { CampaignOTTHoursWatchedStatsModel } from './dashboards/new_ihm_campaigns/campaignOTTHourWatchedStats'
import { CampaignDailyStatsModel } from './dashboards/new_ihm_campaigns/campaignDailyStats'
import { CampaignDailyImpsClicksModel } from './dashboards/new_ihm_campaigns/campaignDailyImpsClicks'
import { CampaignMediaTypeStatsModel } from './dashboards/new_ihm_campaigns/campaignImpressionsByMediaType'
import { CampaignOTTImpressionsByDayStatsModel } from './dashboards/new_ihm_campaigns/campaignOTTImpressionsByDayStats'
import { CampaignOTTImpressionsByDayPartStatsModel } from './dashboards/new_ihm_campaigns/campaignOTTImpressionsByDayPartStats'
import { CampaignOTTMarketPlaceStatsModel } from './dashboards/new_ihm_campaigns/campaignOTTMarketPlaceStats'
import { CampaignTacticPerformanceModel } from './dashboards/new_ihm_campaigns/campaignTacticPerformance'
import { CampaignGeoStoreVisitFlagsModel } from './dashboards/new_ihm_campaigns/campaignGeoStoreVisitFlags'
import { CampaignGeoStoreVisitStatsModel } from './dashboards/new_ihm_campaigns/campaignGeoStoreVisitStats'
import { CampaignGeoCityStatsModel } from './dashboards/new_ihm_campaigns/campaignGeoCityStats'
import { CampaignGeoDmaStatsModel } from './dashboards/new_ihm_campaigns/campaignGeoDmaStats'
import { CampaignGeoStateStatsModel } from './dashboards/new_ihm_campaigns/campaignGeoStateStats'
import { CampaignLandingPagePerformanceStatsModel } from './dashboards/new_ihm_campaigns/campaignLandingPagePerformanceStats'
import { TotalCampaignSocialMetricsModel } from './dashboards/new_ihm_campaigns/campaignTotalSocialEngagements'
import { CampaignDrillDownMetricsModel } from './dashboards/new_ihm_campaigns/campaignDrillDownMetrics'
import { CampaignVideoViewModel } from './dashboards/new_ihm_campaigns/campaignVideoViewStats'
import { CampaignCreativeModel } from './dashboards/new_ihm_campaigns/campaignCreativeStats'
import { CampaignDemographicsAgeModel } from './dashboards/new_ihm_campaigns/campaignAgeStats'
import { CampaignDemographicsGenderModel } from './dashboards/new_ihm_campaigns/campaignGenderStats'
import { CampaignAudioAdCompletionStatsModel } from './dashboards/new_ihm_campaigns/campaignAudioAdCompletion'
import { CompanyAudioAdCompletionStatsModel } from './dashboards/ihm_companies/companyAudioAdCompletion'
import { CompanyTopNetworkStatsModel } from './dashboards/ihm_companies/companyTopNetworkStats'
import { CampaignTopNetworkStatsModel } from './dashboards/new_ihm_campaigns/campaignTopNetworkStats'
import { CampaignDailyReachAndFrequencyModel } from './dashboards/new_ihm_campaigns/campaignDailyReachAndFrequency'
import { CampaignBroadcastDelivery } from './dashboards/new_ihm_campaigns/campaignBroadcastDelivery'
import { CampaignDailyBroadcastDelivery } from './dashboards/new_ihm_campaigns/campaignBroadcastDailyDelivery'
import { BroadcastImpressionGoals } from './dashboards/new_ihm_campaigns/broadcastImpressionGoals'
import { CompanyBroadcastDailyDelivery } from './dashboards/ihm_companies/companyBroadcastDailyDelivery'
import { CompanyBroadcastDelivery } from './dashboards/ihm_companies/companyBroadcastDelivery'
import { CompanyTopStationsStatsModel } from './dashboards/ihm_companies/companyTopStations'
import { CampaignTopStationsStatsModel } from './dashboards/new_ihm_campaigns/campaignTopStations'

export interface ModelTypes {
  /**
   * The brand model
   */
  brand: BrandModel
  /**
   * The impressions by publisher for campaign
   */
  campaignImpressionsByPublisher: CampaignImpressionsByPublisherModel
  /**
   * The tactic for campaign tactic performance
   */
  campaignTacticPerformance: CampaignTacticPerformanceModel

  /**
   * The company model
   */
  company: CompanyModel
  /**
   * The company video view model
   */
  companyVideoView: CompanyVideoViewModel
  /**
   * The company creative model
   */
  companyCreative: CompanyCreativeModel
  /**
   * The config model
   */
  config: ConfigModel

  /**
   * The dashboard model
   */
  dashboard: DashboardModel
  /**
   * The dataTable model
   */
  dataTable: DataTableModel

  /**
   * The ihm campaign details model
   */
  ihmCampaignDetails: IHMCampaignDetailsModel

  /**
   * The ihm campaign model
   */
  ihmCampaign: IhmCampaignModel
  /**
   *  The impression ordered range model
   */
  impressionsOrderedRange: ImpressionsOrderedRangeModel
  /**
   *  The single company user model
   */
  singleCompanyUser: singleCompanyUserModel
  /**
   * The map model
   */
  map: MapModel
  /**
   * The mimeTypes model
   */
  mimeTypes: MimeTypeModel
  /**
   * The network model
   */
  network: NetworkModel
  /**
   * The number model
   */
  number: NumberModel
  /**
   * Company roll up, OTT impressions by day widget
   */
  OTTImpressionsByDayStats: OTTImpressionsByDayStatsModel
  /**
   * Company roll up, OTT impressions by day part widget
   */
  OTTImpressionsByDayPartStats: OTTImpressionsByDayPartStatsModel
  /**
   * The publisher logos model
   */
  publisherLogos: PublisherLogosModel
  /**
   * The render model
   */
  render: RenderModel
  /**
   * The sisense model
   */
  sisense: SisenseModel
  /**
   * The section logo model
   */
  sectionLogo: SectionLogosModel
  /**
   * The stats model
   */
  stats: StatsModel
  /**
   * The styles model
   */
  styles: StylesModel
  /**
   * The theme model
   */
  theme: ThemeModel
  /**
   * The user model
   */
  user: UserModel
  /**
   * The widget model
   */
  widget: WidgetModel
  /**
   * The Company Details model
   */
  companyDetails: CompanyDetailsModel

  /**
   * The Daily stats model
   */
  companyDailyStats: CompanyDailyStatsModel

  /**
   * The media type stats model
   */
  companyMediaTypeStats: CompanyMediaTypeStatsModel
  /*
   * The impressions by publisher widget model
   */
  impressionsByPublisher: ImpressionsByPublisherModel
  /**
   * The Company Summary model
   */
  companySummary: CompanySummaryModel
  /**
   * The Company Daily clicks for media type
   */
  companyDailyMediaTypeClickStats: CompanyDailyMediaTypeClickStatsModel
  /**
   * The Company Daily impressions for media type
   */
  companyDailyMediaTypeImpStats: CompanyDailyMediaTypeImpStatsModel
  /**
   * The Impressions by device model
   */
  impressionsByDevice: ImpressionsByDeviceModel
  /**
   * The Impressions by device model, model
   */
  impressionsByDeviceModel: ImpressionsByDeviceModelModel
  /**
   * The Company total Stats model
   */
  companyTotalStats: CompanyTotalStatsModel
  /**
   * The Company Media type model
   */
  companyMediaType: CompanyMediaTypeStatsModel
  /**
   * The Tactic Performance Model
   */
  tacticPerformanceStats: TacticPerformanceModel
  /**
   * Company Geo Dma Stats Model
   */
  companyGeoDmaStatsModel: GeoDmaStatsModel
  /**
   * Company Geo City Stats Model
   */
  companyGeoCityStatsModel: GeoCityStatsModel
  /**
   * Company Geo State Stats Model
   */
  companyGeoStateStatsModel: GeoStateStatsModel
  /**
   * Company Landing Page performance Model
   */
  landingPagePerformanceStats: LandingPagePerformanceStatsModel
  /**
   * Company Geo Store Visits Stats Model
   */
  companyGeoStoreVisitStatsModel: GeoStoreVisitStatsModel
  /**
   * Company Geo Store Visits Flag Model
   */
  geoStoreVisitsFlagsModel: GeoStoreVisitFlagsModel
  /**
   * Company OTT Market Place Stats Model
   */
  companyOTTMarketPlaceStatsModel: CompanyOTTMarketPlaceStatsModel
  /**
   * OTT Hours Watched
   */
  OTTHoursWatchedStats: OTTHoursWatchedStatsModel
  /**
   * new campaign dashboard endpoint for company summary
   */
  CampaignSummary: CampaignSummaryModel
  /**
   * new campaign dashboard endpoint for metric data
   */
  CampaignTotalHeroMetrics: TotalHeroMetricsModel
  /**
   * new campaign dashboard reach and frequency
   */
  reachAndFrequency: CampaignReachAndFrequencyModel
  /**
   * new campaign dashboard endpoint impressions by device
   */
  campaignImpressionsByDevice: CampaignImpressionsByDeviceModel
  /**
   * new campaign dashboard endpoint impressions by device model
   */
  campaignImpressionsByDeviceModel: CampaignImpressionsByDeviceModelModel
  /**
   * new campaign dashboard endpoint OTT Hours Watched widget
   */
  campaignOTTHoursWatchedStats: CampaignOTTHoursWatchedStatsModel
  /**
   * new campaign daily stats model
   */
  campaignDailyStats: CampaignDailyStatsModel
  /**
   * Daily clicks and impressions
   */
  campaignDailyImpsClicks: CampaignDailyImpsClicksModel
  /**
   * Campaign impressions by media type
   */
  campaignMediaType: CampaignMediaTypeStatsModel
  /**
   * new campaign dashboard endpoint OTT market places
   */
  campaignOTTMarketPlaceStats: CampaignOTTMarketPlaceStatsModel
  /**
   * new campaign dashboard details
   */
  campaignDetails: CampaignDetailsModel
  /**
   * new campaign dashboard OTT day stats
   */
  campaignOTTByDayStats: CampaignOTTImpressionsByDayStatsModel
  /**
   * new campaign dashboard OTT by day part stats
   */
  campaignOTTImpressionsByDayPartStats: CampaignOTTImpressionsByDayPartStatsModel
  /**
   * new campaign geo store visits model
   */
  campaignGeoStoreVisitStats: CampaignGeoStoreVisitStatsModel
  /**
   * new campaign geo store viit flag model
   */
  campaignGeoStoreVisitFlag: CampaignGeoStoreVisitFlagsModel
  /**
   * new campaign geo state stats
   */
  campaignGeoStateStats: CampaignGeoStateStatsModel
  /**
   * new campaign geo city stats
   */
  campaignGeoCityStats: CampaignGeoCityStatsModel
  /**
   * new campaign dma stats
   */
  campaignGeoDmaStats: CampaignGeoDmaStatsModel
  /*
   * new campaign landing page performance model
   */
  campaignLandingPagePerformanceStats: CampaignLandingPagePerformanceStatsModel
  /*
   * new campaign total social metricsl
   */
  campaignTotalSocialMetrics: TotalCampaignSocialMetricsModel
  /*
   * new campaign social metrics for drilldown levels
   */
  campaignDrillDownSocialMetrics: CampaignDrillDownMetricsModel
  /* new Campaign video view endpoint model
   */
  campaignVideoView: CampaignVideoViewModel
  /*
   * new Campaign video view endpoint model
   */
  campaignCreative: CampaignCreativeModel
  /**
   * new Campaign demographic age
   */
  campaignDemograpicsAge: CampaignDemographicsAgeModel
  /**
   * new Campaign demographic gender
   */
  campaignDemograpicsGender: CampaignDemographicsGenderModel
  /**
   * Campaign Top Stations
   */
  campaignTopNetwork: CampaignTopNetworkStatsModel
  /**
   * company Top Network
   */
  companyTopNetwork: CompanyTopNetworkStatsModel
  /**
   * Campaign Top Stations
   */
  campaignTopStations: CampaignTopStationsStatsModel
  /**
   * company Top Network
   */
  companyTopStations: CompanyTopStationsStatsModel
  /**
   * company Audio Ad Completions
   */
  companyAudioAdCompletion: CompanyAudioAdCompletionStatsModel
  /**
   * new campaign Audio Ad Completions
   */
  campaignAudioAdCompletion: CampaignAudioAdCompletionStatsModel
  /**
   *
   */
  campaignDailyReachAndFrequency: CampaignDailyReachAndFrequencyModel
  /**
   *campaign broadcast delivery
   */
  campaignBroadcastDelivery: CampaignBroadcastDelivery
  /**
   *campaign broadcast daily delivery
   */
  campaignBroadcastDailyDelivery: CampaignDailyBroadcastDelivery

  /**
   *broadcast imps goal
   */
  broadcastImpressionGoals: BroadcastImpressionGoals
  /**
   *company broadcast daily delivery
   */
  companyBroadcastDailyDelivery: CompanyBroadcastDailyDelivery

  /**
   *company broadcast delivery
   */
  companyBroadcastDelivery: CompanyBroadcastDelivery
}

const typesObject: ModelTypes = {
  brand: new BrandModel(),
  company: new CompanyModel(),
  companyVideoView: new CompanyVideoViewModel(),
  companyCreative: new CompanyCreativeModel(),
  config: new ConfigModel(),
  dashboard: new DashboardModel(),
  dataTable: new DataTableModel(),
  geoStoreVisitsFlagsModel: new GeoStoreVisitFlagsModel(),
  ihmCampaign: new IhmCampaignModel(),
  impressionsOrderedRange: new ImpressionsOrderedRangeModel(),
  singleCompanyUser: new singleCompanyUserModel(),
  map: new MapModel(),
  mimeTypes: new MimeTypeModel(),
  network: new NetworkModel(),
  number: new NumberModel(),
  publisherLogos: new PublisherLogosModel(),
  render: new RenderModel(),
  sisense: new SisenseModel(),
  sectionLogo: new SectionLogosModel(),
  stats: new StatsModel(),
  styles: new StylesModel(),
  theme: new ThemeModel(),
  user: new UserModel(),
  widget: new WidgetModel(),
  companyDetails: new CompanyDetailsModel(),
  companyDailyStats: new CompanyDailyStatsModel(),
  companyMediaTypeStats: new CompanyMediaTypeStatsModel(),
  companyTotalStats: new CompanyTotalStatsModel(),
  impressionsByPublisher: new ImpressionsByPublisherModel(),
  companySummary: new CompanySummaryModel(),
  companyDailyMediaTypeClickStats: new CompanyDailyMediaTypeClickStatsModel(),
  companyDailyMediaTypeImpStats: new CompanyDailyMediaTypeImpStatsModel(),
  impressionsByDevice: new ImpressionsByDeviceModel(),
  impressionsByDeviceModel: new ImpressionsByDeviceModelModel(),
  companyMediaType: new CompanyMediaTypeStatsModel(),
  companyGeoDmaStatsModel: new GeoDmaStatsModel(),
  companyGeoCityStatsModel: new GeoCityStatsModel(),
  companyGeoStateStatsModel: new GeoStateStatsModel(),
  tacticPerformanceStats: new TacticPerformanceModel(),
  landingPagePerformanceStats: new LandingPagePerformanceStatsModel(),
  companyGeoStoreVisitStatsModel: new GeoStoreVisitStatsModel(),
  OTTImpressionsByDayStats: new OTTImpressionsByDayStatsModel(),
  OTTImpressionsByDayPartStats: new OTTImpressionsByDayPartStatsModel(),
  OTTHoursWatchedStats: new OTTHoursWatchedStatsModel(),
  companyOTTMarketPlaceStatsModel: new CompanyOTTMarketPlaceStatsModel(),
  CampaignSummary: new CampaignSummaryModel(),
  CampaignTotalHeroMetrics: new TotalHeroMetricsModel(),
  reachAndFrequency: new CampaignReachAndFrequencyModel(),
  campaignImpressionsByPublisher: new CampaignImpressionsByPublisherModel(),
  ihmCampaignDetails: new IHMCampaignDetailsModel(),
  campaignImpressionsByDeviceModel: new CampaignImpressionsByDeviceModelModel(),
  campaignImpressionsByDevice: new CampaignImpressionsByDeviceModel(),
  campaignOTTHoursWatchedStats: new CampaignOTTHoursWatchedStatsModel(),
  campaignDailyStats: new CampaignDailyStatsModel(),
  campaignDailyImpsClicks: new CampaignDailyImpsClicksModel(),
  campaignMediaType: new CampaignMediaTypeStatsModel(),
  campaignOTTMarketPlaceStats: new CampaignOTTMarketPlaceStatsModel(),
  campaignDetails: new CampaignDetailsModel(),
  campaignOTTByDayStats: new CampaignOTTImpressionsByDayStatsModel(),
  campaignTacticPerformance: new CampaignTacticPerformanceModel(),
  campaignOTTImpressionsByDayPartStats: new CampaignOTTImpressionsByDayPartStatsModel(),
  campaignGeoStoreVisitFlag: new CampaignGeoStoreVisitFlagsModel(),
  campaignGeoStoreVisitStats: new CampaignGeoStoreVisitStatsModel(),
  campaignGeoCityStats: new CampaignGeoCityStatsModel(),
  campaignGeoDmaStats: new CampaignGeoDmaStatsModel(),
  campaignGeoStateStats: new CampaignGeoStateStatsModel(),
  campaignLandingPagePerformanceStats: new CampaignLandingPagePerformanceStatsModel(),
  campaignTotalSocialMetrics: new TotalCampaignSocialMetricsModel(),
  campaignDrillDownSocialMetrics: new CampaignDrillDownMetricsModel(),
  campaignVideoView: new CampaignVideoViewModel(),
  campaignCreative: new CampaignCreativeModel(),
  campaignDemograpicsAge: new CampaignDemographicsAgeModel(),
  campaignDemograpicsGender: new CampaignDemographicsGenderModel(),
  campaignAudioAdCompletion: new CampaignAudioAdCompletionStatsModel(),
  campaignTopNetwork: new CampaignTopNetworkStatsModel(),
  companyAudioAdCompletion: new CompanyAudioAdCompletionStatsModel(),
  companyTopNetwork: new CompanyTopNetworkStatsModel(),
  campaignDailyReachAndFrequency: new CampaignDailyReachAndFrequencyModel(),
  campaignBroadcastDelivery: new CampaignBroadcastDelivery(),
  campaignBroadcastDailyDelivery: new CampaignDailyBroadcastDelivery(),

  broadcastImpressionGoals: new BroadcastImpressionGoals(),
  companyBroadcastDailyDelivery: new CompanyBroadcastDailyDelivery(),
  companyBroadcastDelivery: new CompanyBroadcastDelivery(),

  campaignTopStations: new CampaignTopStationsStatsModel(),
  companyTopStations: new CompanyTopStationsStatsModel(),
}

export default typesObject
