import fileDownload from 'js-file-download'
import log from '@/log'
import Styles, { StylesModel } from '@/types/styles'
import vuetify from '@/plugins/vuetify'

const styles = new StylesModel()

export interface WidgetMenuItem {
  title: string
  click: any
}

export class WidgetModel {
  /**
   * Get the maximum width of a widget, based on the number
   * of columns in it's row
   * */
  maxWidth(columns: number = 3): number {
    const bp = vuetify.framework.breakpoint
    // When screen size goes to sm, then the max width the widget
    // can have is the threshold of sm
    if (bp.smAndDown) return bp.thresholds.sm

    // Else we calculate the maximum width of the widget
    // based on it's breakpoint size.
    // NOTE: does not support mixed breakpoint sized columns
    const bpWidth = Math.floor(12 / columns)
    const percentOfWidth = bpWidth / 12
    return styles.maxWidth * percentOfWidth
  }

  minWidth(columns: number = 3): number {
    const bp = vuetify.framework.breakpoint
    // When screen size goes to sm, then the min width the widget
    // can have is the minimum supported resolution (320px iphone 5)
    if (bp.smAndDown) return styles.minWidth

    // Else we calculate the minimum width of the widget
    // based on it's breakpoint size.
    // NOTE: does not support mixed breakpoint sized columns
    const bpWidth = Math.floor(12 / columns)
    const percentOfWidth = bpWidth / 12
    return bp.thresholds.sm * percentOfWidth
  }

  /**
   * Get the width configuration to use for scaling widget sizes
   */
  widgetWidths(
    columns: number = 3,
    unit: string = 'px'
  ): { width: number; maxWidth: number; minWidth: number; unit: string } {
    const bp = vuetify.framework.breakpoint
    let width = screen.width
    if (bp.mdAndUp) {
      width = width / columns
    }

    return {
      width,
      maxWidth: this.maxWidth(columns),
      minWidth: this.minWidth(columns),
      unit,
    }
  }

  /**
   * Scale a number between a minimum and maximum size based on the
   * size of a widget, extrapolated from the number of columns in
   * it's row
   */
  scale(min: number, max: number, columns: number = 2, unit: string = 'px'): string {
    const widths = this.widgetWidths(columns)
    widths.unit = unit
    return styles.scale(min, max, widths)
  }

  heroNumberAttrs(columns: number = 3): Styles {
    const min = columns < 5 ? 30 : 22
    const max = columns < 5 ? 48 : 32
    return {
      class: 'info--text',
      style: {
        fontSize: this.scale(min, max, columns),
      },
    }
  }

  heroTitleAttrs(columns: number = 3): Styles {
    const min = columns < 5 ? 22 : 20
    const max = columns < 5 ? 32 : 28
    return {
      style: {
        fontSize: this.scale(min, max, columns),
      },
    }
  }

  heroSubheadingAttrs(columns: number = 3): Styles {
    const min = columns < 5 ? 12 : 8
    const max = columns < 5 ? 16 : 12
    return {
      style: {
        fontSize: this.scale(min, max, columns),
      },
    }
  }

  downloadCanvasPNG(canvas: HTMLCanvasElement, fileName: string): Promise<void> {
    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          reject('An error occurred getting canvas blob')
          return
        }
        fileDownload(blob, fileName)
        resolve()
      }, fileName)
    })
  }
}

export class FullScreen {
  private isFullScreen: boolean
  constructor(private container: Element, private onChange: (i: boolean, e: Event) => void) {
    this.container = container
    this.onChange = onChange
    this.isFullScreen = false

    document.addEventListener('fullscreenchange', this.onFullScreenChange.bind(this))
    document.addEventListener('fullscreenerror', this.onFullScreenError.bind(this))

    const c = this.container as any
    if (this.container.requestFullscreen) {
      this.container.requestFullscreen()
    } else if (c.mozRequestFullScreen) {
      c.mozRequestFullScreen()
    } else if (c.webkitRequestFullscreen) {
      c.webkitRequestFullscreen()
    } else if (c.msRequestFullscreen) {
      c.msRequestFullscreen()
    }
  }

  onFullScreenError(e: Event): any {
    log.error('An error occurred making fullscreen: ', e)
    this.isFullScreen = false
    this.onChange(this.isFullScreen, e)
    this.cleanup()
  }

  onFullScreenChange(e: Event) {
    this.isFullScreen = !this.isFullScreen
    this.onChange(this.isFullScreen, e)
    if (!this.isFullScreen) {
      this.cleanup()
    }
  }

  cleanup() {
    this.container.removeEventListener('fullscreenchange', this.onFullScreenChange)
    this.container.removeEventListener('fullscreenerror', this.onFullScreenError)
  }

  public static exitFullscreen() {
    const d = document as any
    if (document.exitFullscreen) {
      document.exitFullscreen()
    } else if (d.mozCancelFullScreen) {
      /* Firefox */
      d.mozCancelFullScreen()
    } else if (d.webkitExitFullscreen) {
      /* Chrome, Safari and Opera */
      d.webkitExitFullscreen()
    } else if (d.msExitFullscreen) {
      /* IE/Edge */
      d.msExitFullscreen()
    }
  }
}
