import api from '@/api'
import types from '@/types'
import Report, { UnsupportedMediaTypeReport } from '@/api/communique/report'

export interface ImpressionsByDeviceModel {
  // the number of impressions for each of the device models
  impressions: number

  // the type of the device
  device_model: string
}

export interface FetchImpressionsByDeviceModelParams {
  companyId: string
  startDate?: string
  endDate?: string
  mediaTypes?: string[]
  excludedInitiativeIDs?: string[]
  includedInitiativeIDs?: string[]
  identityID?: string
}

export class ImpressionsByDeviceModelModel {
  public async fetchAll({
    companyId,
    mediaTypes,
    endDate,
    excludedInitiativeIDs,
    includedInitiativeIDs,
    startDate,
  }: FetchImpressionsByDeviceModelParams): Promise<
    Report<ImpressionsByDeviceModel> | UnsupportedMediaTypeReport | null
  > {
    return api('report.dashboards.ihmCompany.deviceModel')
      .params({
        companyId,
        media_types: mediaTypes,
        end_date: endDate,
        exclude_initiative_ids: excludedInitiativeIDs,
        include_initiative_ids: includedInitiativeIDs,
        start_date: startDate,
      })
      .cache(1000 * types.config.dashboardCacheSeconds())
      .jsonMust<Report<ImpressionsByDeviceModel>>()
  }
}
