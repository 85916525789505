import api from '@/api'
import types from '@/types'
import Report, { UnsupportedMediaTypeReport } from '@/api/communique/report'

export interface CampaignMediaTypeStat {
  media_type: string
  sub_media_type?: string
  impressions: number
}
export default CampaignMediaTypeStat

export interface CampaignFetchParams {
  startDate?: string
  endDate?: string
  initiativeId?: string
  mediaTypes: string[]
  excludeLineItemIds?: string[]
  includeLineItemIds?: string
}

export class CampaignMediaTypeStatsModel {
  public async fetchAll({
    mediaTypes,
    startDate,
    endDate,
    initiativeId,
    excludeLineItemIds,
    includeLineItemIds,
  }: CampaignFetchParams): Promise<Report<CampaignMediaTypeStat> | UnsupportedMediaTypeReport> {
    return api('report.dashboards.ihmCampaign.v2.campaignMediaTypeStats')
      .params({
        media_types: mediaTypes,
        initiativeId,
        start_date: startDate,
        end_date: endDate,
        exclude_line_item_ids: excludeLineItemIds,
        include_line_item_ids: includeLineItemIds,
      })
      .cache(1000 * types.config.dashboardCacheSeconds())
      .jsonMust<Report<CampaignMediaTypeStat>>()
  }
}
