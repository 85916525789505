import api from '@/api'
import types from '@/types'
import Report, { UnsupportedMediaTypeReport } from '@/api/communique/report'

export interface ImpressionsByPublisher {
  // the number of impressions a publisher has
  impressions: number

  // the name of the publisher
  publisher: string

  // the media type of the publisher
  media_type: string
}

export interface FetchImpressionsByPublisherParams {
  startDate?: string
  endDate?: string
  initiativeId?: string
  mediaTypes: string[]
  excludeLineItemIds?: string[]
  includeLineItemIds?: string
}

export class CampaignImpressionsByPublisherModel {
  public async fetchAll({
    mediaTypes,
    startDate,
    endDate,
    initiativeId,
    excludeLineItemIds,
    includeLineItemIds,
  }: FetchImpressionsByPublisherParams): Promise<Report<ImpressionsByPublisher> | UnsupportedMediaTypeReport | null> {
    return api('report.dashboards.ihmCampaign.v2.impressionsByPublisher')
      .params({
        media_types: mediaTypes,
        initiativeId,
        start_date: startDate,
        end_date: endDate,
        exclude_line_item_ids: excludeLineItemIds,
        include_line_item_ids: includeLineItemIds,
      })
      .cache(1000 * types.config.dashboardCacheSeconds())
      .jsonMust<Report<ImpressionsByPublisher>>()
  }
}
