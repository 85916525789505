import api from '@/api'
import types from '@/types'
import Report, { UnsupportedMediaTypeReport } from '@/api/communique/report'

export interface CampaignTacticPerformance {
  // the number of impressions for a specific grouping
  impressions: number

  // the number of clicks for a specific grouping
  clicks: number

  // the grouping to group the result by
  grouping: string
}

export interface FetchTacticPerformanceParams {
  startDate?: string
  endDate?: string
  initiativeId?: string
  mediaTypes: string[]
  excludeLineItemIds?: string[]
  includeLineItemIds?: string
}

export class CampaignTacticPerformanceModel {
  public async fetchAll({
    initiativeId,
    mediaTypes,
    endDate,
    excludeLineItemIds,
    includeLineItemIds,
    startDate,
  }: FetchTacticPerformanceParams): Promise<Report<CampaignTacticPerformance> | UnsupportedMediaTypeReport | null> {
    return api('report.dashboards.ihmCampaign.v2.tacticPerformanceStats')
      .params({
        initiativeId,
        media_types: mediaTypes,
        end_date: endDate,
        start_date: startDate,
        exclude_line_item_ids: excludeLineItemIds,
        include_line_item_ids: includeLineItemIds,
      })
      .cache(1000 * types.config.dashboardCacheSeconds())
      .jsonMust<Report<CampaignTacticPerformance>>()
  }
}
