export const slugify = (str: string, replace: string = '-'): string => {
  // From: https://gist.github.com/mathewbyrne/1280286#gistcomment-1716050
  return str
    .toLowerCase()
    .replace(/[^\w\s-]/g, '') // remove non-word [a-z0-9_], non-whitespace, non-hyphen characters
    .replace(/[\s_-]+/g, replace) // swap any length of whitespace, underscore, hyphen characters with replace
    .replace(/^-+|-+$/g, '') // remove leading, trailing -
}

export default slugify
