import log, { Logger } from '@/log'
import router from '@/router'
import colors from '@/stylus/colors.json'
import Types, { ModelTypes } from '@/types'
import Vue from 'vue'

// This is how we define prototype types in Typescript
declare module 'vue/types/vue' {
  interface Vue {
    /**
     * Show the alert dialog
     */
    $alert: (headline: string, message?: string, close?: () => void, width?: number, btnText?: string) => void
    /**
     * Make available the main color set from our color types in Vue
     */
    $colors: any
    /**
     * Show the confirmation dialog for an action
     */
    $confirm: (
      headline: string,
      message: string,
      ok: () => void,
      cancel?: () => void,
      width?: number,
      okText?: string,
      cancelText?: string
    ) => void
    /**
     * Redirect to error
     */
    $redirectError: () => void
    /**
     * Make available our custom logger in Vue
     */
    $log: Logger
    /**
     * Reset a components data to initial state
     */
    $reset: (fields?: string[], ignoreFields?: boolean) => void
    /**
     * Redirect to not found
     */
    $redirectNotFound: () => void
    /**
     * Send an error message to the user
     */
    $sendErr: (s: string) => void
    /**
     * Send a message to the user
     */
    $sendMsg: (s: string) => void
    /**
     * Make available all model type functions in Vue
     */
    $types: ModelTypes
    /**
     * Allows for watching multiple properties at once
     */
    $watchAll: (props: string[], callback: (k: string, newVal: any, oldVal: any) => void, options?: any) => void
  }
}

Vue.prototype.$alert = function (
  headline: string,
  message: string = '',
  close: () => void = () => {},
  width: number = 700,
  btnText: string = 'OK'
) {
  // To explain - global programatic dialog is only created once on App.
  // App is the first child of $root. So we get the app and get the confirm
  // dialog ref from that, hence this convenience method
  const alert = this.$root.$children[0].$refs.alertDialog
  alert.show(headline, message, close, width, btnText)
}

Vue.prototype.$confirm = function (
  headline: string,
  message: string,
  ok: () => void,
  cancel: () => void = () => undefined,
  width: number = 700,
  okText: string = 'OK',
  cancelText: string = 'Cancel'
) {
  // To explain - global programatic dialog is only created once on App.
  // App is the first child of $root. So we get the app and get the confirm
  // dialog ref from that, hence this convenience method
  const confirm = this.$root.$children[0].$refs.confirmDialog
  confirm.show(headline, message, ok, cancel, width, okText, cancelText)
}

Vue.prototype.$watchAll = function (
  props: string[],
  callback: (k: string, newVal: any, oldVal: any) => void,
  options: any = {}
) {
  props.forEach((prop) => {
    // Pass the prop as the first argument to our callback
    this.$watch(prop, callback.bind(null, prop), options[prop] || {})
  })
}

Vue.prototype.$redirectNotFound = (): void => {
  router.push({
    name: 'notFound',
  })
}

Vue.prototype.$redirectError = (): void => {
  router.push({
    name: 'error',
  })
}

Vue.prototype.$sendErr = function (msg: string): void {
  this.$store.commit('error', msg)
}

Vue.prototype.$sendMsg = function (msg: string): void {
  this.$store.commit('message', msg)
}

// Useful for logging anywhere in components: https://medium.com/vuejs-tips/vue-log-a7b0b5d16a0
Vue.prototype.$log = log

/**
 * Reset the components data.  If nothing is passed, data is fully reset.
 * param fields The fields to reset if ignoreFields is false. The fields to ignore in reset if ignoreFields is true
 * param ignoreFields Modifies the way fields is processed to either reset a list of fields, or ignore resetting fields
 */
Vue.prototype.$reset = function (fields: string[] = [], ignoreFields: boolean = false) {
  const defaults = this.$options.data.apply(this)
  if (ignoreFields) {
    fields.forEach((f) => delete defaults[f])
  } else if (fields.length) {
    Object.keys(defaults).forEach((k) => {
      if (!fields.includes(k)) delete defaults[k]
    })
  }
  Object.assign(this.$data, defaults)
  this.$emit('reset')
}

// Here we put the model types on the Vue prototype so we can
// access model type functions in templates
Vue.prototype.$types = Types

// Put our master color list on the prototype so it can be used in templates/components
Vue.prototype.$colors = Object.assign({}, colors)

Vue.config.productionTip = false

Vue.config.errorHandler = (err, vm, info) => {
  const message = `Error in component ${vm.$options.name} (${info}): ${err}`
  log.exception(err, message)
}

export {}
