import api from '@/api'
import types from '@/types'

export default interface CompanySummary {
  /**
   * The name of the company
   */
  companyName: string
  id: string
  dateCreated: number
  dateModified: number
  disabled: boolean
  minOrderStartDate: string
  maxOrderEndDate: string
  logo: string
  impressionsOrdered: number
  initiativesCount: number
  timeZone: string
}

export interface FetchCompanySummaryParams {
  companyId: string
  startDate?: string
  endDate?: string
  mediaTypes: string[]
  excludedInitiativeIDs?: string[]
  includedInitiativeIDs?: string[]
  identityID?: string
}

export class CompanySummaryModel {
  public async fetch({
    companyId,
    mediaTypes,
    endDate,
    excludedInitiativeIDs,
    includedInitiativeIDs,
    identityID,
    startDate,
  }: FetchCompanySummaryParams): Promise<CompanySummary> {
    return api('pmn.ihmCompanies.summary')
      .params({
        companyId,
        mediaTypes,
        endDate,
        excludedInitiativeIDs,
        includedInitiativeIDs,
        identityID,
        startDate,
      })
      .cache(1000 * types.config.dashboardCacheSeconds())
      .jsonMust<CompanySummary>()
  }
}
