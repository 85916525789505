import api from '@/api'
import { loadPages } from '@/api/pmn'

export default interface Brand {
  /**
   * The brand's company
   */
  companyID: string
  /**
   * The brand's created date
   */
  dateCreated: string
  /**
   * The brand's modified date
   */
  dateModified: string
  /**
   * True if the brand is disabled
   */
  disabled: boolean
  /**
   * The brand's foreign ID
   */
  foreignID: string
  /**
   * The brand's id
   */
  id: string
  /**
   * The brand's name
   */
  name: string
}

/**
 * Contains methods for working with the Brand type
 */
export class BrandModel {
  /**
   * Fetch the current user's brands from publisher API
   */
  public async fetchAll(): Promise<Brand[]> {
    const a = api('pmn.brands')
      .params({ disabled: false })
      .cache(1000 * 60 * 5)
    return loadPages<Brand>(a, 'brands', 100)
  }

  /**
   * Get the index of the brand with the given name
   * @param bs The brands to search
   * @param id The id to get the index of
   */
  public indexOfID(bs: Brand[], id: string): number {
    for (let i = 0; i < bs.length; i++) {
      if (bs[i].id === id) return i
    }
    return -1
  }

  /**
   * Get the index of the brand with the given name
   * @param bs The brands to search
   * @param id The id to get the index of
   */
  public getByID(bs: Brand[], id: string): Brand | null {
    const idx = this.indexOfID(bs, id)
    if (idx === -1) return null
    return bs[idx]
  }

  /**
   * Get the index of the brand with the given name
   * @param bs The brands to search
   * @param name The name to get the index of
   */
  public indexOfName(bs: Brand[], name: string): number {
    for (let i = 0; i < bs.length; i++) {
      if (bs[i].name === name) return i
    }
    return -1
  }
}
