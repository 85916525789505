import store from '@/store'
import * as colors from '@/stylus/colors.json'
import types from '@/types'
import { VuetifyThemeVariant } from 'vuetify/types/services/theme'

export default interface ThemeStyles {
  // The title of the app
  title: string
  light?: ThemeVariant
  dark?: ThemeVariant
}

export interface ThemeVariant {
  // The vueitfy theme
  vuetify: VuetifyThemeVariant
  // The highcharts color scheme for this theme
  highcharts: string[]
  // The loading image
  loading: string
  // The logo image
  logo: string
  // The drawer logo
  drawerLogo: string
  // True if the drawer is a dark style
  drawerDark: boolean
  // The drawer color
  drawer: string
  // The drawer accent color
  drawerAccent: string
  // The drawer selected item color
  drawerSelected: string
  // The drawer selected item accent color
  drawerSelectedAccent: string
  // The toolbar border color
  toolbarBorder: string
  // PlaceHolder
  placeHolderCompanyLogo?: string
}

export enum Theme {
  UNIFIED = 'UNIFIED',
  UNIFIED_ADMIN = 'UNIFIED_ADMIN',
  IHM = 'IHM',
}

const styles: { [key in Theme]: ThemeStyles } = {
  [Theme.UNIFIED]: colors.UPTheme,
  [Theme.IHM]: colors.IHMTheme,
  [Theme.UNIFIED_ADMIN]: colors.UPAdminTheme,
}

export class ThemeModel {
  public isDark(): boolean {
    const c = this.currentStyles()
    if (store.state.dark !== null) store.state.dark
    return !c.light
  }

  public currentTheme(): ThemeVariant {
    const c = this.currentStyles()
    if (!c.light) return assertv(c.dark)
    if (!c.dark) return assertv(c.light)
    return this.isDark() ? assertv(c.dark) : assertv(c.light)
  }

  public currentStyles(): ThemeStyles {
    const theme = this.current()
    return styles[theme]
  }
  public current(): Theme {
    const c = types.company.currentLoggedIn()

    // Rig To Have an IHM theme By Default
    if (!types.config.isIHMLocation()) {
      return Theme.UNIFIED
    }

    if (types.company.forceWhiteLabeled(c)) {
      return Theme.UNIFIED
    }

    if (types.company.isProvider(c)) {
      return Theme.UNIFIED_ADMIN
    }

    if (types.company.isIHMBranded(false, c)) {
      return Theme.IHM
    }

    return Theme.UNIFIED
  }
}
