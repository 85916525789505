import log from '@/log'
import Config from '@/types/config'

export interface AppRenderer {
  renderApp(options: {
    /** Whether it should verify authentication intermittenly. */
    autoPoll: boolean
    /** The base route name. */
    basename: string
    /** The flag that define IHeartMedia Platform*/

    isIHMPlatform?: boolean
    /** Whether it should render the header. */
    noHeader: boolean
    /** Path to render default component */
    defaultPage?: string
  }): void
}

declare global {
  /**
   * An assertion function helper
   */
  function assertv<T>(value: T | null | undefined, msg?: string): T

  interface Window {
    /**
     * Contains the configuration from the server
     */
    unifiedConfig?: Config
    /**
     * Contains global values from the platform-ui server
     */
    unifiedGlobal?: any
    /**
     * Pacing dashboard renderer
     */
    UnifiedPacing?: AppRenderer
    /**
     * IHM Report renderer
     */
    UnifiedReports?: AppRenderer
    /**
     * Sales Dashboard renderer
     */
    SalesDashboard?: AppRenderer
    /**
     * The token to use for Mapbox
     */
    mapboxAPIToken?: string
    /**
     * the URL to sisense
     */
    pendo: any

    LD_CLIENT_SIDE_ID: string

    dashboardsURL?: string
    /**
     * True if we're in dev mode
     */
    __DEV__: boolean
    /**
     * An assertion function helper
     */
    assertv<T>(value: T | null | undefined, msg?: string): T
    /**
     * Dynamically load a script and wait for it to be loaded
     */
    $loadScript(
      script: string,
      check: (() => boolean) | string,
      ret?: (() => any) | string,
      timeoutMS?: number
    ): Promise<any>
    /**
     * Check if the given remote file exists
     */
    $urlExists(url: string): Promise<boolean>
  }
}

window.assertv = function <T>(value: T | null | undefined, msg: string = 'value is falsy'): T {
  if (value) {
    return value
  } else {
    throw new Error(`Assertion Failed: ${msg}`)
  }
}

window.$urlExists = async (url: string): Promise<boolean> => {
  try {
    const resp = await fetch(url, { method: 'head' })
    return resp.status !== 404
  } catch (e) {
    return true // If there is some issue with communication, assume it exists. Could be CORS issue.
  }
}

/**
 * Loads a script dynamically.
 * If check is a function, when it returns true, script is assumed to be loaded
 * If check doesn't return true in timeoutMS, an error is thrown.
 * If check is a string, when a property exists on window with that name,
 * the script is assumed to be loaded
 * If ret is unspecified and check is a string, the promise will return
 * whatever is at window[check]
 * If ret is a string, the promise will return whatever is at window[ret]
 * If ret is a function, the promise will return the output of that function
 */
window.$loadScript = (
  script: string,
  check: (() => boolean) | string,
  ret?: (() => any) | string,
  timeoutMS: number = 5000
): Promise<any> => {
  return new Promise((resolve, reject) => {
    const checkFunc = typeof check !== 'string' ? check : () => !!(window as any)[check]

    let retFunc: () => any
    if (!ret) {
      retFunc = typeof check === 'string' ? () => (window as any)[check] : () => window
    } else {
      retFunc = typeof ret !== 'string' ? ret : () => (window as any)[ret]
    }

    if (checkFunc()) {
      resolve(retFunc())
      return
    }

    const start = Date.now()
    const tag = document.createElement('script')
    tag.setAttribute('src', script)
    document.head.appendChild(tag)

    const int = setInterval(() => {
      if (checkFunc()) {
        clearInterval(int)
        resolve(retFunc())
      } else if (timeoutMS && Date.now() - start > timeoutMS) {
        clearInterval(int)
        reject(new Error(`$loadScript Timeout: ${script} did not load in ${timeoutMS}ms`))
      }
    }, 100)
  })
}

window.onunhandledrejection = (event: PromiseRejectionEvent) => {
  if (event.reason instanceof Error) {
    const message = 'Unhandled Exception in async function'

    log.exception(event.reason, message, event)
  } else {
    const message = `Unhandled Exception in async function: ${event.reason}`
    log.error(message, event)
  }
}

window.onerror = (e, source, fileno, columnNumber, err) => {
  const message = `Unhandled Exception (${source}:${fileno}:${columnNumber})`
  if (err) {
    log.exception(err, message, e)
  } else {
    log.error(message, e)
  }
}

export {}
