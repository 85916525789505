import Vue from 'vue'
import VueObserveVisibility from 'vue-observe-visibility'

import App from '@/App.vue'
import '@/plugins/fontawesome'
import '@/plugins/globals'
import '@/plugins/highcharts'
import '@/plugins/videojs'
import '@/plugins/void'
import '@/plugins/vue'
import vuetify from '@/plugins/vuetify'
import router from '@/router'
import store from '@/store'
import '@fontsource/inter' // Defaults to weight 400
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import 'reflect-metadata'
import './workers/checkLoginWorker.worker.ts'

Vue.use(VueObserveVisibility)
if (window.unifiedConfig) {
  window.unifiedConfig = {
    // Temp fix until we find a better setup for micro-frontend envs
    ...window.unifiedConfig,
    orderPerformanceApi: window.unifiedConfig?.platformApiHost + '/order_performance',
    orderPerformanceApiPort: 443,
    orderPerformanceApiProtocol: 'https',
    pmnHost: window.unifiedConfig?.platformApiHost + '/pmn',
    pmnPort: 443,
    pmnProtocol: 'https',
  }
}
export default function (props = {}, elementID = 'app') {
  new Vue({
    errorCaptured: Vue.config.errorHandler,
    render: (h) => h(App, { props }),
    router,
    store,
    vuetify,
  }).$mount(`#${elementID}`)
}
