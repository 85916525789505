import api from '@/api'
import Report from '@/api/communique/report'
import types from '@/types'

export interface CampaignDetails {
  /**
   * The id of the company related to the order
   */
  company_id: string
  /**
   * the company logo
   */
  company_logo: string
  /**
   * The name of the company
   */
  company_name: string
  /**
   * the name of the campaign
   */
  dashboard_friendly_name: string
  /**
   * the lowest start date for an order
   */
  earliest_start_date: string
  /**
   * the highest end date for an order
   */
  initiative_id: string
  /**
   * all the media types for a specific company
   */
  initiative_name: Array<string>
  /**
   * the highest end date for an order
   */
  latest_end_date: string
  /**
   * the number of line items for a initiative
   */
  lineItemCount: number
  /** the actual line items
   *
   */
  lineitems: Array<LineItemData>
  /** max line item end date
   *
   */
  maxLineItemEndDate: string
  /** media types the campaign supports
   *
   */
  mediaTypes: Array<string>
  /** min line item start date
   *
   */
  minLineItemStartDate: string
}

export interface LineItemData {
  id: string
  name: string
}

export class CampaignDetailsModel {
  public async fetchAll(initiativeId: string): Promise<Report<CampaignDetails> | null> {
    return api('pmn.newIhmCampaignSummary.details')
      .params({
        initiativeId,
      })
      .cache(1000 * types.config.dashboardCacheSeconds())
      .jsonMust<Report<CampaignDetails>>()
  }
}
