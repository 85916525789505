import api from '@/api'
import Report from '@/api/communique/report'
import types from '@/types'

export default interface ImpressionGoals {
  initiative_id: string
  impressions_goal: number
}

export class BroadcastImpressionGoals {
  public async fetch(initiativeIds: String[]): Promise<Report<ImpressionGoals>> {
    return api('report.dashboards.ihmCampaign.v2.broadcastImpressionGoals')
      .params({
        initiative_ids: initiativeIds,
      })
      .cache(1000 * types.config.dashboardCacheSeconds())
      .jsonMust<Report<ImpressionGoals>>()
  }
}
