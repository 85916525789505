import api from '@/api'
import types from '@/types'
import Report, { UnsupportedMediaTypeReport } from '@/api/communique/report'

export interface ImpressionsByDeviceModel {
  // the number of impressions for each of the device models
  impressions: number

  // the type of the device
  device_model: string
}

export interface FetchImpressionsByDeviceModelParams {
  startDate?: string
  endDate?: string
  initiativeId?: string
  mediaTypes: string[]
  excludeLineItemIds?: string[]
  includeLineItemIds?: string
}

export class CampaignImpressionsByDeviceModelModel {
  public async fetchAll({
    initiativeId,
    mediaTypes,
    endDate,
    excludeLineItemIds,
    includeLineItemIds,
    startDate,
  }: FetchImpressionsByDeviceModelParams): Promise<
    Report<ImpressionsByDeviceModel> | UnsupportedMediaTypeReport | null
  > {
    return api('report.dashboards.ihmCampaign.v2.deviceModel')
      .params({
        initiativeId,
        media_types: mediaTypes,
        end_date: endDate,
        exclude_line_item_ids: excludeLineItemIds,
        include_line_item_ids: includeLineItemIds,
        start_date: startDate,
      })
      .cache(1000 * types.config.dashboardCacheSeconds())
      .jsonMust<Report<ImpressionsByDeviceModel>>()
  }
}
