import api from '@/api'
import types from '@/types'
import Report, { UnsupportedMediaTypeReport } from '@/api/communique/report'

export interface ImpressionsByDevice {
  // the number of impressions for a specific device
  impressions: number

  // the type of the device
  device_type: string
}

export interface FetchImpressionsByDeviceParams {
  companyId: string
  startDate?: string
  endDate?: string
  mediaTypes?: string[]
  excludedInitiativeIDs?: string[]
  includedInitiativeIDs?: string[]
  identityID?: string
}

export class ImpressionsByDeviceModel {
  public async fetchAll({
    companyId,
    mediaTypes,
    endDate,
    excludedInitiativeIDs,
    includedInitiativeIDs,
    startDate,
  }: FetchImpressionsByDeviceParams): Promise<Report<ImpressionsByDevice> | UnsupportedMediaTypeReport | null> {
    return api('report.dashboards.ihmCompany.device')
      .params({
        companyId,
        media_types: mediaTypes,
        end_date: endDate,
        exclude_initiative_ids: excludedInitiativeIDs,
        include_initiative_ids: includedInitiativeIDs,
        start_date: startDate,
      })
      .cache(1000 * types.config.dashboardCacheSeconds())
      .jsonMust<Report<ImpressionsByDevice>>()
  }
}
