
import { checkLogin, logout, unimpersonate } from '@/api'

import UserStorage from '@unified/js-common/lib/user/User'
import Vue from 'vue'
import UserFlags from '@unified/js-common/lib/user/UserFlags'
const AlertDialog = () => import('@/components/dialogs/AlertDialog.vue')
const ConfirmDialog = () => import('@/components/dialogs/ConfirmDialog.vue')
const TheNavDrawer = () => import('@/components/TheNavDrawer.vue')
const TheToolBar = () => import('@/components/TheToolBar.vue')
const UserMessage = () => import('@/components/UserMessage.vue')
const LoadingImage = () => import('@/components/LoadingImage.vue')

function setFavicon(url: string): void {
  // Find the existing favicon element
  let link: HTMLLinkElement | null = document.querySelector("link[rel*='icon']")

  // Create a new link element if one does not already exist
  if (!link) {
    link = document.createElement('link')
  }

  // Set the attributes for the link element
  link.type = 'image/x-icon'
  link.rel = 'shortcut icon'
  link.href = url

  // Append the link element to the head if it does not exist
  if (!document.head.contains(link)) {
    document.head.appendChild(link)
  }
}

export default Vue.extend({
  name: 'UIDashboards',
  components: {
    AlertDialog,
    ConfirmDialog,
    TheNavDrawer,
    TheToolBar,
    UserMessage,
    LoadingImage,
  },
  data() {
    return {
      loading: true,
    }
  },
  async beforeCreate() {
    checkLogin()
  },
  beforeDestroy() {},
  async created() {
    await this.init()
    this.loading = false
  },
  methods: {
    async init() {
      const c = this.$types.company.currentLoggedIn()
      const forceWhiteLabeled = this.$types.company.forceWhiteLabeled(c)
      const isIHMLocation = this.$types.config.isIHMLocation()
      const isNotDevMode = this.$types.config.isDev() === false

      if (forceWhiteLabeled && isIHMLocation && isNotDevMode) {
        logout()
      }

      this.$log.debug(`Dev Mode: ${this.$types.config.isDev()}`)
      this.$log.debug(`Cache Disabled: ${this.$types.config.cacheDisabled()}`)
      this.$log.debug(`APIConfig`, this.$types.config.APIConfig())
      this.$log.debug(`UIConfig`, this.$types.config.UIConfig())
      this.$log.debug(`Sisense URL`, this.$types.config.SisenseURL())

      const theme = this.$types.theme.currentStyles()

      this.$vuetify.theme.dark = this.$types.theme.isDark()
      if (theme.dark) {
        this.$vuetify.theme.themes.dark = theme.dark.vuetify
      }
      if (theme.light) {
        this.$vuetify.theme.themes.light = theme.light.vuetify
      }

      document.title = theme.title
      setFavicon(`/branding/${window.location.hostname}/favicon.ico`)

      this.loadGlobalConfig()
      await this.loadLD()

      this.loadDashboards()
      await this.loadPendo()

      window.addEventListener('beforeunload', () => {
        // close left navigation drawer when the page is unloaded
        this.$store.commit('toggleDrawer', null)
      })
    },
    getMailto(): string {
      const prefix = this.$types.company.isIHMBranded(false) ? 'iheartmedia-' : ''
      return `mailto:${prefix}support@unified.com`
    },
    dismissError(): void {
      this.$store.commit('broken', false)
    },
    reloadPage(): void {
      var url = new URL(window.location.href)
      url.searchParams.set('broken', 'true')
      window.location.href = url.toString()
    },
    isSuperBroken(): boolean {
      return this.$store.state.broken && !!this.$router.$queryParam('broken')
    },
    async loadDashboards() {
      try {
        this.$store.commit('dashboards', await this.$types.dashboard.loadAll())
      } catch (err) {
        this.$sendErr('An unexpected error occured loading your dashboards. Please refresh the page.')
        throw err
      }
    },
    loadGlobalConfig() {
      const jsId = 'unified-global-config'

      if (!document.getElementById(jsId)) {
        const src = `${this.$types.config.UPDomain()}/global.js`
        const scriptElm = document.createElement('script')
        scriptElm.setAttribute('src', src)
        scriptElm.setAttribute('id', jsId)

        document.head.appendChild(scriptElm)
      }
    },
    async loadLD() {
      const LD_KEY = this.$types.config.LaunchDarkly()

      if (!LD_KEY) {
        this.$log.error("Couldn't find the LD_CLIENT_ID")
        return
      }

      UserFlags.setLdClientSideId(LD_KEY)
      await this.loadLDFlags()
    },
    loadLDFlags() {
      return this.$store.dispatch('checkLDFlags', [
        'company-roll-up',
        'ihmcampaigns-selection',
        'new-initiative-dash',
        'violation-dashboard',
      ])
    },
    async loadPendo() {
      try {
        const api_key = this.$types.config.pendoAPIKey()

        if (!api_key) {
          return
        }
        await (function (apiKey) {
          ;(function (p: any, e, n, d: string, o: any) {
            var v: string[], w, x, y: any, z: any
            o = p[d] = p[d] || {}
            o._q = []
            v = ['initialize', 'identify', 'updateOptions', 'pageLoad']
            for (w = 0, x = v.length; w < x; ++w)
              (function (m) {
                o[m] =
                  o[m] ||
                  function () {
                    o._q[m === v[0] ? 'unshift' : 'push']([m].concat([].slice.call(arguments, 0)))
                  }
              })(v[w])
            y = e.createElement(n)
            y.async = !0
            y.src = 'https://cdn.pendo.io/agent/static/' + apiKey + '/pendo.js'
            z = e.getElementsByTagName(n)[0]
            z.parentNode.insertBefore(y, z)
          })(window, document, 'script', 'pendo', {})
        })(api_key)

        window.pendo.initialize({
          visitor: {
            id: UserStorage.identityId(), // Required if user is logged in
            email: UserStorage.email(), // Recommended if using Pendo Feedback, or NPS Email
            full_name: UserStorage.firstName() + ' ' + UserStorage.lastName(),
            role: UserStorage.isAdmin(),
            title: UserStorage.title(),
            titleType: UserStorage.titleType(),
          },

          account: {
            id: UserStorage.parentCompany().id, // Highly recommended
            name: UserStorage.parentCompany().name,
            types: UserStorage.parentCompany().type,
            isCustomer: UserStorage.parentCompany().isCustomer,
          },
        })
      } catch (err) {
        throw err
      }
    },
    unimpersonate() {
      unimpersonate()
    },
  },
})
