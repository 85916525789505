import api from '@/api'
import types from '@/types'
import Report, { UnsupportedMediaTypeReport } from '@/api/communique/report'

export interface BroadcastDailyDelivery {
  // the number of impressions for a specific device
  cumulative_impressions: number

  cumulative_order: number

  stat_date: string
}

export interface FetchBroadcastDeliveryParams {
  startDate?: string
  endDate?: string
  companyId?: string
  mediaTypes: string[]
  excludedInitiativeIDs?: string[]
  includedInitiativeIDs?: string[]
  station: String[] | null
}

export class CompanyBroadcastDailyDelivery {
  public async fetchAll({
    companyId,
    mediaTypes,
    endDate,
    excludedInitiativeIDs,
    includedInitiativeIDs,
    startDate,
    station,
  }: FetchBroadcastDeliveryParams): Promise<Report<BroadcastDailyDelivery> | UnsupportedMediaTypeReport> {
    return api('report.dashboards.ihmCompany.companyDailyBroadcastDelivery')
      .params({
        companyId: companyId,
        media_types: mediaTypes,
        end_date: endDate,
        start_date: startDate,
        exclude_initiative_ids: excludedInitiativeIDs,
        include_initiative_ids: includedInitiativeIDs,
        station: station,
      })
      .cache(1000 * types.config.dashboardCacheSeconds())
      .jsonMust<Report<BroadcastDailyDelivery>>()
  }
}
