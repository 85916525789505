import api from '@/api'
import Report, { UnsupportedMediaTypeReport } from '@/api/communique/report'
import types from '@/types'

export interface LandingPagePerformanceStat {
  directions: number
  more_info: number
  coupon: number
  adgroup_name: string
  adgroup_id: number
  click_to_call: number
  website: number
  html?: string
}

export interface queryParams {
  startDate?: string
  endDate?: string
  initiativeId?: string
  mediaTypes: string[]
  excludeLineItemIds?: string[]
  includeLineItemIds?: string
  creativeId?: string
}

export interface queryParamsByCreative {
  creativeId: string
}

export class CampaignLandingPagePerformanceStatsModel {
  public async fetchAll({
    initiativeId,
    mediaTypes,
    startDate,
    endDate,
    excludeLineItemIds,
    includeLineItemIds,
  }: queryParams): Promise<Report<LandingPagePerformanceStat> | UnsupportedMediaTypeReport> {
    return api('report.dashboards.ihmCampaign.v2.landingPagePerformanceStats')
      .params({
        initiativeId: initiativeId,
        media_types: mediaTypes,
        start_date: startDate,
        end_date: endDate,
        exclude_line_item_ids: excludeLineItemIds,
        include_line_item_ids: includeLineItemIds,
      })
      .cache(1000 * types.config.dashboardCacheSeconds())
      .jsonMust<Report<LandingPagePerformanceStat>>()
  }

  public async fetchUsingCreativeId({
    creativeId,
  }: queryParamsByCreative): Promise<Report<LandingPagePerformanceStat> | UnsupportedMediaTypeReport> {
    return api('report.dashboards.ihmCampaign.v2.landingPagePerformanceStatsByCreative')
      .params({ creativeId })
      .cache(1000 * types.config.dashboardCacheSeconds())
      .jsonMust<Report<LandingPagePerformanceStat>>()
  }
}
