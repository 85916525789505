import api from '@/api'
import Report, { UnsupportedMediaTypeReport } from '@/api/communique/report'
import types from '@/types'

interface CompanyAudioAdCompletionStats {
  impressions: number | null
  audio_completion_25: number | null
  audio_completion_50: number | null
  audio_completion_75: number | null
  audio_completion_100: number | null
}

export interface queryParams {
  companyId: string
  mediaTypes: string[]
  startDate?: string
  endDate?: string
  excludedInitiativeIDs?: string[]
  includedInitiativeIDs?: string[]
}

export class CompanyAudioAdCompletionStatsModel {
  public async fetch({
    companyId,
    mediaTypes,
    startDate,
    endDate,
    excludedInitiativeIDs,
    includedInitiativeIDs,
  }: queryParams): Promise<Report<CompanyAudioAdCompletionStats> | UnsupportedMediaTypeReport> {
    return api('report.dashboards.ihmCompany.companyAudioAdCompletion')
      .params({
        companyId: companyId,
        media_types: mediaTypes,
        start_date: startDate,
        end_date: endDate,
        exclude_initiative_ids: excludedInitiativeIDs,
        include_initiative_ids: includedInitiativeIDs,
      })
      .cache(1000 * types.config.dashboardCacheSeconds())
      .jsonMust<Report<CompanyAudioAdCompletionStats>>()
  }
}
