import { UserAccessService } from '@/features/Access/UserAuth'
import log from '@/log'
import store from '@/store'
import types from '@/types'
import query from '@/types/query'
import { SisenseDashboard } from '@/types/sisense'
import { Location } from 'vue-router'
import { ALLOWED_ACCESS } from './company'

export default interface Dashboard {
  id: string
  title: string
  route: Location
  sisense?: SisenseDashboard
}

export class DashboardModel {
  public fromSisenseDash(sisense: SisenseDashboard): Dashboard {
    return {
      id: assertv(sisense.oid),
      title: assertv(sisense.title),
      route: { name: 'sisenseDashboard', params: { id: sisense.oid } },
      sisense,
    }
  }

  public async loadAll(): Promise<Dashboard[]> {
    let dashboards: Dashboard[] = []
    try {
      dashboards = query(await types.sisense.fetchAll())
        .group(['oid', 'title'])
        .filter((a) => {
          return !a.title?.includes('_drill_')
        })
        .filter((a) => a.oid && a.title && !a.hiddenForNonOwner)
        .transform(this.fromSisenseDash)
    } catch (e) {
      // @ts-ignore
      log.exception(e, 'An error occurred getting dashboards')
    }

    if (types.company.isIHMBranded() || types.company.isUnifiedUser()) {
      dashboards.push({
        id: 'ihmCampaignSelection',
        route: { name: 'ihmCampaignSelection' },
        title: 'Campaign Reports',
      })
    }

    if (types.company.hasAccess(ALLOWED_ACCESS.TAB_EXPLORER)) {
      dashboards.push({
        id: 'explorerDashboard',
        route: { name: 'explorerDashboard' },
        title: 'Explorer Dashboard',
      })
    }

    if (types.company.hasAccess(ALLOWED_ACCESS.TAB_PACING)) {
      dashboards.push({
        id: 'pacingDashboard',
        route: { name: 'pacingDashboard' },
        title: 'Pacing Dashboard',
      })
    }

    if (types.company.hasAccess(ALLOWED_ACCESS.TAB_IHM_REPORTS)) {
      dashboards.push({
        id: 'ihmReport',
        route: { name: 'ihmReport' },
        title: 'IHM Reports',
      })
    }

    if (store.getters.checkHasAccessTo('violation-dashboard')) {
      dashboards.push({
        id: 'violationDashboard',
        route: { name: 'violationDashboard' },
        title: 'Violation Dashboard',
      })
    }

    if (types.company.hasAccess(ALLOWED_ACCESS.TAB_PLANNER)) {
      dashboards.push({
        id: 'signaturePlanner',
        route: { name: 'signaturePlanner' },
        title: 'Digital Pricing Tool',
      })
    }

    const accessService = new UserAccessService(types.user, types.company)

    const [showSalesDashboard] = await Promise.all([accessService.canAccess('sales-dashboard-mvp')])

    if (showSalesDashboard) {
      dashboards.push({
        id: 'salesDashboard',
        route: { name: 'salesDashboard' },
        title: 'Sales Dashboard',
      })
    }

    return dashboards.sort((a, b) => {
      const aTitle = a.title.toLowerCase()
      const bTitle = b.title.toLowerCase()
      if (aTitle < bTitle) {
        return -1
      } else if (aTitle > bTitle) {
        return 1
      } else if (a.id < b.id) {
        return -1
      } else if (a.id > b.id) {
        return 1
      } else {
        return 0
      }
    })
  }
}
