export default interface Config {
  env: string

  platformApiHost: string
  platformApiPort: string
  platformApiProtocol: string
  platformApiAuthentication: string
  platformApiIdentityId: string

  pmnHost: string
  pmnPort: number
  pmnProtocol: string

  platformUiHost: string
  platformUiPort: string
  platformUiProtocol: string

  sisenseURL: string

  mapboxAPIToken: string

  pendoAPIKey: string

  ldAPIKey: string

  orderPerformanceApi?: string

  orderPerformanceApiPort?: number

  orderPerformanceApiProtocol?: string
}

export interface APIConfig {
  domain: string
  port: string
  protocol: string
}

/**
 * Contains methods for working with the Config type
 */
export class ConfigModel {
  public APIConfig(): APIConfig {
    return {
      domain: process.env?.VUE_APP_PLATFORM_API_HOST || window?.unifiedConfig?.platformApiHost || 'localhost',
      port: process.env?.VUE_APP_PLATFORM_API_PORT || window?.unifiedConfig?.platformApiPort || '8000',
      protocol: process.env?.VUE_APP_PLATFORM_API_PROTOCOL || window?.unifiedConfig?.platformApiProtocol || 'http',
    }
  }

  public UIConfig(): APIConfig {
    return {
      domain: process.env?.VUE_APP_UI_HOST || window?.unifiedConfig?.platformUiHost || 'up.unified.com',
      port: process.env?.VUE_APP_UI_PORT || window?.unifiedConfig?.platformUiPort || '9000',
      protocol: process.env?.VUE_APP_UI_PROTOCOL || window?.unifiedConfig?.platformUiProtocol || 'https',
    }
  }

  public ConfigDomain(conf: APIConfig): string {
    return `${conf.protocol}://${conf.domain}:${conf.port}`
  }

  /**
   * Get the base auth URL domain
   */
  public UPDomain(): string {
    const conf = this.UIConfig()
    return this.ConfigDomain(conf)
  }

  public LogoutUIConfig() {
    const uiConfig = this.UIConfig()
    if (!this.isIHMLocation()) {
      const splitDomain = uiConfig.domain.split('.')
      // Get is the Unified version (ihm is ihm verson)
      splitDomain[0] = 'get'
      uiConfig.domain = splitDomain.join('.')
    }
    return uiConfig
  }

  public LogoutDomain() {
    const conf = this.LogoutUIConfig()
    return this.ConfigDomain(conf)
  }

  /**
   * Tell's if app was loaded as IHM dashbaord or an external dashboard
   */
  public isIHMLocation(): boolean {
    if (this.isDev()) {
      return true
    }

    return window.location.hostname.split('.')[0] === 'ihm'
  }

  public SisenseURL(): string {
    return process.env?.VUE_APP_SISENSE_URL ?? window?.unifiedConfig?.sisenseURL ?? ''
  }

  public SisenseDomain(): string {
    const u = this.SisenseURL()
    if (!u) return ''
    const url = new URL(u)
    let domain = url.origin

    while (domain.endsWith('/')) {
      domain = domain.slice(0, domain.length - 1)
    }

    return domain
  }

  public isDev(): boolean {
    return window.__DEV__ ?? false
  }

  public cacheDisabled(): boolean {
    return process.env.VUE_APP_DISABLE_CACHE?.toLowerCase() === 'true' ?? false
  }

  public dashboardCacheSeconds(): number {
    return parseInt(process.env?.VUE_APP_DASHBOARD_CACHE_SECONDS || '600')
  }

  public mapboxAPIToken(): string {
    return window?.unifiedConfig?.mapboxAPIToken ?? process.env?.VUE_APP_MAPBOX_API_TOKEN ?? ''
  }

  public pendoAPIKey(): string {
    return window?.unifiedConfig?.pendoAPIKey ?? process.env?.VUE_APP_PENDO_API_KEY ?? ''
  }

  public LaunchDarkly(): string {
    return window?.unifiedConfig?.ldAPIKey ?? process.env?.VUE_APP_LD_API_KEY ?? ''
  }

  public apiAuthentication(): string {
    return process.env?.VUE_APP_API_AUTHENTICATION || window?.unifiedConfig?.platformApiAuthentication || 'cognito'
  }

  public byPassIdentityId(): string {
    return process.env?.VUE_APP_PLATFORM_IDENTITY_ID || window?.unifiedConfig?.platformApiIdentityId || 'amadmin'
  }
  public pacingVersion(): string {
    return process.env.PACING_DASH_VERSION || '' + Date.now()
  }
  public ihmReportVersion(): string {
    return process.env.IHM_REPORT_VERSION || '' + Date.now()
  }
  public salesDashboardVersion(): string {
    return process.env.SALES_DASHBOARD_VERSION || '' + Date.now()
  }
}
