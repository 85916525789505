import api from '@/api'
import Report, { UnsupportedMediaTypeReport } from '@/api/communique/report'
import types from '@/types'

export default interface CampaignGeoStateStats {
  code: string
  name: string
  imps: number
  clicks: number
}

export interface queryParams {
  startDate?: string
  endDate?: string
  initiativeId?: string
  mediaTypes: string[]
  excludeLineItemIds?: string[]
  includeLineItemIds?: string
}

export class CampaignGeoStateStatsModel {
  public async fetchAll({
    initiativeId,
    mediaTypes,
    startDate,
    endDate,
    excludeLineItemIds,
    includeLineItemIds,
  }: queryParams): Promise<Report<CampaignGeoStateStats> | UnsupportedMediaTypeReport> {
    return api('report.dashboards.ihmCampaign.v2.campaignGeoStateStats')
      .params({
        initiativeId: initiativeId,
        media_types: mediaTypes,
        start_date: startDate,
        end_date: endDate,
        exclude_line_item_ids: excludeLineItemIds,
        include_line_item_ids: includeLineItemIds,
      })
      .cache(1000 * types.config.dashboardCacheSeconds())
      .jsonMust<Report<CampaignGeoStateStats>>()
  }
}
