import typesObject from '@/types'
import { ALLOWED_ACCESS, CompanyModel } from '@/types/company'
import { UserModel } from '@/types/user'
import AccessService, { Access } from './AccessService'

/**
 *
 * Return True For thinsgs that are benefits
 * and false for things that are negative and take away
 * from user experience
 *
 */

type Cache = {
  [key: string]: boolean
}

export class UserAccessService implements AccessService {
  private user: UserModel
  private company: CompanyModel
  private cache: Cache = {}
  constructor(user: UserModel, company: CompanyModel) {
    this.user = user
    this.company = company
  }

  private updateCache(access: Access, hasAccess: boolean) {
    this.cache = { ...this.cache, [access]: hasAccess }
  }

  async canAccess(access: Access): Promise<boolean> {
    const saved = this.cache[access]
    if (saved !== undefined) {
      return saved
    }

    try {
      switch (access) {
        case Access.lineItemFilter:
          return this.hasAccessToLineItemFilter()
        case Access.broadcast:
          return this.hasAcessToBroadcast()
        case Access.companyDashboard:
          return this.hasAcessToCompanyDashboard()
        case Access.powerPointDownload:
          return this.hasAccessToPPT()
        case Access.searchWithoutCompanySelected:
          return this.hasAccessToSearchWithoutCompany()
        case Access.subMediaType:
          return this.hasAccessToSubMediaTypes()
        case Access.broadcastDigital:
          return this.hasAccessToBroadcastDigital()
        case Access.hasAccessToMoreThanOneCompany:
          return this.hasAccessToMoreThanOneCompany()
        case Access.tritonAudioTopStation:
          return this.hasAccessToTritonAudioTopStations()
        case Access.bundleDashboardReportTray:
          return this.hasAccessToBundleDashboardReportTray()
        case Access.campaignDashboardReportTray:
          return this.hasAccessToCampaignDashboardReportTray()
        case Access.companyDashboardReportTray:
          return this.hasAccessToCompanyDashboardReportTray()
        case Access.viewScheduledReports:
          return this.hasAccessToViewScheduledReports()
        case Access.createScheduledReports:
          return this.hasAccessToCreateScheduledReports()
        case Access.reportTrayAdditionalEmails:
          return this.hasAccessReportTrayAdditionalEmails()
        case Access.jelliTtwnPremiere:
          return this.hasAccessToJelliTtwnPremiere()
        case Access.companyJelliTtwnPremiere:
          return this.hasAccessToCompanyJelliTtwnPremiere()
        case Access.canGetMaxAndMinImpressions:
          return this.hasAccessToSearchWithoutCompany()
        case Access.tradedeskInIhmDash:
          return this.hasAccessToTradedesk()
        case Access.streamingAudioBeeswaxInIhmDash:
          return this.hasAccessToBeeswaxStreamingAudio()
        case Access.displayBeeswaxInIhmDash:
          return this.hasAccessToBeeswaxDisplay()
        case Access.prerollBeeswaxInIhmDash:
          return this.hasAccessToBeeswaxPreroll()
        case Access.ottBeeswaxInIhmDash:
          return this.hasAccessToBeeswaxOTT()
        case Access.podcastBeeswaxInIhmDash:
          return this.hasAccessToBeeswaxPodcast()
        case Access.broadcastRadioLdFlagEnableInIhmDash:
          return this.hasBroadcastRadioLdFlagEnableInIhmDash()
        case Access.broadcastJelliLdFlagEnableInIhmDash:
          return this.hasBroadcastJelliLdFlagEnableInIhmDash()
        case Access.broadcastPremiereLdFlagEnableInIhmDash:
          return this.hasBroadcastPremiereLdFlagEnableInIhm_dash()
        case Access.broadcastTtwnLdFlagEnableInIhmDash:
          return this.hasBroadcastTtwnLdFlagEnableInIhmDash()
        case Access.salesDashboard:
          return this.hasAccessToSalesDashboard()
        case Access.amazonReachAndFrequencyLdFlagEnableInIhmDash:
          return this.hasAccessToAmazonReachAndFrequency()
        case Access.broadcastSpotPerformanceWidgetLdFlagEnableInIhmDash:
          return this.hasBroadcastSpotPerformanceWidgetLdFlagEnableInIhmDash()
        case Access.vcrHeroMetric:
          return this.hasAccessToVCRMetric()
        case Access.storeVisitsHeroMetric:
          return this.hasAccessToStoreVisitsHeroMetric()
        case Access.landingPageHeroMetric:
          return this.hasAccessToLandingPageHeroMetric()
        case Access.ttwnJelliHeatMapInDashLdFlagEnableInIhmDash:
          return this.hasTtwnJelliHeatMapInDashLdFlagEnableInIhmDash()
        case Access.spotHeroMetricInDashLdFlagEnableInIhmDash:
          return this.hasSpotHeroMetricInDashLdFlagEnableInIhmDash()
        case Access.broadcastOrderedDemo:
          return this.hasbroadcastOrderedDemoInDashLdFlagEnableInIhmDash()
        default:
          return false
      }
    } catch (e) {
      return false
    }
  }

  private async hasAccessToLineItemFilter(): Promise<boolean> {
    try {
      const hasAccess = await this.user.isEnabled('native-dashboard-lineitem-filtering')
      this.updateCache(Access.lineItemFilter, hasAccess)
      return hasAccess
    } catch (e) {
      return false
    }
  }

  private async hasAcessToBroadcast(): Promise<boolean> {
    try {
      const hasAccess = true
      this.updateCache(Access.broadcast, hasAccess)
      return hasAccess
    } catch (e) {
      return false
    }
  }

  private async hasAcessToCompanyDashboard(): Promise<boolean> {
    try {
      const hasAccess = this.company.isCompanyDashboardUser()
      this.updateCache(Access.companyDashboard, hasAccess)
      return hasAccess
    } catch (e) {
      return false
    }
  }

  private async hasAccessToSearchWithoutCompany(): Promise<boolean> {
    try {
      const hasAccess = !this.company.hasAccess(ALLOWED_ACCESS.IHEART_REVISED_CAMPAIGN_SELECTION)
      this.updateCache(Access.searchWithoutCompanySelected, hasAccess)

      return hasAccess
    } catch (e) {
      return false
    }
  }

  private async hasAccessToSubMediaTypes(): Promise<boolean> {
    try {
      const hasAccess = await this.user.isEnabled('ihm-dash-media-sub-types')
      this.updateCache(Access.subMediaType, hasAccess)
      return hasAccess
    } catch (e) {
      return false
    }
  }

  private async hasAccessToBroadcastDigital(): Promise<boolean> {
    try {
      const hasAccess = await this.user.isEnabled('broadcast-digital-dashboard')
      this.updateCache(Access.broadcastDigital, hasAccess)
      return hasAccess
    } catch (e) {
      return false
    }
  }

  private async hasAccessToPPT(): Promise<boolean> {
    try {
      const [userAccess, companyAccess] = await Promise.all([
        this.user.isEnabled('export-presentation-file'),
        this.company.hasAccessToPPT(),
      ])
      const hasAccess = userAccess && companyAccess
      this.updateCache(Access.powerPointDownload, hasAccess)
      return hasAccess
    } catch (e) {
      return false
    }
  }
  private async hasAccessToMoreThanOneCompany(): Promise<boolean> {
    try {
      const r = await typesObject.singleCompanyUser.fetch()
      const hasAccess = r.has_more_than_one_company == true
      this.updateCache(Access.hasAccessToMoreThanOneCompany, hasAccess)
      return hasAccess
    } catch (e) {
      return false
    }
  }

  private async hasAccessToTritonAudioTopStations(): Promise<boolean> {
    try {
      const hasAccess = await this.user.isEnabled('triton-audio-top-stations')
      this.updateCache(Access.tritonAudioTopStation, hasAccess)
      return hasAccess
    } catch (e) {
      return false
    }
  }

  private async hasAccessToBundleDashboardReportTray(): Promise<boolean> {
    try {
      const [userAccess, companyAccess] = await Promise.all([
        this.user.isEnabled('reporting-ihm-bundle-dashboard-report-tray'),
        this.company.hasAccessToReportTray(),
      ])
      const hasAccess = userAccess && companyAccess
      this.updateCache(Access.bundleDashboardReportTray, hasAccess)
      return hasAccess
    } catch (e) {
      return false
    }
  }

  private async hasAccessToCampaignDashboardReportTray(): Promise<boolean> {
    try {
      const hasAccess = this.company.hasAccessToReportTray()
      this.updateCache(Access.campaignDashboardReportTray, hasAccess)
      return hasAccess
    } catch (e) {
      return false
    }
  }

  private async hasAccessToCompanyDashboardReportTray(): Promise<boolean> {
    try {
      const [userAccess, companyAccess] = await Promise.all([
        this.user.isEnabled('reporting-ihm-company-dashboard-report-tray'),
        this.company.hasAccessToReportTray(),
      ])
      const hasAccess = userAccess && companyAccess
      this.updateCache(Access.companyDashboardReportTray, hasAccess)
      return hasAccess
    } catch (e) {
      return false
    }
  }

  private async hasAccessToViewScheduledReports(): Promise<boolean> {
    try {
      const [userAccess, companyAccess] = await Promise.all([
        this.user.isEnabled('reporting-ihm-report-tray-view-scheduled-reports'),
        this.company.hasAccessToIhmReportTab(),
      ])
      const hasAccess = userAccess && companyAccess
      this.updateCache(Access.viewScheduledReports, hasAccess)
      return hasAccess
    } catch (e) {
      return false
    }
  }

  private async hasAccessToCreateScheduledReports(): Promise<boolean> {
    try {
      const [userAccess, companyAccess] = await Promise.all([
        this.user.isEnabled('reporting-ihm-report-tray-create-scheduled-reports'),
        this.company.hasAccessToIhmReportTab(),
      ])
      const hasAccess = userAccess && companyAccess
      this.updateCache(Access.createScheduledReports, hasAccess)
      return hasAccess
    } catch (e) {
      return false
    }
  }

  private async hasAccessReportTrayAdditionalEmails(): Promise<boolean> {
    try {
      const hasAccess = await this.user.isEnabled('reporting-ihm-report-tray-added-functionality')
      this.updateCache(Access.reportTrayAdditionalEmails, hasAccess)
      return hasAccess
    } catch (e) {
      return false
    }
  }

  private async hasAccessToTradedesk(): Promise<boolean> {
    try {
      const hasAccess = await this.user.isEnabled('trade-desk-in-ihm-dash')
      this.updateCache(Access.tradedeskInIhmDash, hasAccess)
      return hasAccess
    } catch (e) {
      return false
    }
  }

  private async hasAccessToBeeswaxStreamingAudio(): Promise<boolean> {
    try {
      const hasAccess = await this.user.isEnabled('beeswax-in-ihm-dash')
      this.updateCache(Access.streamingAudioBeeswaxInIhmDash, hasAccess)
      return hasAccess
    } catch (e) {
      return false
    }
  }

  private async hasAccessToBeeswaxDisplay(): Promise<boolean> {
    try {
      const hasAccess = await this.user.isEnabled('display-beeswax-in-ihm-dash')
      this.updateCache(Access.displayBeeswaxInIhmDash, hasAccess)
      return hasAccess
    } catch (e) {
      return false
    }
  }

  private async hasAccessToBeeswaxPreroll(): Promise<boolean> {
    try {
      const hasAccess = await this.user.isEnabled('preroll-beeswax-in-ihm-dash')
      this.updateCache(Access.prerollBeeswaxInIhmDash, hasAccess)
      return hasAccess
    } catch (e) {
      return false
    }
  }

  private async hasAccessToBeeswaxOTT(): Promise<boolean> {
    try {
      const hasAccess = await this.user.isEnabled('ott-beeswax-in-ihm-dash')
      this.updateCache(Access.ottBeeswaxInIhmDash, hasAccess)
      return hasAccess
    } catch (e) {
      return false
    }
  }

  private async hasAccessToBeeswaxPodcast(): Promise<boolean> {
    try {
      const hasAccess = await this.user.isEnabled('podcast-beeswax-in-ihm-dash')
      this.updateCache(Access.podcastBeeswaxInIhmDash, hasAccess)
      return hasAccess
    } catch (e) {
      return false
    }
  }

  private async hasAccessToJelliTtwnPremiere(): Promise<boolean> {
    try {
      const hasAccess = await this.user.isEnabled('jelli-ttwn-premiere')
      this.updateCache(Access.jelliTtwnPremiere, hasAccess)
      return hasAccess
    } catch (e) {
      return false
    }
  }

  private async hasAccessToCompanyJelliTtwnPremiere(): Promise<boolean> {
    try {
      const hasAccess = await this.user.isEnabled('company-jelli-ttwn-premiere')
      this.updateCache(Access.companyJelliTtwnPremiere, hasAccess)
      return hasAccess
    } catch (e) {
      return false
    }
  }

  private async hasBroadcastRadioLdFlagEnableInIhmDash(): Promise<boolean> {
    try {
      const hasAccess = await this.user.isEnabled('stations-in-initiative-group-dashboard')
      this.updateCache(Access.broadcastRadioLdFlagEnableInIhmDash, hasAccess)
      return hasAccess
    } catch (e) {
      return false
    }
  }

  private async hasBroadcastJelliLdFlagEnableInIhmDash(): Promise<boolean> {
    try {
      const hasAccess = await this.user.isEnabled('jelli-in-initiative-group-dashboard')
      this.updateCache(Access.broadcastJelliLdFlagEnableInIhmDash, hasAccess)
      return hasAccess
    } catch (e) {
      return false
    }
  }

  private async hasBroadcastPremiereLdFlagEnableInIhm_dash(): Promise<boolean> {
    try {
      const hasAccess = await this.user.isEnabled('premiere-in-initiative-group-dashboard')
      this.updateCache(Access.broadcastPremiereLdFlagEnableInIhmDash, hasAccess)
      return hasAccess
    } catch (e) {
      return false
    }
  }

  private async hasBroadcastTtwnLdFlagEnableInIhmDash(): Promise<boolean> {
    try {
      const hasAccess = await this.user.isEnabled('ttwn-in-initiative-group-dashboard')
      this.updateCache(Access.broadcastTtwnLdFlagEnableInIhmDash, hasAccess)
      return hasAccess
    } catch (e) {
      return false
    }
  }

  private async hasAccessToSalesDashboard(): Promise<boolean> {
    try {
      const hasAccess = await this.user.isEnabled('sales-dashboard-mvp')
      this.updateCache(Access.salesDashboard, hasAccess)
      return hasAccess
    } catch (e) {
      return false
    }
  }

  private async hasAccessToAmazonReachAndFrequency(): Promise<boolean> {
    try {
      const hasAccess = await this.user.isEnabled('amazon-reach-and-frequency-in-dash')
      this.updateCache(Access.amazonReachAndFrequencyLdFlagEnableInIhmDash, hasAccess)
      return hasAccess
    } catch (e) {
      return false
    }
  }

  private async hasBroadcastSpotPerformanceWidgetLdFlagEnableInIhmDash(): Promise<boolean> {
    try {
      const hasAccess = await this.user.isEnabled('spot-performance-widget')
      this.updateCache(Access.broadcastSpotPerformanceWidgetLdFlagEnableInIhmDash, hasAccess)
      return hasAccess
    } catch (e) {
      return false
    }
  }

  private async hasAccessToVCRMetric(): Promise<boolean> {
    try {
      const hasAccess = await this.user.isEnabled('vcr-hero-in-company-dash')
      this.updateCache(Access.vcrHeroMetric, hasAccess)
      return hasAccess
    } catch (e) {
      return false
    }
  }

  private async hasAccessToStoreVisitsHeroMetric(): Promise<boolean> {
    try {
      const hasAccess = await this.user.isEnabled('store-visits-hero-in-company-dash')
      this.updateCache(Access.storeVisitsHeroMetric, hasAccess)
      return hasAccess
    } catch (e) {
      return false
    }
  }

  private async hasAccessToLandingPageHeroMetric(): Promise<boolean> {
    try {
      const hasAccess = await this.user.isEnabled('landing-page-hero-in-company-dash')
      this.updateCache(Access.landingPageHeroMetric, hasAccess)
      return hasAccess
    } catch (e) {
      return false
    }
  }
  private async hasTtwnJelliHeatMapInDashLdFlagEnableInIhmDash(): Promise<boolean> {
    try {
      const hasAccess = await this.user.isEnabled('ttwn-jelli-heat-map-in-dash')
      this.updateCache(Access.ttwnJelliHeatMapInDashLdFlagEnableInIhmDash, hasAccess)
      return hasAccess
    } catch (e) {
      return false
    }
  }
  private async hasSpotHeroMetricInDashLdFlagEnableInIhmDash(): Promise<boolean> {
    try {
      const hasAccess = await this.user.isEnabled('spot-hero-metric-in-dash')
      this.updateCache(Access.spotHeroMetricInDashLdFlagEnableInIhmDash, hasAccess)
      return hasAccess
    } catch (e) {
      return false
    }
  }

  private async hasbroadcastOrderedDemoInDashLdFlagEnableInIhmDash(): Promise<boolean> {
    try {
      const hasAccess = await this.user.isEnabled('broadcast-ordered-demo')
      this.updateCache(Access.broadcastOrderedDemo, hasAccess)
      return hasAccess
    } catch (e) {
      return false
    }
  }
}
