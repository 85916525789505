import api from '@/api'
import Report from '@/api/communique/report'
import types from '@/types'

export default interface GeoStoreVisitFlags {
  initiative_id: string
  track_store_visits: boolean
  excludedInitiativeIDs?: string[]
  includedInitiativeIDs?: string[]
}

interface queryParams {
  companyId: string
  mediaTypes?: string[]
  startDate?: string
  endDate?: string
  excludedInitiativeIDs?: string[]
  includedInitiativeIDs?: string[]
}

export class GeoStoreVisitFlagsModel {
  public async fetchAll({
    companyId,
    mediaTypes,
    startDate,
    endDate,
    excludedInitiativeIDs,
    includedInitiativeIDs,
  }: queryParams): Promise<Report<GeoStoreVisitFlags>> {
    return api('report.dashboards.ihmCompany.geoStoreVisitFlags')
      .params({
        companyId: companyId,
        media_types: mediaTypes,
        start_date: startDate,
        end_date: endDate,
        exclude_initiative_ids: excludedInitiativeIDs,
        include_initiative_ids: includedInitiativeIDs,
      })
      .cache(1000 * types.config.dashboardCacheSeconds())
      .jsonMust<Report<GeoStoreVisitFlags>>()
  }
}
