import api from '@/api'
import Report from '@/api/communique/report'
import types from '@/types'

//Subject to be modified as more stats are added
export interface CreativeStats {
  //the backend doens't return id
  //so we generate id based on the index for each row, that why it's optional
  id?: number
  media_type: string
  publisher: string
  name: string
  format: string
  tactic: string
  impressions: number
  clicks: number
  ctr: number
  vcr: number
  preview?: string
  video_url?: string
  creative_status: string
  vast?: boolean
}

export interface QueryParams {
  activeToggle: boolean
  offset?: number
  itemsPerPage?: number
  order_fields: string[]
  startDate?: string
  endDate?: string
  mediaTypes?: string[]
  companyId: string
  excludedInitiativeIDs?: string[]
  includedInitiativeIDs?: string[]
}

export class CompanyCreativeModel {
  public async fetch({
    activeToggle,
    companyId,
    itemsPerPage,
    mediaTypes,
    startDate,
    endDate,
    excludedInitiativeIDs,
    includedInitiativeIDs,
    offset,
    order_fields,
  }: QueryParams): Promise<Report<CreativeStats>> {
    return api('report.dashboards.ihmCompany.companyCreativeStats')
      .params({
        activeCreativeToggle: activeToggle,
        offset: offset,
        itemsPerPage: itemsPerPage,
        orderFields: order_fields,
        companyId: companyId,
        media_types: mediaTypes,
        start_date: startDate,
        end_date: endDate,
        exclude_initiative_ids: excludedInitiativeIDs,
        include_initiative_ids: includedInitiativeIDs,
      })
      .cache(1000 * types.config.dashboardCacheSeconds())
      .jsonMust<Report<CreativeStats>>()
  }
}
