import api from '@/api'
import Report, { UnsupportedMediaTypeReport } from '@/api/communique/report'
import types from '@/types'

interface CampaignAudioAdCompletionStats {
  impressions: number | null
  audio_completion_25: number | null
  audio_completion_50: number | null
  audio_completion_75: number | null
  audio_completion_100: number | null
}

export interface queryParams {
  startDate?: string
  endDate?: string
  initiativeId?: string
  mediaTypes: string[]
  excludeLineItemIds?: string[]
  includeLineItemIds?: string
}

export class CampaignAudioAdCompletionStatsModel {
  public async fetch({
    initiativeId,
    mediaTypes,
    startDate,
    endDate,
    excludeLineItemIds,
    includeLineItemIds,
  }: queryParams): Promise<Report<CampaignAudioAdCompletionStats> | UnsupportedMediaTypeReport> {
    return api('report.dashboards.ihmCampaign.v2.campaignAudioAdCompletion')
      .params({
        initiativeId: initiativeId,
        media_types: mediaTypes,
        start_date: startDate,
        end_date: endDate,
        exclude_line_item_ids: excludeLineItemIds,
        include_line_item_ids: includeLineItemIds,
      })
      .cache(1000 * types.config.dashboardCacheSeconds())
      .jsonMust<Report<CampaignAudioAdCompletionStats>>()
  }
}
