import Vue from 'vue'
import * as colors from '@/stylus/colors.json'
import * as directives from 'vuetify/es5/directives'
import Vuetify from 'vuetify/lib'

Vue.use(Vuetify)

export default new Vuetify({
  directives,
  icons: {
    iconfont: 'md',
  },
  theme: {
    dark: false,
    themes: {
      dark: colors.IHMTheme.light.vuetify,
    },
  },
})
