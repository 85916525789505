import colors from '@/stylus/colors.json'

export interface SectionFABStype {
  label: string
  color: string
  icon: string
  sectionClassName: string
}

const sectionFABStylesMap = {
  Amazon: {
    label: 'Amazon',
    color: colors.section.amazon,
    icon: 'fab fa-amazon',
    sectionClassName: 'amazon',
  },
  Display: {
    label: 'Display',
    color: colors.section.display,
    icon: 'fas fa-window-maximize',
    sectionClassName: 'display',
  },
  GroundTruth: {
    label: 'Location-Based',
    color: colors.section.locationBased,
    icon: 'fas fa-map-pin',
    sectionClassName: 'gt',
  },
  Social: {
    label: 'Social',
    color: colors.section.social,
    icon: 'fas fa-share-alt',
    sectionClassName: 'social',
  },
  OTT: {
    label: 'OTT',
    color: colors.section.ott,
    icon: 'fas fa-tv',
    sectionClassName: 'ott',
  },
  Preroll: {
    label: 'Pre-Roll',
    color: colors.section.preRoll,
    icon: 'fas fa-video',
    sectionClassName: 'preroll',
  },
  Podcast: {
    label: 'Podcast',
    color: colors.section.podcast,
    icon: 'fas fa-podcast',
    sectionClassName: 'podcast',
  },
  'Streaming Audio': {
    label: 'Streaming Audio',
    color: colors.section.streamingAudio,
    icon: 'fas fa-waveform',
    sectionClassName: 'streamingaudio',
  },
  Hybrid: {
    label: 'Digital Audio',
    color: colors.section.hybrid,
    icon: 'fas fa-headphones-alt',
    sectionClassName: 'hybrid',
  },
  'Broadcast Radio': {
    label: 'Broadcast Radio',
    color: colors.section.broadcast,
    icon: 'fas fa-broadcast-tower',
    sectionClassName: 'broadcast',
  },
}

export class SectionLogosModel {
  public logos(): { [key: string]: SectionFABStype } {
    return sectionFABStylesMap
  }
}
