import { checkLogin } from '../api'
import log from '@/log'

let interval: NodeJS.Timeout // variable to store the interval

self.addEventListener('message', (event) => {
  if (event.data === 'start') {
    log.debug('Check Login Worker started')

    interval = setInterval(() => {
      self.postMessage('runCheckLogin')
    }, 30000)
  } else if (event.data === 'runCheckLogin') {
    checkLogin()

    log.debug('login checked')
    // can add another message like self.postMessage('checkLoginCompleted') in the future
  }
})

self.addEventListener('error', (error) => {
  log.error('Error in the checkLogin worker', error)
})

self.addEventListener('onbeforeunload', () => {
  clearInterval(interval) // clear the interval
  self.close() // Terminate the web worker when the application is closed
})

self.postMessage('start')

export {}
