import api from '@/api'
import Report from '@/api/communique/report'
import types from '@/types'

export default interface CompanyDetails {
  /**
   * The orders for the company
   */
  orders: Array<OrderData>
  /**
   * The name of the company
   */
  name: string
  /**
   * The id of the company
   */
  id: string
  /**
   * the number of orders
   */
  orderCount: number
  /**
   * the lowest start date for an order
   */
  minOrderStartDate: string
  /**
   * the highest end date for an order
   */
  maxOrderEndDate: string
  /**
   * all the media types for a specific company
   */
  mediaTypes: Array<string>
}

export interface OrderData {
  id: string
  dashboardFriendlyName: string
}

export class CompanyDetailsModel {
  public async fetchAll(companyId: string): Promise<CompanyDetails> {
    return api('pmn.ihmCompanies.details')
      .params({
        companyId,
      })
      .cache(1000 * types.config.dashboardCacheSeconds())
      .jsonMust<CompanyDetails>()
  }
}
