import api from '@/api'
import Report from '@/api/communique/report'
import types from '@/types'

export interface CampaignDailyImpsClicks {
  impressions: number
  clicks: number
  media_type: string
  stat_date: string
}

export interface CampaignFetchParams {
  startDate?: string
  endDate?: string
  initiativeId?: string
  mediaTypes: string[]
  excludeLineItemIds?: string[]
  includeLineItemIds?: string
}

export class CampaignDailyImpsClicksModel {
  public async fetchAll({
    mediaTypes,
    startDate,
    endDate,
    initiativeId,
    excludeLineItemIds,
    includeLineItemIds,
  }: CampaignFetchParams) {
    return api('report.dashboards.ihmCampaign.v2.campaignDailyImpsClicks')
      .params({
        media_types: mediaTypes,
        initiativeId,
        start_date: startDate,
        end_date: endDate,
        exclude_line_item_ids: excludeLineItemIds,
        include_line_item_ids: includeLineItemIds,
      })
      .cache(1000 * types.config.dashboardCacheSeconds())
      .jsonMust<Report<CampaignDailyImpsClicks>>()
  }
}
