import api from '@/api'
import PMNList from '@/api/pmn/list'
import types from '@/types'
import User from '@unified/js-common/lib/user/User'

export enum ALLOWED_ACCESS {
  TAB_EXPLORER = 'tab_explorer',
  TAB_PACING = 'tab_pacing',
  TAB_IHM_REPORTS = 'tab_ihm_reports',
  IHEART_BRANDED = 'iheart_branded',
  IHEART_COMPANY_ROLL_UP = 'iheart_company_roll_up',
  IHEART_DASHBOARD_REPORTING_ACCESS = 'iheart_dashboard_reporting_access',
  WHITE_LABELED = 'white_label_dashboard',
  IHEART_POWERPOINT_ACCESS = 'iheart_powerpoint_access',
  IHEART_REVISED_CAMPAIGN_SELECTION = 'iheart_revised_campaign_selection',
  TAB_PLANNER = 'tab_planner',
  IHM_CSM = 'ihm_csm',
  LOCAL_NON_CSM = 'local_non_csm',
  UNIFIED_PARTNERSHIPS_NON_CSM = 'unified_partnerships_non_csm',
  IHM_SIGNATURE_PLUS_CUSTOMERS = 'ihm_signature_plus_customers',
}

export default interface Company {
  /**
   * The company's access rights
   */
  allowedAccess: string[]
  /**
   * The company's created date
   */
  createDate: string
  /**
   * The company's id
   */
  id: string
  /**
   * Is this company a customer
   */
  isCustomer: boolean
  /**
   * The company's name
   */
  name: string
  /**
   * The company's type
   */
  type: string
  /**
   * The company's logo
   */
  logo: string | null
  marketPlaceName?: string
}

export interface CompanyIntegration {
  /**
   * The company's id
   */
  CompanyId: string
  /**
   * The company's name
   */
  CompanyName: string
  /**
   * The company's market
   */
  MarketPlaceName: string
  /**
   * The company's logo
   */
  logo: string | null
}

type CompanyWithMarketPlaceApiDTO = {
  data: {
    companies: {
      companyID: string
      companyName: string
      dateCreated: string
      dateModified: string
      logo: string | null
      marketPlaceName: string
      type: string
    }[]
  }
  meta: {
    count: number
    limit: number
    nextURL: string
    offset: number
    prevURL: string
  }
}

/**
 * Contains methods for working with the Company type
 */
export class CompanyModel {
  /**
   * Get the current logged in user's company
   */
  public current(): Company {
    return User.parentCompany()
  }

  /**
   * Get the current logged in user's company, ignoring impersonation
   */
  public currentLoggedIn(): Company {
    return User.company()
  }

  public isProvider(c?: Company): boolean {
    if (!c) c = this.current()
    if (!c) return false
    return c.type === 'PROVIDER'
  }

  public isUnifiedManagedService(c?: Company): boolean {
    if (!c) c = this.current()
    if (!c) return false

    return c.id == 'fea1aeee-d0d2-4992-aaa6-01fc3db4d463'
  }

  public isUnifiedUser(c?: Company): boolean {
    return this.isProvider(c) || this.isUnifiedManagedService(c)
  }

  public hasDarklyFlag(): boolean {
    if (User.ldClientSideId) {
      return true
    } else {
      return false
    }
  }

  /**
   * Check to see if the given company has access. If company not specified, use current company.
   * @param c The company to check
   * @param accessCheck The access we're attempting to check
   */
  public hasAccess(accessCheck: string, allowProvider: boolean = true, c?: Company): boolean {
    if (!c) c = this.current()
    if (!c) return false
    if (allowProvider && c.type === 'PROVIDER') return true
    return c.allowedAccess.includes(accessCheck)
  }

  public isIHMBranded(allowProvider: boolean = true, c?: Company) {
    return this.hasAccess(ALLOWED_ACCESS.IHEART_BRANDED, allowProvider, c)
  }

  public isCompanyDashboardUser(allowProvider: boolean = true, c?: Company) {
    return this.hasAccess(ALLOWED_ACCESS.IHEART_COMPANY_ROLL_UP, allowProvider, c)
  }

  public hasAccessToPPT(allowProvider: boolean = true, c?: Company) {
    return this.hasAccess(ALLOWED_ACCESS.IHEART_POWERPOINT_ACCESS, allowProvider, c)
  }

  /**
   * Force White Label in future as of right now we this forces unified Theme
   */
  public forceWhiteLabeled(c?: Company) {
    // Providers won't need this is also used to force a keep out()
    const allowProvider: boolean = false
    return this.hasAccess(ALLOWED_ACCESS.WHITE_LABELED, allowProvider, c)
  }

  public hasAccessToReportTray(allowProvider: boolean = false, company?: Company) {
    return this.hasAccess(ALLOWED_ACCESS.IHEART_DASHBOARD_REPORTING_ACCESS, allowProvider, company)
  }

  public hasAccessToIhmReportTab(allowProvider: boolean = false, company?: Company) {
    return this.hasAccess(ALLOWED_ACCESS.TAB_IHM_REPORTS, allowProvider, company)
  }

  public isIHMCsm(allowProvider: boolean = true, c?: Company) {
    return this.hasAccess(ALLOWED_ACCESS.IHM_CSM, allowProvider, c)
  }

  public isLocalNonCsm(allowProvider: boolean = true, c?: Company) {
    return this.hasAccess(ALLOWED_ACCESS.LOCAL_NON_CSM, allowProvider, c)
  }

  public isUnifiedPartnershipsNonCsm(allowProvider: boolean = true, c?: Company) {
    return this.hasAccess(ALLOWED_ACCESS.UNIFIED_PARTNERSHIPS_NON_CSM, allowProvider, c)
  }

  public isIHMSignaturePlusCustomers(allowProvider: boolean = true, c?: Company) {
    return this.hasAccess(ALLOWED_ACCESS.IHM_SIGNATURE_PLUS_CUSTOMERS, allowProvider, c)
  }

  public async findByIds(ids: string[]): Promise<PMNList<Company>> {
    const data: Company[] = []
    const c = await api('pmn.ihmCompanies.trimmedSearch')
      .params({
        ids: ids,
      })
      .cache(1000 * types.config.dashboardCacheSeconds())
      .jsonMust<CompanyWithMarketPlaceApiDTO>()
    c.data.companies.forEach(({ companyID, companyName, dateCreated, logo, marketPlaceName, type }) => {
      data.push({
        allowedAccess: [],
        createDate: dateCreated,
        id: companyID,
        name: companyName,
        isCustomer: false,
        logo: logo,
        type: type,
        marketPlaceName,
      })
    })
    return {
      data: {
        companies: data,
      },
      meta: { count: data.length, limit: ids.length, nextURL: '', offset: 0, prevURL: '' },
    }
  }

  public async searchByName(search: string, offset: number, limit: number): Promise<PMNList<Company>> {
    const c = await api('pmn.ihmCompanies.trimmedSearch')
      .params({
        search: search,
        offset: offset,
        limit: limit,
      })
      .cache(1000 * types.config.dashboardCacheSeconds())
      .jsonMust<CompanyWithMarketPlaceApiDTO>()
    return {
      data: {
        companies: c.data.companies.map(({ companyID, companyName, dateCreated, logo, marketPlaceName, type }) => {
          return {
            allowedAccess: [],
            createDate: dateCreated,
            id: companyID,
            name: companyName,
            isCustomer: false,
            logo: logo,
            type: type,
            marketPlaceName,
          }
        }),
      },
      meta: c.meta,
    }
    // return api('pmn.ihmCompanies.search')
    //   .params({
    //     search: search,
    //     offset: offset,
    //     limit: limit,
    //   })
    //   .cache(1000 * types.config.dashboardCacheSeconds())
    //   .jsonMust<PMNList<Company>>()
  }

  public async searchByMarketId(marketId: string): Promise<CompanyIntegration[]> {
    return api('pmn.companies.market')
      .params({
        marketId,
      })
      .cache(1000 * types.config.dashboardCacheSeconds())
      .json<CompanyIntegration[]>()
  }
}
