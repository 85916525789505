import numeral from 'numeral'

export class NumberModel {
  /**
   * Format a number using numeral.js
   * @param n The number to format
   * @param formatter The formatter to use
   */
  public format(n: number, formatter: string): string {
    return numeral(n).format(formatter)
  }

  public isNumber(str: string): boolean {
    return /^\d+$/.test(str)
  }

  public nFormatter(num: number): string {
    if (num >= 1000000000) {
      return (num / 1000000000).toFixed(1).replace(/\.0$/, '') + 'G'
    }
    if (num >= 1000000) {
      return (num / 1000000).toFixed(1).replace(/\.0$/, '') + 'M'
    }
    if (num >= 1000) {
      return (num / 1000).toFixed(1).replace(/\.0$/, '') + 'K'
    }
    return num.toString()
  }
}
