/* eslint-disable no-console */
/**
 * Logger implements a wrapper around the console logging facilities
 */
export class Logger {
  private tElapsed: number = 0

  /**
   * Debug log
   * @param msgs - the messages to log - uses same format as console method
   */
  public debug(message?: any, ...opt: any[]): void {
    if (this.shouldLog()) {
      // eslint-disable-next-line
      console.log(message, ...opt)
    }
  }

  /**
   * Error log
   * @param msgs - the messages to log - uses same format as console method
   */
  public error(message?: any, ...opt: any[]): void {
    const trace = this.stackTrace()
    // eslint-disable-next-line
    console.error(`${message}\n${trace}`, ...opt)
  }

  /**
   * Exception log - writes an exception
   */
  public exception(error: Error, message?: string, ...opt: any[]): void {
    // eslint-disable-next-line
    console.error(message, error, ...opt)
  }

  /**
   * Table log
   * @param data - the data to log in the table
   * @param columns - the columns to log for the table
   */
  public table(...tabularData: any[]): void {
    if (this.shouldLog()) {
      // eslint-disable-next-line
      console.table(...tabularData)
    }
  }

  /**
   * Log used to trace method calls
   * @param extra Extra info to log
   */
  public t(caller: string, ...extra: any): void {
    let elapsed = ''
    if (this.tElapsed === 0) {
      elapsed = '(Elapsed: Start)'
    } else {
      elapsed = `(Elapsed: ${Date.now() - this.tElapsed})`
    }
    this.tElapsed = Date.now()

    this.debug(`%c TRACE CALL: ${caller} ${elapsed}`, 'color: magenta; font-weight: bold;', ...extra)
  }

  /**
   * Count a log
   */
  public count(label?: string): void {
    if (this.shouldLog()) {
      // eslint-disable-next-line
      console.count(label)
    }
  }

  /**
   * Begin Profile
   */
  public profile(name?: string): void {
    if (this.shouldLog()) {
      // eslint-disable-next-line
      console.profile(name)
    }
  }

  /**
   * End Profile
   */
  public profileEnd(name?: string): void {
    if (this.shouldLog()) {
      // eslint-disable-next-line
      console.profileEnd(name)
    }
  }

  /**
   * Start a timer
   */
  public time(label?: string): void {
    if (this.shouldLog()) {
      // eslint-disable-next-line
      console.time(label)
    }
  }

  /**
   * End a timer
   */
  public timeEnd(label?: string): void {
    if (this.shouldLog()) {
      // eslint-disable-next-line
      console.timeEnd(label)
    }
  }

  /**
   * Timestamp a timer
   */
  public timeStamp(label?: string): void {
    if (this.shouldLog()) {
      // eslint-disable-next-line
      console.timeStamp(label)
    }
  }

  /**
   * Make a mark on a timeline
   */
  public markTimeline(label?: string): void {
    if (this.shouldLog()) {
      // @ts-ignore
      console.markTimeline(label)
    }
  }

  /**
   * Start a timeline
   */
  public timeline(label?: string): void {
    if (this.shouldLog()) {
      // @ts-ignore
      console.timeline(label)
    }
  }

  /**
   * End a timeline
   */
  public timelineEnd(label?: string): void {
    if (this.shouldLog()) {
      // @ts-ignore
      console.timelineEnd(label)
    }
  }

  /**
   * Group logs together
   */
  public group(...args: any): void {
    if (this.shouldLog()) {
      // eslint-disable-next-line
      console.group(...args)
    }
  }

  /**
   * Group logs together collapsed
   */
  public groupCollapsed(...args: any): void {
    if (this.shouldLog()) {
      // eslint-disable-next-line
      console.groupCollapsed(...args)
    }
  }

  /**
   * End groupped logs
   */
  public groupEnd(): void {
    if (this.shouldLog()) {
      // eslint-disable-next-line
      console.groupEnd()
    }
  }

  /**
   * Dir log - logs an object output
   * @param obj - the object to log
   */
  public dir(value?: any, ...opt: any[]): void {
    if (this.shouldLog()) {
      // eslint-disable-next-line
      console.dir(value, ...opt)
    }
  }

  /**
   * Assertion
   * @param assertion - if false, assertion failed
   * @param msgs - the messages to log on assertion failure - uses same format as console method
   */
  public assert(condition?: boolean, message?: string, ...data: any[]): void {
    if (this.shouldLog()) {
      // eslint-disable-next-line
      console.assert(condition, message, ...data)
    }
  }

  /**
   * Get stack trace string. Inspiration: https://stackoverflow.com/a/36172532
   */
  private stackTrace(): string[] {
    const stack = new Error().stack ?? ''
    const lines = stack.split('\n').map((line) => line.trim())
    return lines.splice(lines[0] === 'Error' ? 2 : 1).filter((line) => !line.startsWith('at Logger.'))
  }

  /**
   * Return true if we should write to the console
   * @returns boolean
   */
  private shouldLog(): boolean {
    if (!console || !window.__DEV__) return false
    return window.__DEV__
  }
}

/**
 * Default logger which is used by default when logging
 * @type {Logger}
 */
export default new Logger()
