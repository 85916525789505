/**
 *
 * @param str
 *
 * Allows us to remove prototype modified string from project
 */

const convertString = (str: string): string => {
  return `"${str.replace('"', '""')}"`
}

/**
 *
 * @param array
 *
 * Allows us to remove prototype modified array from project
 */

export const csvify = (array: Array<Array<string | number | undefined | null>>): string => {
  return array
    .map((r) => {
      return r
        .map((i) => {
          const item = i === undefined || i === null ? '' : i
          return convertString(item.toString())
        })
        .join(',')
    })
    .join('\n')
}

export default csvify
