import { Map, LngLatBounds } from 'mapbox-gl'
import { Position } from 'geojson'
import Vue from 'vue'

import { FeatureCollection } from 'geojson'

export interface VueMapboxLoadedEvent {
  component: Vue
  map: Map
}

export class MapModel {
  /**
   * Get the bounds of coordinates for a collection of GeoJSON features
   * From https://docs.mapbox.com/mapbox-gl-js/example/zoomto-linestring/
   * @param fs The collection of features that we're getting the bounds for
   */
  public boundsOfFeatureCollection(fs: FeatureCollection): LngLatBounds {
    const coordinates = fs.features.map<number[]>((f) => {
      return f.geometry.type === 'Point' ? f.geometry.coordinates : []
    })

    // Pass the first coordinates in the LineString to `lngLatBounds` &
    // wrap each coordinate pair in `extend` to include them in the bounds
    // result. A variation of this technique could be applied to zooming
    // to the bounds of multiple Points or Polygon geomteries - it just
    // requires wrapping all the coordinates with the extend method.
    return coordinates.reduce(function (bounds, coord) {
      return bounds.extend(coord as any)
    }, new LngLatBounds())
  }
}
